import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import axios from "axios";
import Select from "react-select";
import "jspdf-autotable";
import TextField from "@mui/material/TextField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import "../../CSS/dataTables.bootstrap4.min.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import $ from 'jquery';
import 'datatables.net';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import dayjs from "dayjs";

const Ledger_Report = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [isModal, setIsModal] = useState(false);
    const [indexValue, setIndex] = useState();
    const [planData, setPlanData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [TotalCredit, setCredit] = useState(0);
    const [TotalDebit, setDebit] = useState(0);
    const [oldData, setlanData] = useState([]);
    const [data, setData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [filter, setFilter] = useState({
        Customer_Id: "-1"
    });
    const planLoad = async () => {
        showLoader();
        const staffResult = await axios.get(
            `${apiUrl}/getPlans?OrgId=${userData?.Org_Id}`
        );
        setPlanData(staffResult.data);
        hideLoader();
    }
    const TypeLoad = async () => {
        showLoader();
        const TypeResult = await axios.get(
            `${apiUrl}/getPercentageTypes?OrgId=${userData?.Org_Id}`
        );
        setTypeData(TypeResult.data);
        hideLoader();
    };
    const customerLoad = async () => {
        showLoader();
        const customerResult = await axios.get(`${apiUrl}/getCustomer?OrgId=${userData.Org_Id}`);
        setCustomerData(customerResult.data);
        hideLoader();
    }
    const dataLoad = async () => {
        showLoader();
        const Result = await axios.get(
            `${apiUrl}/getCustomerReport?OrgId=${userData.Org_Id}&CustomerId=${filter?.Customer_Id}`
        );
        setData(Result.data);
        setlanData(Result.data);
        hideLoader();
    }
    useEffect(() => {
        if (userData && userData.Org_Id) {
            showLoader();
            planLoad();
            customerLoad();
            dataLoad();
            //TypeLoad();
            hideLoader();
        }
    }, [userData, filter.Customer_Id])

    const filterData = () => {

        dataLoad();
    }

    useEffect(() => {
        if (data) {
            showLoader();
            const Credit = data.reduce((total, item) => total + parseFloat(item.Credit), 0);
            const Debit = data.reduce((total, item) => total + parseFloat(item.Debit), 0);
            setCredit(Credit);
            setDebit(Debit);
            hideLoader();
        }
    }, [data]);

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text(`Customer Report - (${data[0].Customer_Name})`, 20, 10);

    const columns = [
        { header: "Date", dataKey: "Date" },
        { header: "Description", dataKey: "Description" },
        { header: "Credit", dataKey: "Credit" },
        { header: "Debit", dataKey: "Debit" }
    ];

    const rows = data.map(row => ({
        Date: dayjs(row.Date).format('DD/MM/YYYY'),
        Description: row.Description,
        Credit: parseFloat(row.Credit).toFixed(2),
        Debit: parseFloat(row.Debit).toFixed(2)
    }));

    // Calculate the sum of the "Credit" and "Debit" columns
    const totalCredit = rows.reduce((total, row) => total + parseFloat(row.Credit), 0);
    const totalDebit = rows.reduce((total, row) => total + parseFloat(row.Debit), 0);

    // Add an additional row to display the total amount
    const totalRow = { Date: "", Description: "Total :", Credit: parseFloat(totalCredit).toFixed(2), Debit: parseFloat(totalDebit).toFixed(2) };
    rows.push(totalRow);

    doc.autoTable({
        columns,
        body: rows,
        margin: { top: 20 },
        styles: {
            overflow: 'linebreak', // Allow text to wrap
            cellWidth: 'wrap', // Wrap text within cell width
            fontSize: 10,
        },
        headStyles: {
            fillColor: [100, 100, 255], // Header background color
            textColor: 255, // Header text color
            fontStyle: 'bold' // Header font style
        },
        columnStyles: {
            1: { cellWidth: 'auto' } // Adjust cell width for Description
        }
    });

    doc.save("Customer Report.pdf");
};

    

    const DownloadExcel = () => {
        const columns = [
            "Date",
            "Description",
            "Credit",
            "Debit"
        ];

        let totalCredit = 0;
        let totalDebit = 0;
        const rows = [columns];
        data.forEach((row) => {
            rows.push([
                dayjs(row.Date).format('DD/MM/YYYY'),
                row.Description,
               parseFloat(row.Credit).toFixed(2),
               parseFloat(row.Debit).toFixed(2)
            ]);

            // Accumulate the amount for total calculation
            totalCredit += row.Credit;
            totalDebit += row.Debit;
        });

        // Push a row at the end to display the total amount
        const totalRow = ["", "Total : ",  parseFloat(totalCredit).toFixed(2),  parseFloat(totalDebit).toFixed(2)];
        rows.push(totalRow);

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, `Customer Report`);

        // Buffer
        // let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

        // Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

        // Download
        XLSX.writeFile(workBook, "Customer Report.xlsx");
    };

    function handleFilter(event) {
        const filterValue = event.target.value.toLowerCase();
        const filteredData = oldData.filter((row) => {
            return (
                (dayjs(row.Date).format('DD/MM/YYYY') && dayjs(row.Date).format('DD/MM/YYYY').toLowerCase().includes(filterValue)) ||
                (row.Credit && parseFloat(row.Credit).toFixed(2).toString().toLowerCase().includes(filterValue)) ||
                (row.Description && row.Description.toLowerCase().includes(filterValue)) ||
                (row.Debit && parseFloat(row.Debit).toFixed(2).toString().toLowerCase().includes(filterValue)) 
            );
        });
        setData(filteredData);
    }
    const updateData = (index) => {
        setIndex(index);
        setIsModal(true);
    }

    function deleteData(id, name) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(id, name);
            }
        });
    }
    const handleDelete = async (id, name) => {
        const obj = {
            User_Role: userData.role,
            Emp_Id: userData.Employee_Id,
            Added_By_Id: userData.userId,
            Added_By: `${userData.firstName} ${userData.lastName}`,
            Org_Id: userData.Org_Id,
            UserName: userData.UserName,
            Name: name
        }
        await axios.delete(`${apiUrl}/deletePaymentLog/${id}`, { data: obj });
        Swal.fire("Deleted!", "Your record has been Deleted.", "success");
        await dataLoad();
    };

    const closed = () => {
        setIsModal(false);
        dataLoad();
    };

    const calculateDaysDifference = (startDate, endDate) => {
        const start = dayjs(startDate).startOf("day");
        const end = dayjs(endDate).startOf("day");
        const daysDifference = end.diff(start, "day");
        console.log(daysDifference)
        return daysDifference;
    };

    return (
        <div className="container-fluid">
            <div>
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                            <h5 className="page-title"> Customer Ledger Report</h5>
                        </div>
                        <div className="card">
                            <h5 className="page-title"></h5>
                            <div className="card-body" style={{ paddingTop: "5px" }}>
                                <div className="row justify-content-center mt-2">
                                    {/* <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            Plan
                                        </label>
                                        <select
                                            className="form-control"
                                            id="example-select"
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    PlanId: e.target.value,
                                                    Customer_Id: "-1"
                                                });
                                            }} >
                                            <option value={-1}>All</option>
                                            {planData.map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item.Plan_Id}
                                                    label={item.Plan_Name}
                                                ></option>
                                            ))}
                                        </select>
                                    </div> */}
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            Customer
                                        </label>
                                        <Select
                                            id="example-select"
                                            options={[
                                                { value: -1, label: 'All' },
                                                ...customerData.map((item, index) => ({
                                                    value: item.Sr_No,
                                                    label: `${item.First_Name} ${item.Last_Name}`
                                                }))
                                            ]}
                                            onChange={(selectedOption) => {
                                                setFilter({
                                                    ...filter,
                                                    Customer_Id: selectedOption.value
                                                });
                                            }}
                                        />

                                    </div>

                                    {/* <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            From Date
                                        </label>
                                        <input type="date" className="form-control"
                                            value={filter.fromDate}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    fromDate: e.target.value,
                                                });
                                            }}></input>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            To Date
                                        </label>
                                        <input type="date" className="form-control"
                                            value={filter.toDate}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    toDate: e.target.value,
                                                });
                                            }}></input>
                                    </div> */}
                                </div>
                                <div className="row justify-content-center mt-2">
                                    <div className="col-md-2">
                                        <button className="btn btn-sm btn-primary" onClick={filterData} style={{ width: "100%" }}>Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <TextField
                                        style={{ zIndex: 0 }}
                                        onChange={handleFilter}
                                        size="small"
                                        label="Search..."
                                    />
                                    <Tooltip title="Export to PDF" >
                                        <i className="mt-2" style={{ cursor: "pointer", padding: "5px", }} onClick={() => { DownloadPDF(); }}> <PictureAsPdfIcon /> </i>
                                    </Tooltip>
                                    <Tooltip title="Export to Excel">
                                        <i className="mt-2" style={{ padding: "5px", cursor: "pointer", }}
                                            onClick={() => { DownloadExcel(); }}>{" "}
                                            <Icon path={mdiFileExcel} size={1} />
                                        </i>
                                    </Tooltip>
                                </div>
                                <div className=" table-responsive">
                                    <table id="dataTableHover"
                                        className="table align-items-center table-flush table-hover paidInterest">
                                        <thead>
                                            <tr>
                                                <th className="text-left" style={{ padding: "10px" }}>Sr. No.</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Date</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Description</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Credit</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Debit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.length !== 0 ? (
                                                data.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-left" style={{ padding: "10px" }} >{index + 1}</td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {dayjs(item.Date).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                            {item.Description}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>{parseFloat(item.Credit).toFixed(2)}</td>

                                                            <td className="text-left" style={{ padding: "10px" }}>{parseFloat(item.Debit).toFixed(2)}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td className="text-center text-danger" colSpan={5}>
                                                        No Data Found !!
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={3} className="text-right" style={{ padding: "10px" }}>
                                                    <strong aligne="right">Total :</strong>
                                                </td>
                                                <td colSpan={1} className="text-left" style={{ padding: "10px" }}>
                                                    <strong>
                                                        {parseFloat(TotalCredit).toFixed(2)}
                                                    </strong>
                                                </td>

                                                <td colSpan={1} className="text-left" style={{ padding: "10px" }}>
                                                    <strong>
                                                        {parseFloat(TotalDebit).toFixed(2)}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </div>
    );
}

export default Ledger_Report;