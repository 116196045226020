import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import DownloadIcon from "@mui/icons-material/Download";
import { useUser } from "../../componets/UserContext";
import dayjs from "dayjs";
import { error } from "../Alerts/alert";
import OTP from "../User/UserOTP";


function generateOTP() {
    const otp = Math.floor(1000 + Math.random() * 9000);
    return otp.toString();
  }

const TopUpInvestment = ({ open, onClose, detail, type , customKey }) => {
    const { userData } = useUser();
    const [AttatchmentFileName, setAttatchmentFileName] = useState("Choose file");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [typeData, setTypeData] = useState([]);
    const [plan, setPlanOption] = useState([]);
    const [bankData, setBankData] = useState([]);
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [selectedRate, setSelectedRate] = useState(0);

console.log(detail)

    const [paymentDetail, setPaymentDetail] = useState({
        Plan_Id: detail.Plan_Id,
        Plan_Name: detail.Plan_Name,
        Old_Plan: detail.Plan_Name,
        Customer_Id: detail.Customer_Id,
        Customer_Name: detail.Customer_Name,
        Enrollment_No: detail.Enrollment_No,
        Investment_Date: type === "Submit" ? "" : formatInputDate(detail.Investment_Date),
        Start_Date: type === "Submit" ? "" : formatInputDate(detail.Start_Date),
        Investment_Amount: type === "Submit" ? "" : detail.Investment_Amount,
        Rate_Of_Int: type === "Submit" ? "" : detail.Rate_Of_Int,
        Mode_Of_Investment: type === "Submit" ? "Cash" : detail.Mode_Of_Investment,
        Bank: type === "Submit" ? "" : detail.Bank,
        Branch_Name: type === "Submit" ? "" : detail.Branch_Name,
        Cheque_No: type === "Submit" ? "" : detail.Cheque_No,
        Cheque_Date: type === "Submit" ? null : formatInputDate(detail.Cheque_Date),
        Reference_No: type === "Submit" ? "" : detail.Reference_No,
        Payment_Doc: type === "Submit" ? "" : detail.Payment_Doc,
        Percentage_Type_Id: detail.Percentage_Type_Id,
        Percentage_Type: detail.Percentage_Type,
        Added_By_Employee_Id: "",
        Remarks: type === "Submit" ? "Cash" : detail.Remarks,
        Added_By_Id: "",
        Added_By: "",
        Org_Id: type === "Submit" ? "Cash" : detail.Org_Id,
    });
    function formatInputDate(dateString) {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }
    useEffect(() => {
        if (paymentDetail && paymentDetail.Plan_Id) {
            (async () => {
                showLoader();
                const cap_Amount = await axios.get(
                    `${apiUrl}/getPlanss/${paymentDetail.Plan_Id}`
                );

                setSelectedRate(parseFloat(cap_Amount.data.Interest_Rate).toFixed(2));
                hideLoader();
            })();
        }
    }, [paymentDetail.Plan_Id]);

    useEffect(() => {
        if (userData && userData.Org_Id && detail) {
            TypeLoad();
            bankLoad();
        }
        if (userData?.Org_Id && detail?.Sr_No) {
            MatchPlan();
        }
    }, [userData])

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const TypeLoad = async () => {
        showLoader();
        const TypeResult = await axios.get(
            `${apiUrl}/getPercentageTypes?OrgId=${userData.Org_Id}`
        );
        setTypeData(TypeResult.data);
        hideLoader();
    };
    const MatchPlan = async () => {
        showLoader();
        const match = await axios.get(`${apiUrl}/getCompaireInvestmentLog?OrgId=${userData.Org_Id}&Customer_Id=${detail.Customer_Id}&Sr_No=${detail.Sr_No}&Plan_Id=${detail.Plan_Id}`);
        //console.log(match.data)
        setPaymentDetail({
            ...paymentDetail,
            Status: match.data?.palnMatched === true ? 'Topup':'TopupUpgraded',
            Customer_Mobile: match.data?.Mobile
        });
        hideLoader();
    };
    const bankLoad = async () => {
        showLoader();
        const res = await axios.get(`${apiUrl}/getBanks?OrgId=${userData.Org_Id}`);
        setBankData(res.data);
        const plan = await axios.get(`${apiUrl}/getPlans?OrgId=${userData.Org_Id}`);
        setPlanOption(plan.data);

        hideLoader();
    };

    const submitHandler = (event) => {
        debugger;
        event.preventDefault();
        const updatedPayment = {
            ...paymentDetail,
            Rate_Of_Int: selectedRate,
            Added_By_Employee_Id: userData.Employee_Id ? userData.Employee_Id : "",
            User_Role: userData.role,
            Added_By_Id: userData.userId,
            Added_By: `${userData.firstName} ${userData.lastName}`,
            Org_Id: userData.Org_Id,
            UserName:userData.UserName,
        }
        const formdata = new FormData();
        formdata.append("Plan_Id", paymentDetail.Plan_Id);
        formdata.append("Plan_Name", paymentDetail.Plan_Name);
        formdata.append("Old_Plan", paymentDetail.Old_Plan);
        formdata.append("Customer_Id", paymentDetail.Customer_Id);
        formdata.append("Customer_Name", paymentDetail.Customer_Name);
        formdata.append("Enrollment_No", paymentDetail.Enrollment_No);
        formdata.append("Start_Date", paymentDetail.Start_Date);
        formdata.append("Investment_Date", paymentDetail.Investment_Date);
        formdata.append("Investment_Amount", paymentDetail.Investment_Amount);
        formdata.append("Rate_Of_Int", updatedPayment.Rate_Of_Int);
        formdata.append("Mode_Of_Investment", paymentDetail.Mode_Of_Investment);
        formdata.append("Bank", paymentDetail.Bank);
        formdata.append("Branch_Name", paymentDetail.Branch_Name);
        formdata.append("Cheque_No", paymentDetail.Cheque_No);
        formdata.append("Cheque_Date", paymentDetail.Cheque_Date);
        formdata.append("Reference_No", paymentDetail.Reference_No);
        formdata.append("Payment_Doc", paymentDetail.Payment_Doc);
        formdata.append("Percentage_Type_Id", paymentDetail.Percentage_Type_Id);
        formdata.append("Percentage_Type", paymentDetail.Percentage_Type);
        formdata.append("Added_By_Employee_Id", updatedPayment.Added_By_Employee_Id);
        formdata.append("Added_By_Id", updatedPayment.Added_By_Id);
        formdata.append("Added_By", updatedPayment.Added_By);
        formdata.append("User_Role", updatedPayment.User_Role);
        formdata.append("UserName", updatedPayment.UserName);
        formdata.append("Org_Id", updatedPayment.Org_Id);

        showLoader();
        if (type === "Submit") {
            axios
                .post(`${apiUrl}/investmentLog`, formdata)
                .then((res) => {
                    handleClose();
                    Swal.fire(
                        "Success!",
                        "Data Inserted Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                        }
                    });
                })
                .catch((err) => {
                    error("Server is Busy!!");
                    hideLoader();
                });
        }
        else if (type === "Update") {
            axios
                .post(`${apiUrl}/investmentLog/${detail.Sr_No}`, formdata)
                .then((res) => {
                    handleClose();
                    Swal.fire(
                        "Success!",
                        "Data Inserted Successfully!!",
                        "success"
                    ).then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                    error("Server is Busy!!");
                    hideLoader();
                });
        }
    }


    const [isModal, setIsModal] = useState(false);

    const showSummarry = () => {
        setIsModal(true);
    };

    const closed = () => {
        setIsModal(false);
        handleClose();
    };

    const insertOTP = async (event) => {
        event.preventDefault();

        const newOTP = generateOTP();

        const otp = {
            Employee: `${userData.firstName} ${userData.lastName}`,
            OTP: newOTP,
            Name: paymentDetail?.Customer_Name,
            OrgId: userData?.Org_Id,
            number:  userData.Mobile,
            messageTitle: "Topup Verification OTP",
        };

        showLoader();

        axios
            .put(`${apiUrl}/insertUserOpt/${userData?.userId}`, otp)
            .then((res) => {
                //navigate("/viewCustomer");
                //LoadCategory();
                hideLoader();
                showSummarry();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Generate OTP. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
                //handleCancelEdit();
            });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Topup Investment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submitHandler} >
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <label>Enrollment No</label>
                            <input
                                type="text"
                                disabled
                                id="txtEnrollment"
                                name="txtEnrollment"
                                placeholder="Enrollment No"
                                className="form-control"
                                value={paymentDetail.Enrollment_No}
                            ></input>
                        </div>
                        <div className="col-md-4">
                            <label>Plan</label>
                            <span className="text-danger">*</span>
                            <select className="form-control"
                                required
                                value={paymentDetail.Plan_Id}
                                onChange={(e) => {
                                    const selectedIndex = e.target.selectedIndex;
                                    const selectedOption = e.target[selectedIndex];
                                    setPaymentDetail({
                                        ...paymentDetail,
                                        Plan_Id: selectedOption.value,
                                        Plan_Name: selectedOption.label,
                                    });
                                }}
                            >
                                <option disabled value="" label="--- Select ---"></option>
                                {plan?.map((item) => (
                                    <option
                                        key={item.Plan_Id}
                                        //selected={detail.Plan_Id === item.Plan_Id}
                                        value={item.Plan_Id} label={item.Plan_Name}
                                    ></option>
                                ))}
                            </select>

                        </div>
                        <div className="col-md-4">
                            <label>Name</label>
                            <input type="text" disabled id="txtName" name="txtName" placeholder="Name" className="form-control"
                                value={paymentDetail.Customer_Name}
                            ></input>
                        </div>
                        {/*  <div className="col-md-3">
                            <label>Interest Type</label>
                            <span className="text-danger">*</span>
                            <select className="form-control" value={detail.Percentage_Type_Id} disabled >
                                <option value="" label="--- Select ---"></option>
                                {detail && typeData && typeData.map((item, index) => (
                                    <option
                                        key={index} selected={item.Sr_No === detail.Percentage_Type_Id}
                                        value={item.Sr_No} label={item.Percentage_Type_Name}
                                    ></option>
                                ))}
                            </select>
                            <p className="text-danger m-0">{errors.Payment_Date}</p>
                                </div>*/}
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-md-4">
                            <label>Investment Date</label>
                            <span className="text-danger">*</span>
                            <input type="date" id="txtDate" name="txtDate" value={paymentDetail.Investment_Date}
                                onChange={(e) =>
                                    setPaymentDetail({
                                        ...paymentDetail,
                                        Investment_Date: e.target.value,
                                    })
                                }
                                placeholder="Start Date" className="form-control" required
                            ></input>
                            <p className="text-danger m-0">{errors.Investment_Date}</p>
                        </div>
                        <div className="col-md-4">
                            <label>Start Date</label>
                            <span className="text-danger">*</span>
                            <input type="date" id="txtDate" name="txtDate" value={paymentDetail.Start_Date}
                                onChange={(e) =>
                                    setPaymentDetail({
                                        ...paymentDetail,
                                        Start_Date: e.target.value,
                                    })
                                }
                                placeholder="Start Date" className="form-control" required
                            ></input>
                            <p className="text-danger m-0">{errors.Start_Date}</p>
                        </div>
                        <div className="col-md-4">
                            <label>Amount</label> <span className="text-danger">*</span>
                            <input type="text" id="txtAmount" name="txtAmount" placeholder="Top Up Amount" required
                                className="form-control" value={paymentDetail.Investment_Amount}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    const numericInput = input.replace(/[^0-9]/g, "");
                                    setPaymentDetail({
                                        ...paymentDetail,
                                        Investment_Amount: numericInput,
                                    })
                                }
                                }></input>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-md-4">
                            <label>Rate of Interest</label>
                            <span className="text-danger">*</span>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                name="Rate_Of_Int"
                                required
                                //options={rateOption}
                                disabled
                                value={selectedRate}
                                //onChange={setSelectedRate}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    const decimalInput = input.replace(/[^0-9.]/g, "");
                                    const amount = decimalInput.replace(
                                        /^(\d*\.\d*)\..*$/,
                                        "$1"
                                    );
                                    setSelectedRate(amount);
                                    //setErrors({ ...errors, sumMismatch:''});
                                }}
                                id="Rate_Of_Int"
                            />
                            <p className="text-danger m-0">{errors.Rate_Of_Int}</p>
                        </div>
                        <div className="col-md-4">
                            <label>Payment Mode</label>
                            <span className="text-danger">*</span>
                            <select
                                className="form-control"
                                value={paymentDetail.Mode_Of_Investment}
                                onChange={(e) =>
                                    setPaymentDetail({
                                        ...paymentDetail,
                                        Mode_Of_Investment: e.target.value,
                                    })
                                }
                            >
                                <option value="Online" label="Online"></option>
                                <option value="Cash" label="Cash"></option>
                                <option value="Cheque" label="Cheque"></option>
                                <option value="IMPS" label="IMPS"></option>
                                <option value="GPAY" label="GPAY"></option>
                                <option value="PhonePay" label="PhonePay"></option>
                                <option value="PAYTM" label="PAYTM"></option>
                                <option value="DD" label="DD"></option>
                                <option value="NEFT" label="NEFT"></option>
                                <option value="RTGS" label="RTGS"></option>
                            </select>
                        </div>

                        <div className="col-md-4">
                            <label>Payment Attatchment</label>
                            <div className="custom-file" style={{}} >
                                <input type="file" className="custom-file-input" id="Payment_Doc"
                                    name="Payment_Doc" accept=".jpg, .jpeg, .png, .pdf"
                                    onChange={(e) => {
                                        const fileName =
                                            e.target.files[0]?.name || "Choose file";
                                        setAttatchmentFileName(fileName);

                                        setPaymentDetail({
                                            ...paymentDetail,
                                            Payment_Doc: e.target.files[0],
                                        });
                                    }}
                                />
                                <label
                                    className="custom-file-label"
                                    htmlFor="Attatchment"
                                    style={{ overflow: "hidden" }}
                                >
                                    {AttatchmentFileName}
                                </label>
                            </div>
                        </div>
                    </div>
                    {(paymentDetail.Mode_Of_Investment === "Cheque" || paymentDetail.Mode_Of_Investment === "DD") && (
                        <div className="row mt-3">
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label htmlFor="Bank" className="form-label">
                                        Bank Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select className="form-control" value={paymentDetail.Bank||""} required
                                        onChange={(e) =>
                                            setPaymentDetail({
                                                ...paymentDetail,
                                                Bank: e.target.value,
                                            })
                                        }>
                                        <option value="" label="--- Select ---"></option>
                                        {bankData.map((item, index) => (
                                            <option
                                                key={index}
                                                value={item.Bank_Name}
                                                label={item.Bank_Name}
                                            ></option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label htmlFor="Branch_Name" className="form-label">
                                        Branch Name
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input value={"" || paymentDetail.Branch_Name} required className="form-control"
                                        type="text" id="Branch_Name" name="Branch_Name" placeholder="Enter Branch Name"
                                        onChange={(e) =>
                                            setPaymentDetail({
                                                ...paymentDetail,
                                                Branch_Name: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label htmlFor="Cheque_No" className="form-label">
                                        Cheque No <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="Cheque_No"
                                        name="Cheque_No"
                                        placeholder="Enter Cheque No"
                                        value={paymentDetail.Cheque_No}
                                        required={true}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const numericInput = input.replace(
                                                /[^0-9]/g,
                                                ""
                                            );
                                            setPaymentDetail({
                                                ...paymentDetail,
                                                Cheque_No: numericInput,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-3">
                                    <label htmlFor="Cheque_Date" className="form-label">
                                        Cheque Date{" "}
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="Cheque_Date"
                                        name="Cheque_Date"
                                        value={paymentDetail.Cheque_Date}
                                        required={true}
                                        onChange={(e) =>
                                            setPaymentDetail({
                                                ...paymentDetail,
                                                Cheque_Date: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>)}
                    {paymentDetail.Mode_Of_Investment !== "Cheque" && paymentDetail.Mode_Of_Investment !== "DD" &&
                        paymentDetail.Mode_Of_Investment !== "Cash" && (
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="Reference_No" className="form-label">
                                            Transaction ID{" "}
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="Reference_No"
                                            name="Reference_No"
                                            required={true}
                                            placeholder="Enter Transaction ID"
                                            value={paymentDetail.Reference_No}
                                            onChange={(e) =>
                                                setPaymentDetail({
                                                    ...paymentDetail,
                                                    Reference_No: e.target.value.toUpperCase(),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    <div className="row justify-content-center my-3">
                        <div className="col-md-2 text-center">
                            <button type="submit" className="btn btn-sm btn-primary">
                                {type === "Submit" ? "Submit" : "Update"}
                            </button> &nbsp;
                            {(type === "Update" && customKey  === 'Verify') && (
                                <button className="btn btn-sm btn-success" onClick={(e) => {
                                    e.preventDefault();
                                    insertOTP(e);
                                }}>
                                    Verify
                                </button>
                                )}
                        </div>
                    </div>
                  
                </form>
                {isModal && (
                    <OTP
                        open={isModal}
                        EmpId={userData?.userId}
                        onClose={closed}
                        role={userData?.role}
                        name={paymentDetail?.Customer_Name}
                        messageData={paymentDetail}
                        type='Topup'
                        Id={detail?.Sr_No}
                    />
                )}
                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default TopUpInvestment;
