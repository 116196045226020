import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import Swal from "sweetalert2";
import "../Customers/modal.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../componets/UserContext";


function generateOTP() {
  const otp = Math.floor(1000 + Math.random() * 9000);
  return otp.toString();
}

const UserOTP = ({ open, onClose, EmpId, role, name , type, Id, messageData}) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [error, setError] = useState({});
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const { userData } = useUser();

  const [adminData, setAdminData] = useState({});

  console.log(messageData , type, role);

  useEffect(() => {
      axios
        .get(`${apiUrl}/getAdminMobile`)
        .then((res) => {
          const data = res.data;
          //console.log(data);
          setAdminData(data);
        });
 
  }, []);

  const handleClose = (e) => {
    setShow(false);
    setOtp("");
    onClose(e);
  };

  const handleShow = () => setShow(true);

  const [showResend, setShowResend] = useState(false);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 0) {
        setShowResend(true);
        clearInterval(interval);
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const handleResend = (event) => {
    if (event) {
      event.preventDefault();
      insertOTP(event);
    }
    setSeconds(30);
    setShowResend(false);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const Verified = {
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName:userData.UserName,
      Role: role,
      otp: otp,
      type: type,
      name: name,
      Id:Id,
      Status:messageData?.Status||"",
      Enrollment_No: messageData?.Enrollment_No ||"",
      Rate: messageData?.Rate_Of_Int ||"",
      Amount:messageData?.Investment_Amount ||"",
      Investment_Type:messageData?.Plan_Name ||"",
      Customer_Mobile:messageData?.Customer_Mobile || ""
    };

   
    const validation = {};

    if (!otp || otp.length !== 4 || !/^\d+$/.test(otp)) {
      validation.OTP = "Please enter a valid 4-digit OTP!";
    }

    if (Object.keys(validation).length === 0) {
      showLoader();

      await axios
        .put(`${apiUrl}/verifyUser/${EmpId}`, Verified)
        .then((res) => {
        

          if (type === 'verify') {
            Swal.fire("Success!", "Account Verified Successfully!!", "success");
            navigate(`/verifyUser`);
            handleClose();
          }
          if (type === 'Topup') {
            Swal.fire("Success!", "Topup Verified Successfully!!", "success");
            navigate(`/verifyTopup`);
            handleClose();
          }
          if (type === 'Upgrade') {
            Swal.fire("Success!", "Plan Upgradation Verified Successfully!!", "success");
            navigate(`/verifyUpgradation`);
            handleClose();
          }
          if (type === 'edit') {
            handleClose('verified');
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            Swal.fire({
              title: "Error",
              text: "Wrong OTP. Please enter the correct OTP!",
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            console.log(err);
            Swal.fire({
              title: "Error",
              text: "Failed to Update. Please try again later.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
          hideLoader();
        });
    } else {
      setError(validation);
    }
  };

  const insertOTP = async (event) => {
    event.preventDefault();
    const newOTP = generateOTP();
    const otp = {
      Employee : `${userData.firstName} ${userData.lastName}`,
      OTP: newOTP,
      Name : name,
      OrgId : userData.Org_Id,
      Email: userData.email,
      number: type === 'edit' ? adminData.Mobile : userData.Mobile,
      messageTitle:
      type === "edit" 
    ? "Emplyee Update OTP" 
    : type === "verify" 
        ? "User Verification" 
        : type === "Topup" 
        ? "Topup Verification OTP": 
        "Upgradation Verification OTP"
    };

    console.log(otp);
    showLoader();

    await axios
      .put(`${apiUrl}/insertUserOpt/${EmpId}`, otp)
      .then((res) => {
        //Swal.fire("Success!", "OTP has been Generated.", "success");

        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Generate OTP. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        //handleCancelEdit();
        hideLoader();
      });
  };



  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        centered
        style={{ boxShadow: "none !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"17px"}}>
            {userData && userData.Mobile ? (`Enter The OTP Send To ${userData.Mobile}`) : ("")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "170px" }}>
          <form onSubmit={submitHandler}>
            <div className="row justify-content-center">
              <div className="col-12 col-md-5 text-center">
                <div className="form-group">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="form-control"
                        style={{ width: "40px" }}
                      />
                    )}
                  />
                </div>
                {error.OTP && (
                  <div className="alert alert-danger">{error.OTP}</div>
                )}
              </div>
              <div className="col-md-12 text-center mb-3">
                {seconds > 0 ? (
                  <div className="">{`Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds
                    }`}</div>
                ) : (
                  <a
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleResend(e)}
                  >
                    Resend OTP
                  </a>
                )}
              </div>
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  
                >
                  Submit OTP
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
     
      {loader}
    </div>
  );
};

export default UserOTP;
