import "./UserPreview.css";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import FileDownloadOffIcon from "@mui/icons-material/VisibilityOff";
import DownloadIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../componets/UserContext";

const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
};

const CustomerPreview = ({ open, onClose, userDetail, opration }) => {
  const handleClose = () => {
    onClose(false);
  };
  const handleSubmit = () => {
    onClose(true);
  };

  const Download = (file, name) => {
    debugger;
    if (file && name) {
      const blob = new Blob([file], { type: file.type });
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element to view the file
      const a = document.createElement('a');
      a.href = url;
      a.target = "_blank"; // Open in a new tab
      //a.download = name;
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        size="xl"
        centered
        style={{ boxShadow: "none !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "17px" }}>User Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="bootstrap snippets bootdey"
            style={{ padding: "0 10px" }}
          >
            <div className="row">
              <div className="profile-nav col-md-3">
                <div className="card">
                  <div className="user-heading round">
                    <a href="#">
                      {userDetail.Photo ? (
                        <>
                          {opration === 'AddUser' ? (
                            <img
                              src={URL.createObjectURL(userDetail.Photo)}
                              alt="user"
                            />
                          ) : (
                            <img
                              src={`${apiUrl}/downloadEmpDocView/` + userDetail.Photo}
                              alt="user"
                            />
                          )}
                        </>

                      ) : (
                        <img src="/img/dummy.png" alt="user" />
                      )}
                    </a>
                    <h6>
                      {userDetail.First_Name} {userDetail.Last_Name}
                    </h6>
                    <p>{userDetail.Employee_Id}</p>
                  </div>
                </div>
              </div>
              <div className="profile-info col-md-9">
                <div className="card">
                  <div className="card-body bio-graph-info">
                    <h1
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#37546c ",
                      }}
                    >
                      User's Detail :
                    </h1>
                    <div className="row">
                      <div className="col-md-4">
                        <p>
                          <span>Employee ID </span>: {userDetail.Employee_Id}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <span>First Name </span>: {userDetail.First_Name}
                        </p>
                      </div>

                      <div className="col-md-4">
                        <p>
                          <span>Last Name </span>: {userDetail.Last_Name}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <p>
                          <span>Mobile </span>: {userDetail.Mobile}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <span>Email </span>:{" "}
                          {userDetail.Email ? userDetail.Email : "--"}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <span>Joining Date </span>:{" "}
                          {formatDate(userDetail.Joining_Date)}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <p>
                          <span>Role </span>: {userDetail.Role}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <span>Gender </span>: {userDetail.Gender}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <span>DOB </span>:{" "}
                          {userDetail.DOB ? formatDate(userDetail.DOB) : "--"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          <span>Address </span>:{" "}
                          {userDetail.Address ? userDetail.Address : "--"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <span>Document1 : </span>
                          {userDetail.EmpDocument1 ? (
                            <>
                              {opration === 'AddUser' ? (
                                <a
                                  style={{ color: "blue" }}
                                  onClick={() =>
                                    Download(
                                      userDetail.EmpDocument1,
                                      "EmpDocument1.png"
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  <DownloadIcon />
                                </a>
                              ) : (
                                <a
                                style={{ color: "blue" }}
                                href={`${apiUrl}/downloadEmpDocView/` + userDetail.EmpDocument1} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="cursor-pointer"
                              >
                                <DownloadIcon />
                              </a>)}
                            </>

                          ) : (
                            <a style={{ color: "grey" }}>
                              <FileDownloadOffIcon />
                            </a>
                          )}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-md-6">

                        <p>
                          <span>Document2 : </span>
                          {userDetail.EmpDocument2 ? (
                            <>
                            {opration === 'AddUser' ? (
                              <a
                              style={{ color: "blue" }}
                              onClick={() =>
                                Download(
                                  userDetail.EmpDocument2,
                                  "EmpDocument2.png"
                                )
                              }
                              className="cursor-pointer"
                            >
                              <DownloadIcon />
                            </a>
                            ):( <a
                                style={{ color: "blue" }}
                                href={`${apiUrl}/downloadEmpDocView/` + userDetail.EmpDocument2} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="cursor-pointer"
                              >
                                <DownloadIcon />
                              </a>)}
                            </>
                          
                          ) : (
                            <a style={{ color: "grey" }}>
                              <FileDownloadOffIcon />
                            </a>
                          )}
                        </p>{" "}
                      </div>
                    </div>
                    {opration === 'AddUser' && (
                      <div className="row justify-content-center">
                        <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                          <button
                            type="submit"
                            className="btn btn-primary "
                            onClick={handleSubmit}
                          >
                            Add User
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerPreview;
