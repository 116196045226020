import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import axios from "axios";
import "jspdf-autotable";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import InterestPayment from "./InterestPayment";
import dayjs from "dayjs";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";

const type = [
  { label: "Slabwise", value: "Slabwise" },
  { label: "Fixed", value: "Fixed" },
]

const InterestRate = () => {
  const { userData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [indexValue, setIndex] = useState();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setAttendanceData] = useState([]);
  const [investmentLog, setInvetmentLog] = useState([]);
  const [planDrop, setplanDrop] = useState([]);
  const [filter, setFilter] = useState({
    Investment_Type_Id: "-1",
    Investment_Percentage_Type: "-1"
  });
  const [InterestAmount, setInterestAmount] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());


  const [monthDate, setDate] = useState([]);
  const [Intrest, setIntrest] = useState([]);
  const [IntrestData, setIntrestData] = useState([]);
 // console.log(Intrest)

  
  function calculate() {
    const interest = data.map((item) => {
      const { startDate, endDate } = getStartAndEndDates(currentYear, currentMonth);
      const today = new Date();
      const todayDate = today.getDate();
      const todayMonth = today.getMonth() + 1; // JavaScript months are zero-based
      const todayYear = today.getFullYear();
  
      let intrest = 0, days = 0;
      let startIDate, endIDate;
  
      if (item.IsActive === "Active") {
        if (new Date(item.Start_Date).getMonth() + 1 === currentMonth && new Date(item.Start_Date).getFullYear() === currentYear) {
          startIDate = item.Start_Date;
          endIDate = (todayYear === currentYear && todayMonth === currentMonth) ? today : endDate;
          intrest = calculateMIP(
            item.Start_Date,
            endIDate,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Start_Date).daysInMonth()
          );
        } else if (new Date(item.Request_Date).getMonth() + 1 === currentMonth && new Date(item.Request_Date).getFullYear() === currentYear) {
          startIDate = startDate;
          endIDate = (todayYear === currentYear && todayMonth === currentMonth) ? (today < new Date(item.Request_Date) ? today : new Date(item.Request_Date)) : item.Request_Date;
          intrest = calculateCloseMIP(
            startDate,
            endIDate,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Request_Date).daysInMonth(),
            item.Last_Payable_Int_days,
            item.IsActive
          );
          
        } else {
          startIDate = startDate;
          endIDate = (todayYear === currentYear && todayMonth === currentMonth) ? today : endDate; 
          if (todayYear === currentYear && todayMonth === currentMonth) {
            intrest = calculateMIP(
              startIDate,
              endIDate,
              item.Capital_Amount,
              item.Rate_Of_Int,
              dayjs(item.Start_Date).daysInMonth()
            );
          } else {
            intrest = calculateRegularMIP(
              item.Capital_Amount,
              item.Rate_Of_Int
            );
          }
        }
  
        const matchingInterest = investmentLog.find(
          (interest) =>
            parseInt(interest.Month) === currentMonth && parseInt(interest.Year) === currentYear &&
            interest.Customer_Id === item.Sr_No &&
            parseFloat(interest.Amount).toFixed(2) === parseFloat(intrest).toFixed(2)
        );
        let IntrestPaid = matchingInterest ? 1 : 0;
        
        return {
          Enrollment_No: item.Enrollment_No,
          Customer_Id: item.Sr_No,
          Plan: item.Investment_Type,
          Start_Date: item.Start_Date,
          startDate: startIDate,
          enddate: endIDate,
          First_Name: item.First_Name,
          Middle_Name: item.Middle_Name,
          Last_Name: item.Last_Name,
          IsActive: item.IsActive,
          Request_Date: item.Request_Date,
          InterestAmount: intrest,
          Mobile: item.Mobile,
          Rate_Of_Int: item.Rate_Of_Int,
          Capital_Amount: item.Capital_Amount,
          IntrestPaid: IntrestPaid,
          Percentage_Type: item.Percentage_Type
        }
      } else if (item.IsActive === "Closed") {
        if (new Date(item.Request_Date).getMonth() + 1 === currentMonth && new Date(item.Request_Date).getFullYear() === currentYear) {
          startIDate = startDate;
          endIDate = (todayYear === currentYear && todayMonth === currentMonth) ? (today < new Date(item.Request_Date) ? today : new Date(item.Request_Date)) : item.Request_Date;
          intrest = calculateCloseMIP(
            startDate,
            endIDate,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Request_Date).daysInMonth(),
            item.Last_Payable_Int_days,
            item.IsActive
          );
          console.log(item.Request_Date, today)
          const matchingInterest = investmentLog.find(
            (interest) =>
              parseInt(interest.Month) === currentMonth && parseInt(interest.Year) === currentYear &&
              interest.Customer_Id === item.Sr_No &&
              interest.Amount === parseFloat(intrest.toFixed(2))
          );
          let IntrestPaid = matchingInterest ? 1 : 0;
          return {
            Enrollment_No: item.Enrollment_No,
            Customer_Id: item.Sr_No,
            Plan: item.Investment_Type,
            Start_Date: item.Start_Date,
            startDate: startIDate,
            enddate: dayjs(endIDate).subtract(item.Last_Payable_Int_days, "days").format("YYYY-MM-DD"),
            First_Name: item.First_Name,
            Middle_Name: item.Middle_Name,
            Last_Name: item.Last_Name,
            IsActive: item.IsActive,
            Request_Date: item.Request_Date,
            InterestAmount: intrest,
            Mobile: item.Mobile,
            Rate_Of_Int: item.Rate_Of_Int,
            Capital_Amount: item.Capital_Amount,
            Percentage_Type: item.Percentage_Type,
            IntrestPaid: IntrestPaid
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).filter(Boolean);
    return interest;
  }

  
  useEffect(() => {
    
    showLoader();
    if (data.length > 0) {
      setIntrest(calculate());
      setIntrestData(calculate());
    }
    hideLoader();
  }, [data]);

  //console.log(Intrest);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const calculateDaysDifference = (startDate, endDate) => {
    const start = dayjs(startDate).startOf("day");
    const end = dayjs(endDate).startOf("day");
    const daysDifference = end.diff(start, "day");
///console.log(daysDifference)
    return daysDifference;
  };

  const calculateMIP = (
    startDate,
    endDate,
    principalAmount,
    monthlyInterestRate,
    monthDayscount
  ) => {
    const countDays = calculateDaysDifference(startDate, endDate);
    const updatedDays = countDays + 1;
    const InterestAmount = (principalAmount * monthlyInterestRate) / 100;
    const monthlyPayout = (InterestAmount * updatedDays) / monthDayscount;

    return monthlyPayout;
  };
  
  const calculateRegularMIP = (principalAmount, monthlyInterestRate) => {
    const monthlyPayout = (principalAmount * monthlyInterestRate) / 100;

    return monthlyPayout;
  };

  const calculateCloseMIP = (
    startDate,
    endDate,
    principalAmount,
    monthlyInterestRate,
    monthDayscount,
    LastPayableIntdays,
    isActive
  ) => {
    const countDays = calculateDaysDifference(
      new Date(startDate),
      new Date(endDate)
    );
    let updatedDays=0;
    debugger;
    if(isActive !== "Closed")
    {
      updatedDays = countDays + 1;
    }
    else
    {
      updatedDays = countDays + 1 - LastPayableIntdays;      
    }
    const InterestAmount = (principalAmount * monthlyInterestRate) / 100;
    const monthlyPayout = (InterestAmount * updatedDays) / monthDayscount;

    return monthlyPayout;
  };

  function getDaysInMonth(month, year) {
    var date = new Date(year, month - 1, 1);
    var days = [];

    while (date.getMonth() === month - 1) {
      days.push({
        mDate: new Date(date).toLocaleDateString("en-GB"),
        Day: new Date(date).toLocaleDateString("en-GB", { weekday: "long" }),
      });
      date.setDate(date.getDate() + 1);
    }

    return days;
  }





  useEffect(() => {
    showLoader();
    // Calculate total interest when data changes
    const totalInterest = Intrest.reduce((total, item) => {

      return total + parseFloat(item.InterestAmount);
    }, 0);

    setInterestAmount(totalInterest);
    //console.log(totalInterest);
    hideLoader();
  }, [Intrest, currentMonth, currentYear]);

  useEffect(() => {
    if (userData && userData.Org_Id) {
      Load();
    }
  }, [userData]);

  const Load = async () => {
  
    showLoader();
    const staffResult = await axios.get(
      `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
    );
    if (staffResult.data.length === 0) {
      setplanDrop([]);
    } else {
      setplanDrop(staffResult.data);
    }

    try {
      const result = await axios.get(
        `${apiUrl}/getCustomersbyMonthYear?OrgId=${userData.Org_Id}&Plan=${filter.Investment_Type_Id}&Month=${currentMonth}&Year=${currentYear}&Type=${filter.Investment_Percentage_Type}`
      );
      const res = await axios.get(
        `${apiUrl}/getInterestPaymentLogInReport?OrgId=${userData.Org_Id}`
      );
      setInvetmentLog(res.data)
      //console.log(result);
      if (result.data.length === 0) {
        setAttendanceData([]);
        setIntrest([]);
        setIntrestData([]);
        hideLoader();
      } else {
        setAttendanceData(result.data);
        console.log(result.data);
        setDate(getDaysInMonth(currentMonth, currentYear));

        hideLoader();
      }
    } catch (error) {
      hideLoader();
      setAttendanceData([]);
    }
  };

  const monthChanged = (event) => {
    const month = parseInt(event.target.value);
    setCurrentMonth(month);
  };

  const yearChanged = (event) => {
    const year = parseInt(event.target.value);
    setCurrentYear(year);
  };

  const Filter = async () => {
    
    try {
      Load();
    } catch (error) {
      hideLoader();
      setAttendanceData([]);
    }
  };



  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  

  function getStartAndEndDates(year, month) {
    // Ensure month value is 0-indexed (January is 0, February is 1, and so on)
    const monthIndex = month - 1;

    // Get the start date of the specified month and year
    const startDate = dayjs()
      .year(year)
      .month(monthIndex)
      .startOf("month")
      .format("YYYY-MM-DD");

    // Get the end date of the specified month and year
    const endDate = dayjs()
      .year(year)
      .month(monthIndex)
      .endOf("month")
      .format("YYYY-MM-DD");

    return {
      startDate,
      endDate,
    };
  }
  const showSummarry = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    Load();
  };

  
  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Customer Interest Report", 20, 10);

    const columns = [
      "Sr. No.",
      "Enrollment No",
      "Capital Amount",
      "Interest Rate",
      "Customer Name",
      "IsActive",
      "From Date",
      "To Date",
      "Days",
      "Interest Amount"
    ];

    const rows = Intrest.map((row, index) => [
      index + 1,
        row.Enrollment_No,
        row.Capital_Amount,
        `${row.Rate_Of_Int}%`,
        `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
        row.IsActive,
        formatDate(row.startDate),
        formatDate(row.enddate),
        calculateDaysDifference(row.startDate, row.enddate)+1 || "0",
        parseFloat(row.InterestAmount).toFixed(2)
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Customer Interest Report.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Sr. No.",
      "Enrollment No",
      "Capital Amount",
      "Interest Rate",
      "Customer Name",
      "IsActive",
      "From Date",
      "To Date",
      "Days",
      "Interest Amount"
    ];
    const rows = [columns];
    Intrest.forEach((row, index) => {
      rows.push([
        index + 1,
        row.Enrollment_No,
        row.Capital_Amount,
        `${row.Rate_Of_Int}%`,
        `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
        row.IsActive,
        formatDate(row.startDate),
        formatDate(row.enddate),
        calculateDaysDifference(row.startDate, row.enddate)+1 || "0",
        parseFloat(row.InterestAmount).toFixed(2)
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Customer Interest Report");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Customer Interest Report.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = IntrestData.filter((row) => {
      return (
        (row.Enrollment_No && row.Enrollment_No.toLowerCase().includes(filterValue)) ||
        (row.Capital_Amount && row.Capital_Amount.toString().toLowerCase().includes(filterValue)) ||
        `${row.Rate_Of_Int}%`.includes(filterValue) ||
        `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`.toLowerCase().includes(filterValue) ||
        row.IsActive.toLowerCase().includes(filterValue) ||
        formatDate(row.startDate).includes(filterValue) ||
        formatDate(row.enddate).includes(filterValue) ||
        calculateDaysDifference(row.startDate, row.enddate).toString().includes(filterValue) ||
        parseFloat(row.InterestAmount).toFixed(2).toString().includes(filterValue)
      );
    });
    setIntrest(filteredData);
  }
  

  return (
    <div className="container-fluid">
      <div>
        <div className="row ">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
              <h5 className="page-title">Customer Interest</h5>
            </div>
            <div className="card">
              <h5 className="page-title"></h5>
              <div className="card-body" style={{ paddingTop: "5px" }}>
                <div className="row justify-content-center mt-2 mb-3">
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      Plan
                    </label>
                    <select
                      className="form-control"
                      id="example-select"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          Investment_Type_Id: e.target.value,
                        });
                      }}
                    >
                      <option value={-1}>All</option>
                      {planDrop.map((item, index) => (
                        <option
                          key={index}
                          value={item.Plan_Id}
                          label={item.Plan_Name}
                        ></option>
                      ))}
                    </select>
                  </div>
                 <div className="col-3 d-none">
                    <label htmlFor="example-select" className="form-label">
                      Type
                    </label>
                    <select
                      className="form-control"
                      id="example-select"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          Investment_Percentage_Type: e.target.value,
                        });
                      }}
                    >
                      <option value={-1}>All</option>
                      {type.map((item, index) => (
                        <option
                          key={index}
                          value={item.value}
                          label={item.label}
                        ></option>
                      ))}
                    </select>
                      </div>
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      Month
                    </label>
                    <select
                      className="form-control"
                      id="example-select"
                      onChange={monthChanged}
                      defaultValue={currentMonth}
                    >
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      Year
                    </label>
                    <select
                      className="form-control"
                      id="example-select"
                      onChange={yearChanged}
                      defaultValue={currentYear}
                    >
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                    </select>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={Filter}
                      style={{ width: "100%" }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <div className="card">
              <div className="card-body">
              <div className="mb-3">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />
                  <Tooltip title="Export to PDF">
                    <i
                      className="mt-2"
                      style={{ cursor: "pointer", padding: "5px" }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      {" "}
                      <PictureAsPdfIcon />{" "}
                    </i>
                  </Tooltip>
                  <Tooltip title="Export to Excel">
                    <i
                      className="mt-2"
                      style={{ padding: "5px", cursor: "pointer" }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
                <div className=" table-responsive">
                  <table
                    id="dataTableHover"
                    className="table align-items-center table-flush table-hover"
                  >
                    <thead>
                      <tr>
                        <th className="text-left" style={{padding: "10px"}}>Sr. No.</th>
                        <th className="text-left" style={{padding: "10px"}}>Enrollment No</th>
                        <th className="text-left" style={{padding: "10px"}}>Capital Amount</th>
                        <th className="text-left" style={{padding: "10px"}}>Interest Rate</th>
                        <th className="text-left" style={{padding: "10px"}}>Customer Name</th>
                        <th className="text-left" style={{padding: "10px"}}>IsActive</th>
                        <th className="text-left" style={{padding: "10px"}}>From Date</th>
                        <th className="text-left" style={{padding: "10px"}}>To Date</th>
                        <th className="text-left" style={{padding: "10px"}}>Days</th>
                        <th className="text-left" style={{padding: "10px"}}>Interest Amount</th>
                        <th className="text-center" style={{padding: "10px"}}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {Intrest.length !== 0 ? (
                        Intrest.map((item, index) => {

                          return (
                            <tr key={index} style={item.IntrestPaid === 1 ? { backgroundColor: "#66bb6a", color: "white" } : {}}>
                              <td className="text-left" style={{padding: "10px"}}>{index + 1}</td>
                              <td className="text-left" style={{padding: "10px"}}>
                                {item.Enrollment_No}
                              </td>
                              <td className="text-left" style={{padding: "10px"}}>
                                {item.Capital_Amount}
                              </td>
                              <td className="text-left" style={{padding: "10px"}}>
                                {item.Rate_Of_Int}%
                              </td>
                              <td className="text-left" style={{padding: "10px"}}>{`${item.First_Name} ${item.Middle_Name} ${item.Last_Name}`}</td>

                              <td className="text-left" style={{padding: "10px"}}>
                                <a
                                  id="punchStatus"
                                  style={{ cursor: "pointer" }}
                                  className={`badge rounded-pill ${item.IsActive === "Active"
                                    ? "badge badge-success text-white"
                                    : item.IsActive === "Closed"
                                      ? "badge badge-danger text-white"
                                      : "bg-primary-subtle text-primary"
                                    } rounded-pill`}
                                >
                                  {item.IsActive}
                                </a>
                              </td>
                              <td className="text-left" style={{padding: "10px"}}>
                                {formatDate(item.startDate)}
                              </td> 
                              <td className="text-left" style={{padding: "10px"}}>
                                {formatDate(item.enddate)}
                              </td> 
                              <td className="text-left" style={{padding: "10px"}}>
                              {calculateDaysDifference(item.startDate, item.enddate)+1 || "0"}
                              </td> 
                              <td className="text-left" style={{padding: "10px"}}>
                                {parseFloat(item.InterestAmount).toFixed(2)}
                              </td>
                              <td className="text-center" style={{padding: "10px"}}>
                                {item.IntrestPaid === 0 ?
                                  (<button className="btn btn-sm btn-success"
                                    onClick={(e) => { e.preventDefault(); showSummarry(index); }}>Pay</button>) :
                                  (<></>)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center text-danger" colSpan={11}>
                            No Data Found for Selected Month and Year
                          </td>
                        </tr>
                      )}
                    </tbody>
                  
                    <tfoot>
                      <tr>
                        <td colSpan={9} className="text-right">
                          <strong >Total Interest Amount:</strong>
                        </td>
                        <td  colSpan={2} className="text-left">
                          <strong>
                            {parseFloat(InterestAmount).toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModal && (
        <InterestPayment
          open={isModal}
          onClose={closed}
          detail={Intrest[indexValue]}
          month={currentMonth}
          year={currentYear}
          type="Submit"
        />
      )}
      {loader}
    </div>
  );
};
export default InterestRate;
