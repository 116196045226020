import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";
import User from "./UserPreview";

const ViewUser = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [userDetail, setUserDetails] = useState();

  const showSummary = (e) => {
    setUserDetails(e);
    setIsModal(true);
  };

  const closed = (status) => {
    setIsModal(false);
  };

  useEffect(() => {
    if (userData && userData.Org_Id) {
      loadStaffData();
    }
  }, [userData]);

  const loadStaffData = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getUsers?OrgId=${userData.Org_Id}`
      );
      setStaffData(result.data || []);
      setStaffDataa(result.data || []);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  const deleteUser = (EmpId, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(EmpId, name);
      }
    });
  };

  const handleDelete = async (EmpId, name) => {
    const obj={
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName:userData.UserName,
      Name: name
    }
    await axios.delete(`${apiUrl}/deleteUser/${EmpId}`,  { data: obj });
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    loadStaffData();
  };

  const columns = [
    {
      name: "Emp ID",
      selector: (row) => row.Employee_Id,
      sortable: true,
    },
    {
      name: "Joining Date",
      selector: (row) => formatDate(row.Joining_Date),
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => (
        <Link onClick={() => showSummary(row)}>{`${row.First_Name} ${row.Last_Name}`}</Link>
      ),      
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      width: "120px",
    },
    {
      name: "Email",
      cell: (row) => (
        <LightTooltip title={row.Email}>
          <span
            style={{
              // maxWidth: "90px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Email ? row.Email : "--"}
          </span>
        </LightTooltip>
      ),
    },
    { name: "Role", selector: (row) => row.Role, width: "100px" },
    {
      name: "Address",
      cell: (row) => (
        <LightTooltip title={row.Address}>
          <span
            style={{
              maxWidth: "70px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Address ? row.Address : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "IsActive",
      cell: (row) => (
        <a
          id="punchStatus"
          style={{ cursor: "pointer" }}
          className={`badge rounded-pill ${row.IsActive === "Active"
              ? "badge badge-success text-white"
              : row.IsActive === "Pending"
                ? "badge badge-warning text-white"
                : "bg-primary-subtle text-primary"
            } rounded-pill`}
        >
          {row.IsActive}
        </a>
      ),
      width: "90px"
    },
    {
      name: "",
      cell: (row) => (
        <div className="dropdown">
          <a
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i>
              <MoreVertIcon
                style={{
                  color: "grey",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </i>
          </a>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to={`/updateUser/edit/${row.EmpId}`}>
              <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "}
              Edit
            </Link>
            {userData.role === "Admin" && (
              <Link className="dropdown-item" onClick={() => deleteUser(row.EmpId, `${row.First_Name} ${row.Last_Name}`)}>
                <DeleteIcon className="text-danger" style={{ fontSize: "18px" }} />{" "}
                Delete
              </Link>)}
          </div>
        </div>
      ),
      width: "45px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const DownloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape'
    });
    doc.text("Employee Details", 20, 10);

    const columns = [
      "Emp ID",
      "Joining Date",
      "Name",
      "Mobile",
      "Email",
      "Role",
      "Address",
    ];

    const rows = data.map((row) => [
      row.Employee_Id,
      formatDate(row.Joining_Date),
      `${row.First_Name} ${row.Last_Name}`,
      row.Mobile,
      row.Email ? row.Email : "--",
      row.Role,
      row.Address ? row.Address : "--",
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Employee Details.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Emp ID",
      "Joining Date",
      "Name",
      "Mobile",
      "Email",
      "Role",
      "Address",
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row.Employee_Id,
        formatDate(row.Joining_Date),
        `${row.First_Name} ${row.Last_Name}`,
        row.Mobile,
        row.Email ? row.Email : "--",
        row.Role,
        row.Address ? row.Address : "--",
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Employee Details");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Employee Details.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = dataa.filter((row) => {
      return (
        (`${row.First_Name} ${row.Last_Name}` &&
          `${row.First_Name} ${row.Last_Name}`
            .toLowerCase()
            .includes(filterValue)) ||
        (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
        (row.Email && row.Email.toLowerCase().includes(filterValue)) ||
        (row.Role && row.Role.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue)) ||
        (row.Employee_Id &&
          row.Employee_Id.toLowerCase().includes(filterValue)) ||
        (formatDate(row.Joining_Date) &&
          formatDate(row.Joining_Date).toLowerCase().includes(filterValue))
      );
    });
    setStaffData(filteredData);
  }

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">View Employee</h5>
          </div>
          <div className="card">
            <div className="card-body">
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
        </div>
        {isModal && (
            <User
              open={isModal}
              onClose={(status) => closed(status)}
              userDetail={userDetail}
              opration={'ViewUser'}
            />
          )}
        {loader}
      </div>
    </div>
  );
};
export default ViewUser;
