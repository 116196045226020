import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { useUser } from "../../componets/UserContext";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import { apiUrl } from "../../componets/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import OTP from "./CloseAccountOTP";

const Payment_Mode = [
  { label: "Online", value: "Online" },
  { label: "TPT", value: "TPT" },
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "IMPS", value: "IMPS" },
  { label: "GPAY", value: "GPAY" },
  { label: "PhonePay", value: "PhonePay" },
  { label: "PAYTM", value: "PAYTM" },
  { label: "DD", value: "DD" },
  { label: "NEFT", value: "NEFT" },
  { label: "RTGS", value: "RTGS" },
];

function generateOTP() {
  const otp = Math.floor(1000 + Math.random() * 9000);
  return otp.toString();
}

const CloseCustomerAccount = () => {
  const [selectedPayMode, setSelectedPayMode] = useState([]);
  const [enrollment, setEnrollment] = useState("");
  const [isValidMobile, setIsValidMobile] = useState(true);
  const navigate = useNavigate();
  const { type, Sr_No } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [Bank, setBankOption] = useState([]);
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedBankName, setSelectedBankName] = useState([]);
  const [capitalAmount, setCapitalAmount] = useState(0);
  const [errors, setErrors] = useState({});
  const { userData } = useUser();
  const [plan, setPlanOption] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [panNo, setPanNo] = useState("");
  const [withdrawalAmountDate, setWithdrawalAmountDate] = useState();
  const [withdrawalProcessDays, setWithdrawalProcessDays] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const showSummarry = () => {
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (userData && userData.Org_Id) {
      LoadCategory();
    }
  }, [userData, selectedPlan.value, Sr_No]);

  const LoadCategory = async () => {
    showLoader();
    const customer = await axios.get(`${apiUrl}/getCustomerById/${Sr_No}`);
    const closeAccount = await axios.get(`${apiUrl}/getAccount/${Sr_No}`);
    setcloseAccount(customer.data);
    //console.log(customer.data);
    //setcloseAccount(closeAccount.data);
    setCapitalAmount(customer.data.Capital_Amount);
    setName(
      `${customer.data.First_Name} ${customer.data.Middle_Name} ${customer.data.Last_Name}`
    );
    setEmail(customer.data.Email);
    setMobile(customer.data.Mobile);
    setPanNo(customer.data.Pan_No);
    setSelectedBank({
      label: customer.data.Bank_Name,
      value: customer.data.Bank_Name,
    });
    // setSelectedBankName(
    //   customer.data.Request_Date
    //     ? { label: customer.data.Bank, value: customer.data.Bank }
    //     : []
    // );

    setSelectedPlan({
      label: customer.data.Investment_Type,
      value: customer.data.Investment_Type_Id,
    });
    if (closeAccount.data && closeAccount.data.Request_Date) {
      setcloseAccount(closeAccount.data);
      setCapitalAmount(closeAccount.data.Capital_Amount);
      setName(closeAccount.data.Request_By);
      setEmail(closeAccount.data.Request_By_Email);
      setMobile(closeAccount.data.Request_By_Mobile);
      setPanNo(closeAccount.data.Request_By_Pan_No);
      setSelectedBank({
        label: closeAccount.data.Withdrawal_Bank_Name,
        value: closeAccount.data.Withdrawal_Bank_Name,
      });
      setSelectedPayMode({
        label: closeAccount.data.Withdrawal_Mode,
        value: closeAccount.data.Withdrawal_Mode,
      });
      setSelectedBankName(
        closeAccount.data.Bank ?
          {
            label: closeAccount.data.Bank,
            value: closeAccount.data.Bank
          } : []
      );
    }

    const plan = await axios.get(`${apiUrl}/getPlans?OrgId=${userData.Org_Id}`);
    const res = await axios.get(`${apiUrl}/getBanks?OrgId=${userData.Org_Id}`);
    const bankOption = res.data.map((option) => ({
      label: option.Bank_Name,
      value: option.Bank_Name,
    }));
    setBankOption(bankOption);
    const planOption = plan.data.map((option) => ({
      label: option.Plan_Name,
      value: option.Plan_Id,
    }));
    setPlanOption(planOption);
    hideLoader();
  };

  useEffect(() => {
    if (selectedPlan && selectedPlan.value) {
      (async () => {
        showLoader();
        const cap_Amount = await axios.get(
          `${apiUrl}/getPlanss/${selectedPlan.value}`
        );
        //setCapitalAmount(cap_Amount.data.Capital_Amount);
        setWithdrawalProcessDays(cap_Amount.data.Capital_Return_Days);
        hideLoader();
      })();
    }
  }, [selectedPlan.value]);

  const [closeAccount, setcloseAccount] = useState({
    Request_Date: "",
    Enrollment_No: "",
    Plan_Id: "",
    Plan_Name: "",
    Request_By: "",
    Request_By_Email: "",
    Request_By_Mobile: "",
    Request_By_Pan_No: "",
    Withdrawal_Mode: "",
    Bank: "",
    Branch_Name: "",
    Cheque_No: "",
    Cheque_Date: "",
    Reference_No: "",
    Withdrawal_Bank_Name: "",
    IFCE_Code: "",
    Account_No: "",
    Account_Holder_Name: "",
    Withdrawal_Process_Days: "",
    Withdrawal_Amount_Date: "",
    Remarks: "",
    Updated_By_Id: "",
    Updated_By: "",
    Org_Id: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    // debugger;
    const updateCloseAccount = {
      ...closeAccount,

      Plan_Id: selectedPlan.value,
      Plan_Name: selectedPlan.label,
      Request_By: name,
      Request_By_Email: email,
      Request_By_Mobile: mobile,
      Request_By_Pan_No: panNo,
      Withdrawal_Amount_Date: formatInputDate(withdrawalAmountDate),
      Enrollment_No: closeAccount.Enrollment_No,
      Capital_Amount: capitalAmount,
      Withdrawal_Bank_Name: selectedBank.value,
      Bank: selectedBankName.value,
      Withdrawal_Mode: selectedPayMode.value,
      Withdrawal_Process_Days: withdrawalProcessDays,
      Updated_By_Employee_Id: userData.Employee_Id,
      Updated_By_Id: userData.userId,
      Updated_By: `${userData.firstName} ${userData.lastName}`,
      
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName:userData.UserName,
    };

    const validationErrors = {};

    if (Object.keys(validationErrors).length === 0) {
      showLoader();

      axios
        .put(`${apiUrl}/updateAccount/${Sr_No}`, updateCloseAccount)
        .then((res) => {
          Swal.fire("Success!", "closedAccount has been Updated.", "success");
          //navigate("/viewCustomer");
          LoadCategory();
          handleCancelEdit();
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error",
            text: "Failed to Update. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const calculateTotalDays = () => {
    const requestDate = new Date(closeAccount.Request_Date);
    const ProcessDays = parseInt(withdrawalProcessDays);

    requestDate.setDate(requestDate.getDate() + ProcessDays);

    const year = requestDate.getFullYear();
    const month = String(requestDate.getMonth() + 1).padStart(2, "0");
    const day = String(requestDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    setWithdrawalAmountDate(formattedDate);
  };

  useEffect(() => {
    calculateTotalDays();
  }, [closeAccount.Request_Date, withdrawalProcessDays]);

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const insertOTP = async (event) => {
    event.preventDefault();

    const newOTP = generateOTP();
    const otp = {
      Employee: `${userData.firstName} ${userData.lastName}`,
      OTP: newOTP,
      Customer: name,
      OrgId: userData.Org_Id,
      Email: userData.email,
      number: userData.Mobile
    };

    showLoader();

    axios
      .put(`${apiUrl}/insertCloseAcountOpt/${Sr_No}`, otp)
      .then((res) => {
        //Swal.fire("Success!", "OTP has been Generated.", "success");
        //navigate("/viewCustomer");
        //LoadCategory();

        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Generate OTP. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        handleCancelEdit();
        hideLoader();
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Close Customer Account</h5>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Enrollment_No" className="form-label">
                        Enrollment No<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Enrollment_No"
                        className="form-control "
                        required
                        disabled
                        value={closeAccount.Enrollment_No}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Request_Date" className="form-label">
                        Request Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        id="Request_Date"
                        className="form-control "
                        dateFormat="dd-MM-yyyy"
                        required
                        disabled={!isEditing}
                        value={formatInputDate(closeAccount.Request_Date)}
                        onChange={(e) =>
                          setcloseAccount({
                            ...closeAccount,
                            Request_Date: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="selectedPlan" className="form-label">
                        Investment Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        type="text"
                        name="selectedPlan"
                        id="selectedPlan"
                        options={plan}
                        isDisabled
                        value={selectedPlan}
                        onChange={setSelectedPlan}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtLname" className="form-label">
                        Capital Amount<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtLname"
                        className="form-control "
                        placeholder="Enter Capital Amount"
                        required
                        disabled={!isEditing}
                        value={capitalAmount}
                        onChange={(e) => setCapitalAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Request_By" className="form-label">
                        Request By Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Request_By"
                        className="form-control "
                        placeholder="Enter Request By Name"
                        required
                        disabled={!isEditing}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Request_By_Email" className="form-label">
                        Request By Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        id="Request_By_Email"
                        className="form-control "
                        placeholder="Enter Email"
                        required
                        disabled={!isEditing}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Request_By_Mobile" className="form-label">
                        Request By Mobile<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={mobile}
                        id="Request_By_Mobile"
                        name="Request_By_Mobile"
                        className={`form-control ${isValidMobile ? "" : "is-invalid"
                          }`}
                        placeholder="Enter Mobile Number"
                        required
                        disabled={!isEditing}
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          const isValid = /^[6-9][0-9]{0,9}$/.test(
                            numericInput
                          );
                          setIsValidMobile(isValid);
                          if (isValid) {
                            setMobile(numericInput);
                          }
                        }}
                      />
                      {!isValidMobile && (
                        <div className="invalid-feedback">
                          Invalid mobile number.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Request_By_Pan_No" className="form-label">
                        Request By Pan No<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Request_By_Pan_No"
                        className="form-control "
                        placeholder="Enter Pan No"
                        required
                        disabled={!isEditing}
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Withdrawal_Mode" className="form-label">
                        Withdrawal Mode
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                      isDisabled={!isEditing}
                        options={Payment_Mode}
                        value={selectedPayMode}
                        onChange={setSelectedPayMode}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {["Cheque", "DD"].includes(selectedPayMode.value) && (
                    <>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="Bank" className="form-label">
                            Bank<span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            //type="text"
                            name="Bank"
                            id="Bank"
                            options={Bank}
                            placeholder="Bank Name"
                            isDisabled={!isEditing}
                            value={selectedBankName}
                            onChange={setSelectedBankName}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="Branch_Name" className="form-label">
                          IFSC/Branch Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="Branch_Name"
                            name="Branch_Name"
                            disabled={!isEditing}
                            value={
                              closeAccount.Request_Date
                                ? closeAccount.Branch_Name
                                : ""
                            }
                            placeholder="Enter IFSC/Branch Name"
                            required={true}
                            onChange={(e) =>
                              setcloseAccount({
                                ...closeAccount,
                                Branch_Name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="Cheque_No" className="form-label">
                            Cheque No <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="Cheque_No"
                            name="Cheque_No"
                            disabled={!isEditing}
                            placeholder="Enter Cheque No"
                            value={
                              closeAccount.Request_Date
                                ? closeAccount.Cheque_No
                                : ""
                            }
                            required={true}
                            onChange={(e) => {
                              const input = e.target.value;
                              const numericInput = input.replace(/[^0-9]/g, "");
                              setcloseAccount({
                                ...closeAccount,
                                Cheque_No: numericInput,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="Cheque_Date" className="form-label">
                            Cheque Date <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            id="Cheque_Date"
                            name="Cheque_Date"
                            required={true}
                            dateFormat="dd-MM-yyyy"
                            value={
                              closeAccount.Request_Date
                                ? closeAccount.Cheque_Date
                                : ""
                            }
                            disabled={!isEditing}
                            onChange={(e) =>
                              setcloseAccount({
                                ...closeAccount,
                                Cheque_Date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  {[
                    "TPT",
                    "IMPS",
                    "GPAY",
                    "Online",
                    "PhonePay",
                    "PAYTM",
                    "NEFT",
                    "RTGS",
                  ].includes(selectedPayMode.value) && (
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="Reference_No" className="form-label">
                            Transaction ID <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            disabled={!isEditing}
                            id="Reference_No"
                            name="Reference_No"
                            required={true}
                            placeholder="Enter Transaction ID"
                            value={
                              closeAccount.Request_Date
                                ? closeAccount.Reference_No
                                : ""
                            }
                            onChange={(e) =>
                              setcloseAccount({
                                ...closeAccount,
                                Reference_No: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label
                        htmlFor="Withdrawal_Bank_Name"
                        className="form-label"
                      >
                        Bank Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        type="text"
                        name="Withdrawal_Bank_Name"
                        id="Withdrawal_Bank_Name"
                        placeholder="Select Bank Name"
                        options={Bank}
                        isDisabled={!isEditing}
                        value={selectedBank}
                        onChange={setSelectedBank}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="IFCE_Code" className="form-label">
                        IFSC Code<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="IFCE_Code"
                        name="IFCE_Code"
                        className="form-control "
                        placeholder="Enter IFSC Code"
                        required
                        disabled={!isEditing}
                        value={closeAccount.IFCE_Code}
                        onChange={(e) =>
                          setcloseAccount({
                            ...closeAccount,
                            IFCE_Code: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Account_No" className="form-label">
                        Account No<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Account_No"
                        name="Account_No"
                        className="form-control "
                        placeholder="Enter Last Name"
                        required
                        disabled={!isEditing}
                        value={closeAccount.Account_No}
                        onChange={(e) =>
                          setcloseAccount({
                            ...closeAccount,
                            Account_No: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label
                        htmlFor="Account_Holder_Name"
                        className="form-label"
                      >
                        Account Holder Name
                      </label>
                      <input
                        type="text"
                        id="Account_Holder_Name"
                        name="Account_Holder_Name"
                        value={closeAccount.Account_Holder_Name}
                        className="form-control "
                        disabled={!isEditing}
                        placeholder="Enter Account Holder Name "
                        onChange={(e) =>
                          setcloseAccount({
                            ...closeAccount,
                            Account_Holder_Name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="Withdrawal_Process_Days"
                        className="form-label"
                      >
                        Withdrawal Process Days
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Withdrawal_Process_Days"
                        className="form-control "
                        placeholder="Enter Withdrawal Process Days"
                        required
                        disabled={!isEditing}
                        value={withdrawalProcessDays}
                        onChange={(e) =>
                          setWithdrawalProcessDays(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="Withdrawal_Amount_Date"
                        className="form-label"
                      >
                        Withdrawal Amount Date
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        id="Withdrawal_Amount_Date"
                        className="form-control "
                        dateFormat="dd-MM-yyyy"
                        //placeholder="Enter Mobile Number"
                        required
                        disabled={!isEditing}
                        value={formatInputDate(withdrawalAmountDate)}
                        onChange={(e) =>
                          setcloseAccount({
                            ...closeAccount,
                            Withdrawal_Amount_Date: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Remarks
                      </label>
                      <textarea
                        type="text"
                        id="exampleFormControlTextarea1"
                        className="form-control"
                        placeholder="Enter Remarks"
                        rows="2"
                        disabled={!isEditing}
                        value={closeAccount.Remarks}
                        onChange={(e) =>
                          setcloseAccount({
                            ...closeAccount,
                            Remarks: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 text-center">
                    {!isEditing && (
                      <button
                        className="btn btn-primary mt-2"
                        onClick={handleEditClick}
                      >
                        Edit
                      </button>
                    )}{" "}
                    &nbsp;
                    {!isEditing && (
                      <button
                        className="btn btn-success mt-2"
                        style={{ display: type === "edit" ? "none" : "" }}
                        onClick={(e) => {
                          e.preventDefault();
                          insertOTP(e);
                          showSummarry();
                        }}
                      >
                        Verify
                      </button>
                    )}
                    {isEditing && (
                      <button
                        className="btn btn-primary mt-2"
                        onClick={handleCancelEdit}
                      >
                        Close
                      </button>
                    )}
                    &nbsp;
                    {isEditing && (
                      <button className="btn btn-primary mt-2" type="submit">
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isModal && (
        <OTP
          open={isModal}
          Sr_No={Sr_No}
          onClose={closed}
          role={userData?.role}
          name={name}
        />
      )}
      {loader}
    </div>
  );
};

export default CloseCustomerAccount;
