// import React, { createContext, useContext, useState } from 'react';

// const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const [userData, setData] = useState(null);

//   return (
//     <UserContext.Provider value={{ userData, setData }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// export const useUser = () => {
//   return useContext(UserContext);
// };


import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setData] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to handle login
  const handleLogin = (data) => {
    // Assuming the login is successful, set userData and isAuthenticated to true
    setData(data);
    setIsAuthenticated(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    // Clear user data and set isAuthenticated to false on logout
    setData(null);
    setIsAuthenticated(false);
  };

  return (
    <UserContext.Provider value={{ userData, isAuthenticated, handleLogin, handleLogout , setData}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
