export function formatDate(dateString) {
    if (!dateString) return '';

    try {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString("en-GB", options);
        return formattedDate !== "Invalid Date" ? formattedDate : "Invalid Date";
    } catch (error) {
        console.error("Error formatting date:", error);
        return "Invalid Date";
    }
}

export function formatTime(timeString) {
    try {
        if (!timeString) return '';

        const [time, meridiem] = timeString.split(' ');

        let [hours, minutes] = time.split(':');
        if (meridiem === 'PM' && hours !== '12') {
            hours = (parseInt(hours, 10) + 12).toString().padStart(2, '0'); // Convert to 24-hour format
        } else if (hours === '12' && meridiem === 'AM') {
            hours = '00'; // Handle midnight
        } else {
            hours = hours.padStart(2, '0'); // Ensure two-digit format
        }

        return `${hours}:${minutes}`;
    } catch (error) {
        console.error("Error formatting time:", error);
        return "Invalid Time";
    }
}

export function formatISOTimeTo12Hour(isoString) {
    try {
        if (!isoString) return '';

        const date = new Date(isoString);
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'

        return `${hours}:${minutes} ${period}`;
    } catch (error) {
        console.error("Error formatting time:", error);
        return "Invalid Time";
    }
}

export function formatISOTime(isoString) {
    try {
        if (!isoString) return '';

        const date = new Date(isoString);
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');

        return `${hours}:${minutes}`;
    } catch (error) {
        console.error("Error formatting time:", error);
        return "Invalid Time";
    }
}

export function formatInputDate(dateString) {
    if (!dateString) {
        return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}


export function formatDateTime(dateTimeString) {
    try {
        if (!dateTimeString) return '';

        // Check if dateTimeString is in ISO 8601 format
        if (dateTimeString.includes('T')) {
            const date = new Date(dateTimeString);
            const day = ('0' + date.getDate()).slice(-2);
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear();
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const seconds = ('0' + date.getSeconds()).slice(-2);

            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        }

        const [dateString, timeString] = dateTimeString.split(' ');

        // Format Date
        const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString("en-GB", dateOptions);
        const validDate = formattedDate !== "Invalid Date" ? formattedDate : "Invalid Date";

        // Format Time
        const [hours, minutes] = timeString.split(':');
        const formattedTime = `${hours}:${minutes}`;

        // Return combined formatted date and time
        return `${validDate} ${formattedTime}`;
    } catch (error) {
        console.error("Error formatting date and time:", error);
        return "Invalid Date Time";
    }
}
export function convertDateTimeFormat(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function convertTimeFormat(dateTimeString) {
    const date = new Date(dateTimeString);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0') + '0000'; // Adding trailing zeros

    // Format the time string
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

