import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import axios from "axios";
import "jspdf-autotable";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import "../../CSS/dataTables.bootstrap4.min.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import "datatables.net";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import dayjs from "dayjs";
import CapitalPayment from "./CapitalPayment";
import { useParams } from "react-router-dom";


const CloseAccountWithdrawal = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [typeData, setTypeData] = useState([]);
  const [indexValue, setIndex] = useState();
  const [isModal, setIsModal] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [TotalInterestAmount, setTotalInterestAmount] = useState(0);
  const [oldData, setlanData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [data, setData] = useState([]);
  const { date } = useParams();
  const [filter, setFilter] = useState({
    PlanId: "-1",
    Type_Id: "-1",
    Customer_Id: "-1",
    fromDate: dayjs(date).startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs(date).endOf("month").format("YYYY-MM-DD"),
  });
  const planLoad = async () => {
    showLoader();
    const staffResult = await axios.get(
      `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
    );
    setPlanData(staffResult.data);
    hideLoader();
  }
  const TypeLoad = async () => {
    showLoader();
    const TypeResult = await axios.get(
      `${apiUrl}/getPercentageTypes?OrgId=${userData.Org_Id}`
    );
    setTypeData(TypeResult.data);
    hideLoader();
  };

  const customerLoad = async () => {
    showLoader();
    const customerResult = await axios.get(`${apiUrl}/getCustomerByPlanId?OrgId=${userData.Org_Id}&PlanId=${filter.PlanId}`);
    setCustomerData(customerResult.data);
    hideLoader();
  }

  const dataLoad = async () => {
    showLoader();
    const Result = await axios.get(
      `${apiUrl}/getAccountCloseMaster?OrgId=${userData.Org_Id}&PlanId=${filter.PlanId}&Customer_Id=${filter.Customer_Id}&FromDate=${filter.fromDate}&ToDate=${filter.toDate}`
    );
    // console.log(Result.data);
    // console.log(filter.PlanId);
    setData(Result.data);
    setlanData(Result.data);
    hideLoader();
  };
  useEffect(() => {
    if (userData && userData.Org_Id) {
      planLoad();
      TypeLoad();
      dataLoad();
      customerLoad();
    }
  }, [userData, filter.PlanId]);

  const filterData = () => {
    dataLoad();
  };

  useEffect(() => {
    if (data) {
      showLoader();
      const totalCapitalAmount = data.reduce((total, item) => total + parseFloat(item.Capital_Amount), 0);
      setTotalInterestAmount(totalCapitalAmount);
      hideLoader();
    }
  }, [data]);

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Withdrawal Payable Capital Amount", 20, 10);

    const columns = [
      "Enrollment No",
      "Name",
      "Start Date",
      "Colse Date",
      "Withdrawal Date",
      "Capital Amount",
    ];

    const rows = data.map((row) => [
      row.Enrollment_No,
      row.Request_By,
      dayjs(row.Start_Date).format("DD/MM/YYYY"),
      dayjs(row.Request_Date).format("DD/MM/YYYY"),
      dayjs(row.Withdrawal_Amount_Date).format("DD/MM/YYYY"),
      row.Capital_Amount.toFixed(2),
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Withdrawal_Payable_Capital_Amount.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Enrollment No",
      "Name",
      "Start Date",
      "Colse Date",
      "Withdrawal Date",
      "Capital Amount",
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row.Enrollment_No,
        row.Request_By,
        dayjs(row.Start_Date).format("DD/MM/YYYY"),
        dayjs(row.Request_Date).format("DD/MM/YYYY"),
        dayjs(row.Withdrawal_Amount_Date).format("DD/MM/YYYY"),
        row.Capital_Amount.toFixed(2),
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Withdrawal Payable Capital Amount");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Withdrawal_Payable_Capital_Amount.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = oldData.filter((row) => {
      return (
        (row.Enrollment_No &&
          row.Enrollment_No.toLowerCase().includes(filterValue)) ||
        (row.Request_By &&
          row.Request_By.toLowerCase().includes(filterValue)) ||
        row.Capital_Amount.toString().includes(filterValue) ||
        dayjs(row.Start_Date).format("DD/MM/YYYY").includes(filterValue) ||
        dayjs(row.Request_Date).format("DD/MM/YYYY").includes(filterValue) ||
        dayjs(row.Withdrawal_Amount_Date).format("DD/MM/YYYY").includes(filterValue)
      );
    });
    setData(filteredData);
  }



  const showSummarry = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    dataLoad();
  };

  return (
    <div className="container-fluid">
      <div>
        <div className="row ">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
              <h5 className="page-title">Withdrawal Payable Capital Amount</h5>
            </div>
            <div className="card">
              <h5 className="page-title"></h5>
              <div className="card-body" style={{ paddingTop: "5px" }}>
                <div className="row justify-content-center mt-2">
                  <div className="col-3" >
                    <label htmlFor="example-select" className="form-label">
                      Plan
                    </label>
                    <select
                      className="form-control"
                      id="example-select"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          PlanId: e.target.value,
                        });
                      }}
                    >
                      <option value={-1}>All</option>
                      {planData.map((item, index) => (
                        <option
                          key={index}
                          value={item.Plan_Id}
                          label={item.Plan_Name}
                        ></option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      Customer
                    </label>
                    <Select
                      id="example-select"
                      options={[
                        { value: -1, label: 'All' },
                        ...customerData.map((item, index) => ({
                          value: item.Sr_No,
                          label: `${item.First_Name} ${item.Last_Name}`
                        }))
                      ]}
                      onChange={(selectedOption) => {
                        setFilter({
                          ...filter,
                          Customer_Id: selectedOption.value
                        });
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      From Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={filter.fromDate}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          fromDate: e.target.value,
                        });
                      }}
                    ></input>
                  </div>
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      To Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={filter.toDate}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          toDate: e.target.value,
                        });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={filterData}
                      style={{ width: "100%" }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />
                  <Tooltip title="Export to PDF">
                    <i
                      className="mt-2"
                      style={{ cursor: "pointer", padding: "5px" }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      {" "}
                      <PictureAsPdfIcon />{" "}
                    </i>
                  </Tooltip>
                  <Tooltip title="Export to Excel">
                    <i
                      className="mt-2"
                      style={{ padding: "5px", cursor: "pointer" }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
                <div className=" table-responsive">
                  <table
                    id="dataTableHover"
                    className="table align-items-center table-flush table-hover paidInterest"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Sr. No.</th>
                        <th className="text-center">Enroll. No</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Close Date</th>
                        <th className="text-center">Withdrawal Date</th>
                        <th className="text-center">Capital Amount</th>
                        <th className="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length !== 0 ? (
                        data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center" style={{ width: "" }}>
                                {index + 1}
                              </td>
                              <td className="text-center" style={{ width: "" }}>
                                {item.Enrollment_No}
                              </td>
                              <td className="text-center" style={{ width: "" }}>
                                {item.Request_By}
                              </td>

                              <td className="text-center" style={{ width: "" }}>
                                {dayjs(item.Request_Date).format("DD/MM/YYYY")}
                              </td>

                              <td className="text-center" style={{ width: "" }}>
                                {dayjs(item.Withdrawal_Amount_Date).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td className="text-center" style={{ width: "" }}>
                                {parseFloat(item.Capital_Amount).toFixed(
                                  2
                                )}
                              </td>
                              <td className="text-center" style={{ padding: "10px" }}>

                                <button
                                  style={{ cursor: "pointer" }}
                                  className="btn btn-sm btn-success"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    showSummarry(index);
                                  }}
                                >
                                  Pay
                                </button>
                                {/* ) : (
                          <Link className="btn btn-sm"
                            onClick={() => {
                              updateData(item.InterestId);
                            }}>
                            <EditIcon className="text-white" style={{ fontSize: "18px" }} />
                          </Link>
                        )} */}

                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center text-danger" colSpan={7}>
                            No Data Found !!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={5} className="text-right" style={{ padding: "10px" }}>
                          <strong aligne="right">
                            Total Capital Amount:
                          </strong>
                        </td>
                        <td className="text-center" style={{ padding: "10px 100px 10px 35px !importent" }}>
                          <strong>
                            {parseFloat(TotalInterestAmount).toFixed(2)}
                          </strong>
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log(data[indexValue])}
      {isModal && (
        <CapitalPayment
          open={isModal}
          onClose={closed}
          detail={data[indexValue]}
          type="Submit"
        />
      )}
      {loader}
    </div>
  );
};

export default CloseAccountWithdrawal;
