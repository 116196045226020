import React, { useState, useEffect } from "react";
import "./CustomerPreview.css";
import { Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { apiUrl } from "../../componets/apiConfig";
import { useUser } from "../../componets/UserContext";
import { useNavigate, useParams, Link } from "react-router-dom";
import InterestPayment from "../Reports/InterestPayment";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";

const SlabInterest = () => {
  const { userData } = useUser();
  const [indexValue, setIndex] = useState();
  const [isModal, setIsModal] = useState(false);
  const [isModall, setIsModall] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const [data, setData] = useState([]);
  const [Interest, setInterestData] = useState([]);
  const [InterestAmount, setInterestAmount] = useState(0);


  useEffect(() => {
    if (Sr_No) {
      (async () => {
        showLoader();
        const res = await axios.get(`${apiUrl}/getCustomerById/${Sr_No}`);
        setData(res.data);
        hideLoader();
      })();
    }
  }, [Sr_No]);

  const exampleData = [
    {
      Sr_No: 25,
      Enrollment_No: 'MIP00015',
      Start_Date: '2023-08-21T00:00:00.000Z',
      Investment_Type_Id: 2,
      Investment_Type: 'Monthly Investment Plan',
      Capital_Amount: 150000,
      Rate_Of_Int: 3,
      Mode_Of_Investment: 'Online',
      Bank: null,
      Branch_Name: null,
      Cheque_No: null,
      Cheque_Date: null,
      Reference_No: '2266165654165',
      Payment_Doc: null,
      Investment_Date: '2023-08-18T00:00:00.000Z',
      Percentage_Type_Id: 2,
      Percentage_Type: 'Fixed',
      Investment_Period: '1 Year',
      Investment_Period_Value: 12,
      Remarks: '',
      First_Name: 'Lokesh',
      Middle_Name: 'S',
      Last_Name: 'Prajapati',
      DOB: '2024-01-16T00:00:00.000Z',
      Gender: 'Male',
      Marital_Status: 'Unmarried',
      Mobile: '9022398228',
      Email: 'lokesh@gmail.com',
      Pan_No: 'GDFG258741',
      Aadhar_No: '123456789012',
      Reference_Type: 'Direct',
      Reference: '',
      Student: 'Yes',
      Address: '',
      Bank_Name: 'IDFC BANK',
      IFCE_Code: 'SBI0000563',
      Account_No: '3260004522',
      Account_Holder_Name: 'Self',
      Co_applicant: 'false',
      Co_applicant_Photo: 'null',
      Co_applicant_Full_Name: '',
      Co_applicant_DOB: '',
      Co_applicant_Birth_Proof: 'null',
      Co_applicant_Occupation: '',
      Co_applicant_Relationship: '',
      Co_applicant_Address: '',
      Photo: 'Photo_1705406719558.png',
      Id_Proof_Type: 'Driving Licence',
      Id_Proof_Attachment: 'Id_Proof_Attachment_1705406719693.pdf',
      Address_Proof_Type: 'Ration Card',
      Address_Proof_Attachment: 'Address_Proof_Attachment_1705406719693.pdf',
      Nominee_Full_Name: '',
      Nominee_DOB: '',
      Nominee_Birth_Proof: 'null',
      Nominee_Photo: 'null',
      Nominee_Sign_Attachment: 'null',
      OTP: 1234,
      Verified_By_Acc: 'NO',
      Verified_By_Super: 'NO',
      Verified_By_Admin: 'NO',
      IsActive: 'Pending',
      Added_By_Employee_Id: 'EMP001',
      Added_By_Id: 1,
      Added_By: 'Expert Trading',
      Added_On: '2024-01-16T17:34:36.137Z',
      Org_Id: 1,
      Plan_Name: 'Monthly Investment Plan',
      Payout_Int_Date: '2023-12-07T00:00:00.000Z',
      Last_Payable_Int_days: 1,
      Capital_Return_Days: 30,
      Description: '',
      Request_Date: '2024-08-21T00:00:00.000Z'
    },
  ];

  const calculateMIP = (
    startDate,
    endDate,
    principalAmount,
    monthlyInterestRate,
    monthDayscount
  ) => {
    const countDays = calculateDaysDifference(startDate, endDate);
    const updatedDays = countDays + 1;
    const InterestAmount = (principalAmount * monthlyInterestRate) / 100;
    const monthlyPayout = (InterestAmount * updatedDays) / monthDayscount;

    return monthlyPayout;
  };

  const calculateRegularMIP = (principalAmount, monthlyInterestRate) => {
    const monthlyPayout = (principalAmount * monthlyInterestRate) / 100;

    return monthlyPayout;
  };

  const calculateCloseMIP = (
    startDate,
    endDate,
    principalAmount,
    monthlyInterestRate,
    monthDayscount,
    LastPayableIntdays
  ) => {
    const countDays = calculateDaysDifference(
      new Date(startDate),
      new Date(endDate)
    );
    const updatedDays = countDays + 1 - LastPayableIntdays;
    const InterestAmount = (principalAmount * monthlyInterestRate) / 100;
    const monthlyPayout = (InterestAmount * updatedDays) / monthDayscount;

    return monthlyPayout;
  };

  const generateDateRangeArray = (data, loadInterest) => {
    //console.log("Data:", data);
    //console.log("LoadInterest:", loadInterest);
    
    const dateRangeArray = [];
    let startDate;
    let endDate = null;
    let monthDaysCount = 0;

    for (let i = 0; i < data.length; i++) {
      startDate = dayjs(data[i].Start_Date);
      endDate = data[i].Request_Date
        ? dayjs(data[i].Request_Date).subtract(
          parseInt(`${data[i].Last_Payable_Int_days}`),
          "day"
        )
        : dayjs().endOf("month");
      const capitalAmount = data[i].Capital_Amount;
      const monthlyInterestRate = data[i].Rate_Of_Int;
      monthDaysCount = dayjs(data[i].Start_Date).daysInMonth();
      let a = dayjs(startDate).startOf("month").format();
debugger;
      const initialObject = {
        Customer_Id: data[i].Sr_No,
        Enrollment_No: data[i].Enrollment_No,
        Start_Date: data[i].Start_Date,
        startDate: startDate.format(),
        enddate: startDate.clone().endOf('month').format('YYYY-MM-DD'),
        Month: new Date(startDate).getMonth() + 1,
        Year: new Date(startDate).getFullYear(),
        capitalamount: capitalAmount,
        Rate_Of_Int: data[i].Rate_Of_Int,
        InterestAmount: calculateMIP( // Change interestamount to InterestAmount
          startDate,
          startDate.clone().endOf('month').format('YYYY-MM-DD'),
          capitalAmount,
          monthlyInterestRate,
          monthDaysCount
        ),
        First_Name: data[i].First_Name,
        Middle_Name: data[i].Middle_Name,
        Last_Name: data[i].Last_Name,
        Mobile: data[i].Mobile,
      };
console.log(initialObject.startDate);
console.log(initialObject.enddate);
console.log(data[i].Rate_Of_Int);
console.log(capitalAmount);
console.log(initialObject.InterestAmount);
console.log(dayjs(data[i].Start_Date).daysInMonth());
      // Check if the  matches any entry in loadInterest
      const matchingInterest = loadInterest.find(
        (interest) =>
        parseInt(interest.Month) === dayjs(initialObject.startDate).month() + 1 && parseInt(interest.Year) === dayjs(initialObject.startDate).year() && interest.Customer_Id === initialObject.Customer_Id && 
        interest.Amount === parseFloat(initialObject.InterestAmount.toFixed(2))
      );

      // Set the status based on matchingInterest
      initialObject.status = matchingInterest ? "Paid" : "Unpaid";
      initialObject.InterestId = matchingInterest ? matchingInterest.Sr_No : null;

      
      dateRangeArray.push(initialObject);
     
      // Create objects for each subsequent month until endDate
      let currentMonth = startDate.add(1, "month");
      while (currentMonth.isBefore(endDate, "day")) {
        const interestObj = {
          Customer_Id: data[i].Sr_No,
          Enrollment_No: data[i].Enrollment_No,
          Start_Date: data[i].Start_Date,
          startDate: currentMonth.startOf("month").format(),
          enddate: currentMonth.endOf("month").format(),
          Month: new Date(startDate).getMonth() + 1,
          Year: new Date(startDate).getFullYear(),
          capitalamount: capitalAmount,
          Rate_Of_Int: data[i].Rate_Of_Int,
          InterestAmount: calculateRegularMIP( // Change interestamount to InterestAmount
            capitalAmount,
            monthlyInterestRate
          ),
          First_Name: data[i].First_Name,
          Middle_Name: data[i].Middle_Name,
          Last_Name: data[i].Last_Name,
          Mobile: data[i].Mobile,
        };
        let month = dayjs(interestObj.startDate).month() + 1;
        let year = dayjs(interestObj.startDate).year();
        let endMonth = dayjs(endDate).month() + 1;
        let endYear = dayjs(endDate).year();
        if (month === endMonth && year === endYear) {
          currentMonth = currentMonth.add(1, "month");
          continue;
        }
        // console.log(currentMonth.startOf("month").format())
        // Check if the currentMonth matches any entry in loadInterest
      
        const matchingInterest = loadInterest.find(
          (interest) => 
            parseInt(interest.Month) === month && parseInt(interest.Year) === year && interest.Customer_Id === interestObj.Customer_Id && 
            interest.Amount === parseFloat(interestObj.InterestAmount.toFixed(2))
        );
        console.log(loadInterest);
        
        // Set the status based on matchingInterest
        interestObj.status = matchingInterest ? "Paid" : "Unpaid";
        interestObj.InterestId = matchingInterest ? matchingInterest.Sr_No : null;
        
          dateRangeArray.push(interestObj);

        currentMonth = currentMonth.add(1, "month");
      }

      // Add an additional object if Request_Date is not null
      if (data.length > 0 && data[i].Request_Date) {
        const lastItem = data[i];
        const lastMonthStartDate = dayjs(lastItem.Request_Date).startOf("month");
        const lastMonthEndDate = dayjs(lastItem.Request_Date);

        const lastMonthDaysCount = dayjs(lastItem.Request_Date).daysInMonth();

        const lastMonthInterest = calculateCloseMIP(
          lastMonthStartDate,
          lastMonthEndDate,
          lastItem.Capital_Amount,
          lastItem.Rate_Of_Int,
          lastMonthDaysCount,
          data[i].Last_Payable_Int_days
        );

        const lastMonthObj = {
          Customer_Id: lastItem.Sr_No,
          Enrollment_No: lastItem.Enrollment_No,
          Start_Date: data[i].Start_Date,
          startDate: lastMonthStartDate.format(), 
          enddate: lastMonthEndDate.format(),
          Month: new Date(startDate).getMonth() + 1,
          Year: new Date(startDate).getFullYear(),
          capitalamount: lastItem.Capital_Amount,
          Rate_Of_Int: lastItem.Rate_Of_Int,
          InterestAmount: lastMonthInterest, // Change interestamount to InterestAmount
          First_Name: lastItem.First_Name,
          Middle_Name: lastItem.Middle_Name,
          Last_Name: lastItem.Last_Name,
          Mobile: lastItem.Mobile,
        };
        //console.log(lastMonthStartDate)
        // Check if the lastMonthObj matches any entry in loadInterest
        const matchingInterest = loadInterest.find(
          (interest) =>
          parseInt(interest.Month) === dayjs(lastMonthObj.startDate).month() + 1 && parseInt(interest.Year) === dayjs(lastMonthObj.startDate).year() && interest.Customer_Id === lastMonthObj.Customer_Id && 
          interest.Amount === parseFloat(lastMonthObj.InterestAmount.toFixed(2)) );
       
        // Set the status based on matchingInterest
        lastMonthObj.status = matchingInterest ? "Paid" : "Unpaid";
        lastMonthObj.InterestId = matchingInterest ? matchingInterest.Sr_No : null;

        dateRangeArray.push(lastMonthObj);
        // console.log("DateRangeArray:", dateRangeArray);
      }
    }
    return dateRangeArray;
  };

  useEffect(() => {
    if (Sr_No && userData && userData.Org_Id) {
      Load();
    }

  }, [Sr_No, userData]);

  const Load = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getSlabedInterestLog?OrgId=${userData.Org_Id}&CustomerId=${Sr_No}`
      );
      const res = await axios.get(
        `${apiUrl}/getInterestPaymentLog?OrgId=${userData.Org_Id}`
      );
      let interestArray = generateDateRangeArray(result.data, res.data);
      console.log(interestArray)
      interestArray = interestArray.sort((a, b) =>
        dayjs(b.startDate).diff(dayjs(a.startDate))
      );
      const totalInterest = interestArray.reduce(
        (sum, item) => sum + item.InterestAmount,
        0
      );
      setInterestAmount(totalInterest);
      setInterestData(interestArray);

    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch Data. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      hideLoader();
    }
  };

  const calculateDaysDifference = (startDate, endDate) => {
    const start = dayjs(startDate).startOf("day");
    const end = dayjs(endDate).startOf("day");
    const daysDifference = end.diff(start, "day");

    return daysDifference;
  };


  const showSummarry = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    Load();
  };


  const updateData = async (id) => {
    if (id) {
      showLoader();
      const res = await axios.get(`${apiUrl}/getInterestPaymentLogById/${id}`);
      setData(res.data);
      hideLoader();
    }

    setIsModall(true);
  }

  const onClosed = () => {
    setIsModall(false);
    Load();
  };

  return (
    <div className="card">
      <div className="card-body bio-graph-info">
        <div className="col-12 mt-2">
          <div className=" table-responsive">
            <table
              id="dataTableHover"
              className="table align-items-center table-flush table-hover"
            >
              <thead>
                <tr>
                  <th className="text-left" style={{ padding: "10px" }}>Sr. No.</th>
                  <th className="text-left" style={{ padding: "10px" }}>Investment Amt.</th>
                  <th className="text-left" style={{ padding: "10px" }}>ROI</th>
                  <th className="text-left" style={{ padding: "10px" }}>From Date</th>
                  <th className="text-left" style={{ padding: "10px" }}>To Date</th>
                  {/*<th className="text-left">IsActive</th>*/}
                  <th className="text-left" style={{ padding: "10px" }}>Interest Amount</th>
                  <th className="text-center" style={{ padding: "10px" }}></th>
                </tr>
              </thead>
              <tbody>
                {Interest.length > 0 ? (
                  Interest.map((item, index) => {
                    return (
                      <tr
                        key={index}

                        style={
                          item.status === 'Paid'
                            ? {
                              backgroundColor: "#66bb6a",
                              color: "white",
                            }
                            : {}
                        }
                      >
                        <td className="text-left" style={{ padding: "10px" }}>{index + 1} </td>
                        <td className="text-left" style={{ padding: "10px" }}>{item.capitalamount} </td>
                        <td className="text-left" style={{ padding: "10px" }}>{item.Rate_Of_Int}%</td>
                        <td className="text-left" style={{ padding: "10px" }}>
                          {dayjs(item.startDate).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td className="text-left" style={{ padding: "10px" }}>
                          {dayjs(item.enddate).format("DD/MM/YYYY")}
                        </td>
                        {/*<td className="text-left">
                            <a
                              id="Status"
                              style={{ cursor: "pointer" }}
                              className={`badge rounded-pill ${
                                item.IsActive === "Active"
                                  ? "badge badge-success text-white"
                                  : item.IsActive === "Closed"
                                  ? "badge badge-danger text-white"
                                  : "bg-primary-subtle text-primary"
                              } rounded-pill`}
                            >
                              {item.IsActive}
                            </a>
                            </td>*/}
                        <td className="text-left" style={{ padding: "10px" }}>
                          {parseFloat(item.InterestAmount).toFixed(
                            2
                          )}
                        </td>
                        <td className="text-center" style={{ padding: "10px" }}>
                          {item.status === 'Unpaid' ? (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={(e) => {
                                e.preventDefault();
                                showSummarry(index);
                              }}
                            >
                              Pay
                            </button>
                          ) : (
                            <Link className="btn btn-sm"
                              onClick={() => {
                                updateData(item.InterestId);
                              }}>
                              <EditIcon className="text-white" style={{ fontSize: "18px" }} />
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-center text-danger"
                      colspan={7}
                    >
                      Data Not Found
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} className="text-right" style={{ padding: "10px" }}>
                    <strong aligne="right">
                      Total Interest Amount:
                    </strong>
                  </td>
                  <td colSpan={2} className="text-left" style={{ padding: "10px" }}>
                    <strong>
                      {parseFloat(InterestAmount).toFixed(2)}
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      {isModal && (
        <InterestPayment
          open={isModal}
          onClose={closed}
          detail={Interest[indexValue]}
          month={dayjs(Interest[indexValue].startDate).month() + 1}
          year={dayjs(Interest[indexValue].startDate).year()}
          type="Submit"
        />
      )}
      {
        isModall && (
          <InterestPayment
            open={isModall}
            onClose={onClosed}
            detail={data[0]}
            month={0}
            year={0}
            type="Update"
          />
        )
      }
      {loader}
    </div>
  );
};

export default SlabInterest;
