import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";
import GradingIcon from '@mui/icons-material/Grading';


function generateOTP() {
  const otp = Math.floor(1000 + Math.random() * 9000);
  return otp.toString();
}

const VerifyCloseAccounts = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setCustomerData] = useState([]);
  const [dataa, setCustomerDataa] = useState([]);
  const [planDrop, setplanDrop] = useState([]);
  const [filter, setFilter] = useState({
    PlanId: "-1",
    FromDate: "",
    ToDate: "",
  });

  useEffect(() => {
    if (userData && userData.Org_Id && userData.role) {
      loadCustomers();
    }
  }, [userData]);


  const FilterClick = () => {
    loadCustomers();
  };

  const loadCustomers = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getClosedAccount?OrgId=${userData.Org_Id}&PlanId=${filter.PlanId}&FromDate=${filter.FromDate}&ToDate=${filter.ToDate}&Role=${userData.role}`
      );
      const staffResult = await axios.get(
        `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
      );
      //console.log(result.data);
      setCustomerData(result.data || []);
      setCustomerDataa(result.data || []);
      if (staffResult.data.length === 0) {
        setplanDrop([]);
        hideLoader();
      } else {
        setplanDrop(staffResult.data);
        hideLoader();
      }
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleDelete = async (Sr_No) => {
    await axios.delete(`${apiUrl}/deleteCustomer/${Sr_No}`);
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    await loadCustomers();
  };

  const columns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Enrollment No",
      selector: (row) => row.Enrollment_No,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => formatDate(row.Request_Date),
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row.Request_By,
      sortable: true,
      width: "200px",
    },
    {
      name: "Mobile",
      selector: (row) => row.Request_By_Mobile,
      sortable: true,
      center: true,
    },
    {
      name: "Plan Type",
      selector: (row) => (row.Plan_Name ? row.Plan_Name : "--"),
      sortable: true,
    },
   
    {
      name: "Actions",
      minHeight: "100px",
      cell: (row) => (
        <div className="dropdown">
          <a
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i>
              <MoreVertIcon
                style={{
                  color: "grey",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </i>
          </a>
          <div className="dropdown-menu">
            <Link
              className="dropdown-item"
              to={`/closeCustomerAccount/verify/${row.Customer_Id}`}
            >
              <GradingIcon
                className="text-info"
                style={{ fontSize: "18px" }}
              />{" "}
              View Account
            </Link>
          
         
          </div>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = dataa.filter((row) => {
      return (
        (`${row.First_Name} ${row.Middle_Name} ${row.Last_Name}` &&
          `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`
            .toLowerCase()
            .includes(filterValue)) ||
        (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
        (row.Investment_Type &&
          row.Investment_Type.toLowerCase().includes(filterValue)) ||
        (row.Enrollment_No &&
          row.Enrollment_No.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue)) ||
        (formatDate(row.Start_Date) &&
          formatDate(row.Start_Date).toLowerCase().includes(filterValue))
      );
    });
    setCustomerData(filteredData);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Verify Close Accounts</h5>
          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div className="row justify-content-center mt-2">
                <div className="col-4">
                  <label htmlFor="example-select" className="form-label">
                    Plan
                  </label>
                  <select
                    className="form-control"
                    id="example-select"
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        PlanId: e.target.value,
                      });
                    }}
                  >
                    <option value={-1}>All</option>
                    {planDrop.map((item, index) => (
                      <option
                        key={index}
                        value={item.Plan_Id}
                        label={item.Plan_Name}
                      ></option>
                    ))}
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="example-select" className="form-label">
                    From Date
                  </label>
                  <input
                    type="date"
                    id="txFromtDate"
                    name="txFromtDate"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        FromDate: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="example-select" className="form-label">
                    To Date
                  </label>
                  <input
                    type="date"
                    id="txtToDate"
                    name="txtToDate"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        ToDate: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-center mt-2">
                <div className="col-2">
                  <button
                    className="btn btn-primary"
                    onClick={FilterClick}
                    style={{ width: "100%" }}
                  >
                    {" "}
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card ">
            <div className="card-body">
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
               
              </Stack>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  //title="Customer Data"
                  columns={columns}
                  data={data}
                  fixedHeader
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};
export default VerifyCloseAccounts;
