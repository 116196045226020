import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useUser } from "../../componets/UserContext";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { error } from "../Alerts/alert";


const WhatsappTemplate = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setData] = useState([]);
    const loadCustomers = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/getWhatsappTemplate?OrgId=${userData.Org_Id}`
            );
            setData(result.data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };
    useEffect(() => {
        if (userData && userData.Org_Id) {
            loadCustomers();


        }
    }, [userData])
    const handleCheckboxChange = (index) => {
        const newData = [...data];
        newData[index].Status = !newData[index].Status === true ? 1 : 0;
        setData(newData);
    };

    const handleInputChange = (index, event) => {
        const newData = [...data];
        newData[index].Template = event.target.value;
        setData(newData);
    };
    const submitHandler = async (event) =>
    {
        event.preventDefault();
        const updatedData = data.map(obj => ({
            ...obj,
            User_Role: userData.role,
            Emp_Id: userData.Employee_Id,
            Added_By_Id: userData.userId,
            Added_By: `${userData.firstName} ${userData.lastName}`,
            Org_Id: userData.Org_Id,
            UserName: userData.UserName
        }));

        showLoader();
        axios
            .put(`${apiUrl}/updateWhatsappTemplate`, updatedData)
            .then((res) => {
              Swal.fire(
                "Success!",
                "All template has been updated successfully!!",
                "success"
              ).then((result) => {
                if (result.isConfirmed) {
                  // Redirect using React Router
                  // navigate("/viewTask");
                  //window.location.reload();
                }
              });
              hideLoader();
            })
            .catch((err) => {
              error("Server is Busy!!");
              hideLoader();
            });
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                        <h5 className="page-title">Whatsapp Message Template</h5>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={submitHandler}>
                                <div className="row">
                                    {data.length !== 0 ? (
                                        data.map((item, index) => {
                                            return (
                                                <div key={index} className="col-md-6">
                                                    <div className="form-group">
                                                        <label>{item.Title}</label><br />
                                                        <input type="checkbox" id="customControlAutosizing" onChange={() => handleCheckboxChange(index)} checked={item.Status === 1} />
                                                        <input type="text" className="form-control" value={item.Template} id="" placeholder="Template" rows={1}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                            style={{ display: "inline-block", width: "90%", marginLeft: "20px" }}></input>
                                                    </div>
                                                </div>)
                                        })) : (<></>)}

                                </div>
                                <div className="row justify-content-center mt-1">
                                    <div className="col-md-2 text-center">
                                        <button type="Submit" className="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </div>
    );
}

export default WhatsappTemplate