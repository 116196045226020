import React, { useState, useEffect } from "react";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import { error } from "../Alerts/alert";
import { useUser } from "../../componets/UserContext";
import Swal from "sweetalert2";

const ManualWhatsapp = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [employeeData, setEmployeeData] = useState([]);
    const [planData, setPlanData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [filter, setFilter] = useState({
        Receipient_Type: "-1",
        Plan_Id: "-1"
    });
    const [attachmentFileName, setAttachmentFileName] = useState("Choose file");
    const [selectedMobiles, setSelectedMobiles] = useState([]);
    const [checkedStatus, setCheckedStatus] = useState({});

    //console.log(selectedMobiles)

    const [selectAllChecked, setSelectAllChecked] = useState(false);

    // const handleSelectAllChange = (event) => {
    //     const isChecked = event.target.checked;

    //     // Set the checked status of all checkboxes in the table rows
    //     setCheckedStatus((prevCheckedStatus) => {
    //         const updatedCheckedStatus = {};
    //         for (const mobile in prevCheckedStatus) {
    //             updatedCheckedStatus[mobile] = isChecked;
    //         }
    //         return updatedCheckedStatus;
    //     });

    //     // Update the selectAllChecked state
    //     setSelectAllChecked(isChecked);
    // };

    const [message, setMaggase] = useState({
        Mobile: [],
        Message: "",
        Attachment: ""
    });


    const submitHandler = async (event) => {
      
        event.preventDefault();
        const updatedMassage = {
            ...message,
            Mobile: selectedMobiles,
            Added_By_Employee_Id: userData.Employee_Id ? userData.Employee_Id : "",
            Added_By_Id: userData.userId,
            Added_By: `${userData.firstName} ${userData.lastName}`,
            Org_Id: userData.Org_Id,
        }
        const formdata = new FormData();

        formdata.append("Mobile", JSON.stringify(updatedMassage.Mobile));
        formdata.append("Message", message.Message);
        formdata.append("Attachment", message.Attachment);
        formdata.append("Added_By_Employee_Id", updatedMassage.Added_By_Employee_Id);
        formdata.append("Added_By_Id", updatedMassage.Added_By_Id);
        formdata.append("Added_By", updatedMassage.Added_By);
        formdata.append("Org_Id", updatedMassage.Org_Id);

        showLoader();

        await axios
            .post(`${apiUrl}/manualWhatsappMessage`, formdata)
            .then((res) => {
                Swal.fire(
                    "Success!",
                    "Message Sent Successfully!!",
                    "success"
                );
                setMaggase({ Mobile: [], Message: "", Attachment: "" });
                setSelectedMobiles([]);
                setCheckedStatus({});
                hideLoader();
            })
            .catch((err) => {
                console.error(err);
                error("Server is Busy!!");
                hideLoader();
            });

    }

    const loadEmployeeData = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/getUsers?OrgId=${userData.Org_Id}`
            );
            setEmployeeData(result.data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };

    const loadPlanData = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
            );
            setPlanData(result.data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching Plan data: ", error);
        }
    };

    const loadCustomerData = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/getCustomerbyPalnDate?OrgId=${userData.Org_Id}&PlanId=${filter.Plan_Id}&FromDate=&ToDate=`
            );
            setCustomerData(result.data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching Customer Data : ", error);
        }
    };

    useEffect(() => {
        if (userData && userData.Org_Id) {
            if (filter?.Receipient_Type === "Customer") {
                loadPlanData();
            } else {
                loadEmployeeData();
            }
        }
        if (userData?.Org_Id && filter?.Plan_Id !== '-1') {
            loadCustomerData();
        }
    }, [userData, filter.Receipient_Type, filter.Plan_Id]);

    const handleCheckboxChange = (mobile) => {
        if (selectedMobiles.includes(mobile)) {
            setSelectedMobiles(selectedMobiles.filter(num => num !== mobile));
        } else {
            setSelectedMobiles([...selectedMobiles, mobile]);
        }

        setCheckedStatus({
            ...checkedStatus,
            [mobile]: !checkedStatus[mobile]
        });
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                        <h5 className="page-title">Send Whatsapp Message</h5>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-center mt-2">
                                <div className="col-4">
                                    <label htmlFor="example-select" className="form-label">
                                        Receipient Type :
                                    </label>
                                    <select className="form-control" id="example-select"
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                Receipient_Type: e.target.value,
                                            });

                                        }}>
                                        <option value={-1}>--- Select ---</option>
                                        <option value="Employee">Employee</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Custom">Custom</option>
                                    </select>
                                </div>
                                {filter.Receipient_Type === "Customer" &&
                                    <div className="col-4">
                                        <label htmlFor="example-select" className="form-label">
                                            Plan
                                        </label>
                                        <select className="form-control" id="example-select"
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    Plan_Id: e.target.value,
                                                });

                                            }}>
                                            <option value={-1}>--- Select ---</option>
                                            {planData.map((item) => (
                                                <option
                                                    key={item.Plan_Id}
                                                    value={item.Plan_Id}
                                                    label={item.Plan_Name}
                                                ></option>
                                            ))}
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    {filter.Receipient_Type === "Employee" &&
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <div className=" table-responsive">
                                                    <table id="dataTableHover" className="table align-items-center table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">
                                                                    {/* <input
                                                                        type="checkbox"
                                                                        onChange={handleSelectAllChange}
                                                                        checked={selectAllChecked}
                            />*/}
                                                                </th>

                                                                <th className="text-center">Emp Id</th>
                                                                <th className="text-center">Name</th>
                                                                <th className="text-center">Number</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employeeData.length !== 0 ? (
                                                                employeeData.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="text-center" style={{ width: "", padding: "15px 10px 10px 15px" }}>
                                                                                <input type="checkbox" id={`employeeCheck${index}`} checked={checkedStatus[item.Mobile] || false} onChange={() => handleCheckboxChange(item.Mobile)} />
                                                                            </td>

                                                                            <td className="text-center" style={{ width: "" }}>
                                                                                {item.Employee_Id}
                                                                            </td>
                                                                            <td className="text-center" style={{ width: "" }}>
                                                                                {item.First_Name}{" "}{item.Last_Name}
                                                                            </td>
                                                                            <td className="text-center" style={{ width: "" }}>
                                                                                {item.Mobile}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td className="text-center text-danger" colSpan={7}>
                                                                        No Data Found !!
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(filter.Receipient_Type === "Customer" && filter.Plan_Id !== "-1") &&
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <div className=" table-responsive">
                                                    <table id="dataTableHover" className="table align-items-center table-flush table-hover paidInterest" style={{ border: "1px solid #e3e6f0" }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">
                                                                    {/* <input
                                                                        type="checkbox"
                                                                        onChange={handleSelectAllChange}
                                                                        checked={selectAllChecked}
                                />*/}
                                                                </th>

                                                                <th className="text-center">Enrollment No.</th>
                                                                <th className="text-center">Name</th>
                                                                <th className="text-center">Number</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {customerData.length > 0 ? (
                                                                customerData.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="text-center" style={{ width: "", padding: "15px 10px 10px 15px" }}>
                                                                                <input type="checkbox" id={`customerCheck${index}`} checked={checkedStatus[item.Mobile] || false} onChange={() => handleCheckboxChange(item.Mobile)} />
                                                                            </td>
                                                                            <td className="text-center" style={{ width: "" }}>
                                                                                {item.Enrollment_No}
                                                                            </td>
                                                                            <td className="text-center" style={{ width: "" }}>
                                                                                {item.First_Name}{" "}{item.Last_Name}
                                                                            </td>
                                                                            <td className="text-center" style={{ width: "" }}>
                                                                                {item.Mobile}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td className="text-center text-danger" colSpan={7}>
                                                                        No Data Found !!
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {filter.Receipient_Type === "Custom" &&
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <textarea className="form-control" rows={7}
                                                    placeholder="(eg. 9999999999, 8888888888,...)"
                                                    value={selectedMobiles}
                                                    required={true}
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const cleanedInput = input.replace(/[^0-9]/g, ""); // Remove non-digit characters
                                                        const mobileNumbers = [];
                                                    
                                                        // Split the cleaned input into chunks of 10 digits each
                                                        for (let i = 0; i < cleanedInput.length; i += 10) {
                                                            const chunk = cleanedInput.substring(i, i + 10); // Extract a chunk of 10 digits
                                                            mobileNumbers.push(chunk);
                                                        }
                                                    
                                                        setSelectedMobiles(mobileNumbers);
                                                    }}
                                                    
                                                />

                                            </div>
                                        </div>
                                    }
                                </div>
                                {(filter.Receipient_Type === "Employee" || filter.Plan_Id !== "-1" || filter.Receipient_Type === "Custom") &&
                                    <div className="col-6 mt-3">
                                        <h6 style={{ padding: "10px", backgroundColor: "#37546c12" }}>All Receipients Count : {selectedMobiles.length}</h6>
                                        <form onSubmit={submitHandler}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="example-select" className="form-label" >
                                                            Message :
                                                        </label>
                                                        <textarea className="form-control" rows={4}
                                                            placeholder="Message..."
                                                            value={message.Message}
                                                            required={true}
                                                            onChange={(e) => {
                                                                setMaggase({
                                                                    ...message,
                                                                    Message: e.target.value,
                                                                });
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="Attachment">
                                                            Attachment :
                                                        </label>
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="Attachment"
                                                                name="Attachment"
                                                                onChange={(e) => {
                                                                    const file = e.target.files[0];
                                                                    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB

                                                                    if (file && file.size > maxSizeInBytes) {
                                                                        Swal.fire({
                                                                            icon: 'error',
                                                                            title: 'File size exceeds the limit',
                                                                            text: 'Please select a file smaller than 2MB.',
                                                                        });
                                                                        e.target.value = null;
                                                                    } else {
                                                                        const fileName = file?.name || "Choose file";
                                                                        setAttachmentFileName(fileName);
                                                                        setMaggase({
                                                                            ...message,
                                                                            Attachment: file,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                htmlFor="Photo"
                                                                style={{ overflow: "hidden" }}
                                                            >
                                                                {attachmentFileName}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 text-center">
                                                    <button
                                                        className="btn btn-primary mb-1"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </div>
    );
}

export default ManualWhatsapp;
