import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";
import GppBadIcon from "@mui/icons-material/GppBad";
import TopUpInvestment from "./TopUpInvestment"
import UpgradePlan from "./UpgradePlan";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import UpgradeIcon from '@mui/icons-material/Upgrade';
const ViewCustomer = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [isModal, setIsModal] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [data, setCustomerData] = useState([]);
  const [dataa, setCustomerDataa] = useState([]);
  const [planDrop, setplanDrop] = useState([]);
  const [indexValue, setIndex] = useState();
  const [filter, setFilter] = useState({
    PlanId: "-1",
    FromDate: "",
    ToDate: "",
  });

  useEffect(() => {
    if (userData && userData.Org_Id) {
      loadCustomers();
    }
  }, [userData]);

  const FilterClick = () => {
    loadCustomers();
  };

  const loadCustomers = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getCustomerbyPalnDate?OrgId=${userData.Org_Id}&PlanId=${filter.PlanId}&FromDate=${filter.FromDate}&ToDate=${filter.ToDate}`
      );
      const staffResult = await axios.get(
        `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
      );
      setCustomerData(result.data || []);
      setCustomerDataa(result.data || []);
      if (staffResult.data.length === 0) {
        setplanDrop([]);
        hideLoader();
      } else {
        setplanDrop(staffResult.data);
        hideLoader();
      }
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  const deleteUser = (Sr_No, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No, name);
      }
    });
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleDelete = async (Sr_No, name) => {
    const obj ={
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName:userData.UserName,
      Name: name
    }
    await axios.delete(`${apiUrl}/deleteCustomer/${Sr_No}`, {data: obj});
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    await loadCustomers();
  };

  const columns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Enrollment No",
      selector: (row) => row.Enrollment_No,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => formatDate(row.Start_Date),
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => <Link to={`/customerWiseDashBoard/${row.Sr_No}`}>{`${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`}</Link>,
      sortable: true,
      width: "180px",
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      sortable: true,
      center: true,
    },
    {
      name: "Plan Type",
      cell: (row) => (
        <LightTooltip title={row.Investment_Type}>
          <span
            style={{
              maxWidth: "100px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Investment_Type ? row.Investment_Type : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "Capital Amount",
      selector: (row) => row.Capital_Amount,
      sortable: true,
    },
    // {
    //   name: "Address",
    //   cell: (row) => (
    //     <LightTooltip title={row.Address}>
    //       <span
    //         style={{
    //           maxWidth: "80px",
    //           whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           padding: "5px",
    //           cursor: "pointer",
    //           display:"none"
    //         }}
    //       >
    //         {row.Address ? row.Address : "--"}
    //       </span>
    //     </LightTooltip>
    //   ),

    //   sortable: true,
    // },
    // {
    //   name: "Verified",
    //   cell: (row) => (
    //   <div>
    //   {row.Verified_By_Acc === "NO" ? (
    //     <i className="far fa-circle"></i>
    //   ) : (
    //     <i className="fas fa-check-circle text-success" ></i>
    //   )} &nbsp;
    //   {row.Verified_By_Super === "NO" ? (
    //     <i className="far fa-circle"></i>
    //   ) : (
    //     <i className="fas fa-check-circle text-success"></i>
    //   )} &nbsp;
    //   {row.Verified_By_Admin === "NO" ? (
    //     <i className="far fa-circle"></i>
    //   ) : (
    //     <i className="fas fa-check-circle text-success"></i>
    //   )}
    //   </div>
    //   ),
    // },
    {
      name: "IsActive",
      cell: (row) => (
        <a
          id="punchStatus"
          style={{ cursor: "pointer" }}
          className={`badge rounded-pill ${row.IsActive === "Active"
            ? "badge badge-success text-white"
            : row.IsActive === "Closed"
              ? "badge badge-danger text-white"
              : row.IsActive === "Pending"
                ? "badge badge-warning text-white"
                : "bg-primary-subtle text-primary"
            } rounded-pill`}
        >
          {row.IsActive}
        </a>
      ),
      width: "80px"
    },
    {
      name: "",
      cell: (row, index) => (
        <div className="dropdown">

          <a
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i>
              <MoreVertIcon
                style={{
                  color: "grey",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </i>
          </a>
          <div className="dropdown-menu">
            {row.IsActive === "Active" && (
              <>
                <Link className="dropdown-item" to={`/closeCustomerAccount/edit/${row.Sr_No}`}>
                  <GppBadIcon className="text-warning" style={{ fontSize: "18px" }} />{" "}
                  Close Account
                </Link>
                <Link className="dropdown-item" onClick={() => updateData(index)} >
                  <CurrencyRupeeIcon className="text-success" style={{ fontSize: "18px" }} />
                  {/* <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "} */}
                  Top Up
                </Link>
                <Link className="dropdown-item" onClick={() => upgradePlan(index)} >
                  <UpgradeIcon className="text-secondary" style={{ fontSize: "20px" }} />
                  {/* <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "} */}
                  Upgrade
                </Link>
              </>
            )}
            <Link className="dropdown-item" to={`/updateCustomer/edit/${row.Sr_No}`}>
              <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "}
              Edit
            </Link>
            {userData.role === "Admin" && (
              <Link
                className="dropdown-item"
                onClick={() => deleteUser(row.Sr_No, `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`)}
              >
                <DeleteIcon
                  className="text-danger"
                  style={{ fontSize: "18px" }}
                />{" "}
                Delete
              </Link>
            )}
          </div>
        </div>
      ),
      width: "45px"
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const DownloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape'
    });
    doc.text("Customer Details Report", 20, 10);

    const columns = [
      "Enrollment No",
      "Investment Date",
      "Start Date",
      "Plan",
      "Name",
      "Capital Amount",
      "Interest",
      "Mobile",
    ];

    const rows = data.map((row) => [
      row.Enrollment_No,
      formatDate(row.Investment_Date),
      formatDate(row.Start_Date),
      row.Investment_Type ? row.Investment_Type : "--",
      `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
      row.Capital_Amount,
      `${row.Rate_Of_Int}%`,
      row.Mobile,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("CustomerDetailsReport.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Enrollment No",
      "Investment Date",
      "Start Date",
      "Plan",
      "Name",
      "Capital Amount",
      "Interest",
      "Mobile",
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row.Enrollment_No,
        formatDate(row.Investment_Date),
        formatDate(row.Start_Date),
        row.Investment_Type ? row.Investment_Type : "--",
        `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`,
        row.Capital_Amount,
        `${row.Rate_Of_Int}%`,
        row.Mobile,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Customer Details Report");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "CustomerDetailsReport.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = dataa.filter((row) => {
      return (
        (`${row.First_Name} ${row.Middle_Name} ${row.Last_Name}` &&
          `${row.First_Name} ${row.Middle_Name} ${row.Last_Name}`
            .toLowerCase()
            .includes(filterValue)) ||
        (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
        (row.Investment_Type &&
          row.Investment_Type.toLowerCase().includes(filterValue)) ||
        (row.Enrollment_No &&
          row.Enrollment_No.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue)) ||
        (formatDate(row.Start_Date) &&
          formatDate(row.Start_Date).toLowerCase().includes(filterValue))
      );
    });
    setCustomerData(filteredData);
  }

  const closed = () => {
    setIsModal(false);
  };
  //console.log(data);
  const [topUpdata, setTopUpDate] = useState({});
  const updateData = (index) => {
    setIndex(index);
    setIsModal(true);
    setTopUpDate({
      ...topUpdata,
      Plan_Id: data[index].Investment_Type_Id,
      Plan_Name: data[index].Investment_Type,
      Customer_Id: data[index].Sr_No,
      Customer_Name: `${data[index].First_Name} ${data[index].Middle_Name} ${data[index].Last_Name}`,
      Enrollment_No: data[index].Enrollment_No,
      Percentage_Type_Id: data[index].Percentage_Type_Id,
      Percentage_Type: data[index].Percentage_Type
    })
  }
  const closeUpgradePlan = () => {
    setIsUpgrade(false);
  };
  const upgradePlan = (index) => {
    setIndex(index);
    setIsUpgrade(true);
    setTopUpDate({
      ...topUpdata,
      Plan_Id: data[index].Investment_Type_Id,
      Plan_Name: data[index].Investment_Type,
      Customer_Id: data[index].Sr_No,
      Customer_Name: `${data[index].First_Name} ${data[index].Middle_Name} ${data[index].Last_Name}`,
      Enrollment_No: data[index].Enrollment_No,
      Percentage_Type_Id: data[index].Percentage_Type_Id,
      Percentage_Type: data[index].Percentage_Type
    })
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">View Customer</h5>

          </div>
          <div className="card mb-2">
            <div className="card-body">
              <div className="row justify-content-center mt-2">
                <div className="col-4">
                  <label htmlFor="example-select" className="form-label">
                    Plan
                  </label>
                  <select
                    className="form-control"
                    id="example-select"
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        PlanId: e.target.value,
                      });
                    }}
                  >
                    <option value={-1}>All</option>
                    {planDrop.map((item, index) => (
                      <option
                        key={index}
                        value={item.Plan_Id}
                        label={item.Plan_Name}
                      ></option>
                    ))}
                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="example-select" className="form-label">
                    From Date
                  </label>
                  <input
                    type="date"
                    id="txFromtDate"
                    name="txFromtDate"
                    className="form-control"
                    //placeholder="Date"
                    dateFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        FromDate: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="example-select" className="form-label">
                    To Date
                  </label>
                  <input
                    type="date"
                    id="txtToDate"
                    name="txtToDate"
                    className="form-control"
                    //placeholder="Date"
                    dateFormat="dd-MM-yyyy"
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        ToDate: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-center mt-2">
                <div className="col-2">
                  <button
                    className="btn btn-primary"
                    onClick={FilterClick}
                    style={{ width: "100%" }}
                  >
                    {" "}
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card ">
            <div className="card-body">
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div style={{ zIndex: 0, position: "relative", minHeight:"100px" }}>
                <DataTable
                  //title="Customer Data"
                  columns={columns}
                  data={data}
                  fixedHeader
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
        {
          isModal && (
            <>
              {console.log(data[indexValue])}
              <TopUpInvestment
                open={isModal}
                onClose={closed}
                detail={topUpdata}
                type="Submit"
             
              />
            </>
          )
        }
        {
          isUpgrade && (
            <>
              <UpgradePlan
                open={isUpgrade}
                onClose={closeUpgradePlan}
                detail={topUpdata}
                type="Submit"
                plans={planDrop}
              />
            </>
          )
        }
        {loader}
      </div>
    </div>
  );
};
export default ViewCustomer;
