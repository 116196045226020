import React, { useState, useEffect } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { Paper, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GridViewIcon from "@mui/icons-material/GridView";
import { Link } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { apiUrl } from "../../componets/apiConfig";
import { useParams } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";
import "./css.css";
import { useUser } from "../../componets/UserContext";

const Reporting = ({ Sr_No }) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [customer, setCustomer] = useState([]);
  const [Iscustomer, setIsCustomer] = useState(false);
  const { userData } = useUser();

  useEffect(() => {
    if (Iscustomer) {
      showLoader();
      generateAndSavePDF();
      hideLoader();
    }
  }, [Iscustomer]);

  useEffect(() => {
    if (Sr_No) {
      showLoader();
      axios.get(`${apiUrl}/getCustomerById/${Sr_No}`).then((res) => {
        setCustomer(res.data);
        setIsCustomer(true);
        hideLoader();
      });
    }
  }, [Sr_No]);

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const generateAndSavePDF = async () => {
    const element = document.getElementById("printablediv");
    const opt = {
      margin: 10,
      filename: `Receipt.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .outputPdf("blob")
      .then((pdfBlob) => {
        
    debugger;
        const whatsapp = {
          Customer : `${customer.First_Name} ${customer.Last_Name}`,
          Investment_Type: customer.Investment_Type,
          Enrollment_No: customer.Enrollment_No,
          number: customer.Mobile,
          Email: customer.Email,
          OrgId : userData.Org_Id
        };
        const formData = new FormData();
        formData.append("pdf", pdfBlob, "Receipt.pdf");
        formData.append("Customer", whatsapp.Customer);
        formData.append("Investment_Type", whatsapp.Investment_Type);
        formData.append("Enrollment_No", whatsapp.Enrollment_No);
        formData.append("number", whatsapp.number);
        formData.append("OrgId", whatsapp.OrgId);

        axios
          .post(`${apiUrl}/save-pdf`, formData)
          .then((response) => {
            // Handle the response from the server if needed
           // console.log("PDF saved on server.", response.data);
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error saving PDF:", error);
          });
      });
  };

  return (
    <div className="container-fluid d-none" >
      <Paper elevation={3}>
        <div>
          <div className="container">
            <div className="div" id="printablediv">
              <div className="row  justify-content-center">
                <div className="col-md-6 ">
                  <img
                    src="/img/logo.png"
                    alt="logo"
                    style={{
                      maxWidth: "400px",
                      height: "auto",
                      width: "100%",
                      paddingLeft: 0,
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-cneter px-4 mb-3">
                <div className="col-md-12 text-center">
                  <b style={{ fontSize: "14px" }}>
                    Off. No 408, C.J. Complex, Above Vodafone Gallery Opp.
                    Railway Station, Kalyan West 421301
                  </b>
                  <br />
                  <h6 className="col text-center">
                    <strong style={{ fontSize: "14px" }}>
                      {" "}
                      Contact No - 8879862452/7304223366
                    </strong>
                  </h6>
                </div>
              </div>
              <div className="row justify-content-cneter px-4">
                <div className="col-md-12">
                  <strong>Enrollment ID </strong>
                  <p
                    style={{
                      minWidth: "80px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block",
                    }}
                  >
                    {customer.Enrollment_No}
                  </p>

                  <h6 className="col text-right">
                    <strong>Date: </strong>
                    <p
                      style={{
                        minWidth: "100px",
                        padding: "0 10px",
                        borderBottom: "1px solid grey",
                        display: "inline-block",
                      }}
                    >
                      {formatDate(customer.Investment_Date)}
                    </p>
                  </h6>
                </div>
                <div className="col text-center mb-2">
                  <strong>
                    <p
                      style={{
                        minWidth: "222px",
                        padding: "0 10px",
                        borderBottom: "1px solid grey",
                        display: "inline-block",
                      }}
                    >
                      Acknowledgment Receipt :
                    </p>
                  </strong>
                </div>

                <div className="row px-4">
                  <strong>Received the Amount Of </strong>
                  <p
                    style={{
                      width: "455px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block !important",
                    }}
                  >
                    {customer.Capital_Amount}/-
                  </p>
                </div>
                <div className="row px-4">
                  <strong>From Mr./Mrs.</strong>
                  <p
                    style={{
                      minWidth: "531px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block",
                    }}
                  >
                    {`${customer.First_Name} ${customer.Middle_Name} ${customer.Last_Name}`}
                  </p>
                </div>
                <div className="col-12 px-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row">
                        <strong>Reference By</strong>
                        <p
                          style={{
                            minWidth: "109px",
                            padding: "0 10px",
                            borderBottom: "1px solid grey",
                            display: "inline-block",
                          }}
                        >
                          {customer.Reference}&nbsp;
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <strong>Rate Of Int</strong>
                        <p
                          style={{
                            minWidth: "115px",
                            padding: "0 10px",
                            borderBottom: "1px solid grey",
                            display: "inline-block",
                          }}
                        >
                          {customer.Rate_Of_Int}%
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <strong>Start Date :</strong>
                        <p
                          style={{
                            minWidth: "120px",
                            padding: "0 10px",
                            borderBottom: "1px solid grey",
                            display: "inline-block",
                          }}
                        >
                          {formatDate(customer.Start_Date)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-4">
                  <strong>Amount of </strong>
                  <p
                    style={{
                      minWidth: "554px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block",
                    }}
                  >
                    {`(${customer.Mode_Of_Investment}) ${customer.Capital_Amount}`}
                    /-
                  </p>
                </div>
                <div className="row px-4">
                  <strong>Bank : </strong>
                  <p
                    style={{
                      minWidth: "589px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block",
                    }}
                  >
                    SBI
                  </p>
                </div>
                <div className="row px-4">
                  <strong>Branch : </strong>
                  <p
                    style={{
                      minWidth: "574px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block",
                    }}
                  >
                    Dahisar East
                  </p>
                </div>
                <div className="row px-4">
                  <strong>Date Of Cheque </strong>
                  <p
                    style={{
                      minWidth: "516px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block",
                    }}
                  >
                    16/12/2012
                  </p>
                </div>
                <div className="row px-4 mb-4">
                  <strong>Cheque No : </strong>
                  <p
                    style={{
                      minWidth: "543px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block",
                    }}
                  >
                    623024
                  </p>
                </div>

                <div className="row px-4">
                  <strong>Customer Sign </strong>
                  <p
                    style={{
                      minWidth: "525px",
                      padding: "0 10px",
                      borderBottom: "1px solid grey",
                      display: "inline-block",
                    }}
                  >
                    &nbsp;
                  </p>
                </div>
                <div className="col-12 px-4 mb-2">
                  <div className="row">
                    <strong>Date Of Sign </strong>
                    <p
                      style={{
                        minWidth: "100px",
                        padding: "0 10px",
                        borderBottom: "1px solid grey",
                        display: "inline-block",
                      }}
                    >
                      &nbsp;&nbsp; &nbsp;&nbsp;/&nbsp;&nbsp;
                      &nbsp;&nbsp;/20&nbsp;&nbsp; &nbsp;&nbsp;
                    </p>
                  </div>
                </div>

                <div className="row px-4 mb-1">
                  <strong>Received By </strong>
                </div>
                <div className="col-12 px-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <strong>Name </strong>
                        <p
                          style={{
                            minWidth: "248px",
                            padding: "0 10px",
                            borderBottom: "1px solid grey",
                            display: "inline-block",
                          }}
                        >
                          {customer.Added_By}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <strong>Sign : </strong>
                        <p
                          style={{
                            minWidth: "251px",
                            padding: "0 10px",
                            borderBottom: "1px solid grey",
                            display: "inline-block",
                          }}
                        >
                          &nbsp;
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <strong>Branch </strong>
                        <p
                          style={{
                            minWidth: "241px",
                            padding: "0 10px",
                            borderBottom: "1px solid grey",
                            display: "inline-block",
                          }}
                        >
                          Kalyan
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <strong>Employee Id </strong>
                        <p
                          style={{
                            minWidth: "200px",
                            padding: "0 10px",
                            borderBottom: "1px solid grey",
                            display: "inline-block",
                          }}
                        >
                          {customer.Added_By_Employee_Id}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loader}
        </div>
      </Paper>
    </div>
  );
};

export default Reporting;
