import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { useUser } from "../../componets/UserContext";
import axios from "axios";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Swal from "sweetalert2";
import { apiUrl } from "../../componets/apiConfig";
import { error } from "../Alerts/alert";
import { useNavigate } from "react-router-dom";
import useButtonLoader from "../../mui/useButtonLoader";
import CustomerPreview from "./CustomerPreview";

const Payment_Mode = [
  { label: "Online", value: "Online" },
  { label: "TPT", value: "TPT" },
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "IMPS", value: "IMPS" },
  { label: "GPAY", value: "GPAY" },
  { label: "PhonePay", value: "PhonePay" },
  { label: "PAYTM", value: "PAYTM" },
  { label: "DD", value: "DD" },
  { label: "NEFT", value: "NEFT" },
  { label: "RTGS", value: "RTGS" },
];

const Gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const Marital_Status = [
  { label: "Married", value: "Married" },
  { label: "Unmarried", value: "Unmarried" },
];

const StudentOption = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const ReferenceOption = [
  { label: "Direct", value: "Direct" },
  { label: "Reference", value: "Reference" },
];

const AddCustomer = () => {
  const [selectedPayMode, setSelectedPayMode] = useState([]);
  const [addressProofFileName, setAddressProofFileName] =
    useState("Choose file");
  const [idProofFileName, setIdProofFileName] = useState("Choose file");
  const [nomineeSignFileName, setNomineeSignFileName] = useState("Choose file");
  const [nomineePhotoFileName, setNomineePhotoFileName] =
    useState("Choose file");
  const [nomineeAddressProofFileName, setNomineeAddressProofFileName] =
    useState("Choose file");
  const [nomineeDOBProofFileName, setNomineeDOBProofFileName] =
    useState("Choose file");
  const [coaplicantPhotoFileName, setCoaplicantPhotoFileName] =
    useState("Choose file");
  const [coaplicantDOBProofFileName, setCoaplicantDOBProofFileName] =
    useState("Choose file");
  const [aplicantPhotoFileName, setAplicantPhotoFileName] =
    useState("Choose file");
    const [PaymentDocFileName, setPaymentDocFileName] =
    useState("Choose file");
  const [enrollmentPrefix, setEnrollmentPrefix] = useState("");
  const [isValidMobile, setIsValidMobile] = useState(true);
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [Bank, setBankOption] = useState([]);
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedBankName, setSelectedBankName] = useState([]);
  const [capitalAmount, setCapitalAmount] = useState(0);
  const [errors, setErrors] = useState({});
  const { userData } = useUser();
  const [plan, setPlanOption] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);

  const [selectedGender, setSelectedGender] = useState([]);

  const [IsStudent, setIsStudent] = useState([]);
  const [referenceType, setReferenceType] = useState([]);

  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState([]);
  const [buttonElement, setLoading] = useButtonLoader("Submit", "Submiting...");

  const [PercentageType, setPercentageTypeOption] = useState([]);
  const [selectedPercentageType, setSelectedPercentageType] = useState([]);

  const [InvestmentPeriod, setInvestmentPeriodOption] = useState([]);
  const [selectedInvestmentPeriod, setSelectedInvestmentPeriod] = useState([]);

  const [rateOption, setRateOption] = useState([]);
  const [selectedRate, setSelectedRate] = useState(0);

  const [IdProof, setIdProofOption] = useState([]);
  const [selectedIdProof, setSelectedIdProof] = useState([]);

  const [AddressProof, setAddressProofOption] = useState([]);
  const [selectedAddressProof, setSelectedAddressProof] = useState([]);

  const [invoices, setInvoices] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [IspreviousInv, setIspreviousInv] = useState(false);
  const [IsPreview, setIsPreview] = useState(false);
  const [updateCustomer, setData] = useState({});
  //fetching last invoice number

  const HandelPlanChange = (option) => {
    setSelectedPlan(option);
  };
  const [slabwiseCustomers, setSlabwiseCustomers] = useState([
    { To_Amount: '', From_Amount: '', Interest: '' },
  ]);


  const handleInputChange = (index, fieldName, value) => {
    const newCustomers = [...slabwiseCustomers];
    newCustomers[index] = { ...newCustomers[index], [fieldName]: value };
    setSlabwiseCustomers(newCustomers);
  };

  const handleReplicate = () => {
    // Replicate only up to 4 times (index 0 to 3)
    if (slabwiseCustomers.length < 4) {
      setSlabwiseCustomers([...slabwiseCustomers, { To_Amount: '', From_Amount: '', Interest: '' }]);
    }
  };
  
 // console.log(slabwiseCustomers)

  const handleRemove = (index) => {
    // Don't remove if there's only one pair of fields
    if (slabwiseCustomers.length <= 1) {
      return;
    }

    const newCustomers = slabwiseCustomers.filter((_, idx) => idx !== index);
    setSlabwiseCustomers(newCustomers);
  };

  // useEffect(() => {
  //   if (selectedPercentageType && selectedPercentageType.value) {
  //     (async () => {
  //       try {
  //         showLoader();
  //         const rate = await axios.get(
  //           `${apiUrl}/getInterestRates?OrgId=${userData.Org_Id}&Type_Id=${selectedPercentageType.value}`
  //         );
  //         const rateOption = rate.data.map((option) => ({
  //           label: `${option.Rate}%`,
  //           value: option.Rate,
  //         }));
  //         setRateOption(rateOption);
  //         //setSelectedRate({ label: rate.Rate, value: rate.Rate });

  //         hideLoader();
  //       } catch (error) {
  //         console.error(error);
  //         hideLoader();
  //       }
  //     })();
  //   }
  // }, [selectedPercentageType.value, userData]);

  useEffect(() => {
    //debugger;
    if (userData && userData.Org_Id && enrollmentPrefix) {
      (async () => {
        showLoader();
        const result = await axios.get(
          `${apiUrl}/getEnrollmentNo?OrgId=${userData.Org_Id}`
        );
        setInvoices(result.data);
        const nextInvoiceNumber = generateNextInvoiceNumber(result.data);
        setInvoiceNumber(nextInvoiceNumber);
        setCustomer({
          ...customer,
          Enrollment_No: nextInvoiceNumber,
        })
        hideLoader();
      })();
    }
  }, [enrollmentPrefix]);

  
  useEffect(() => {
    if (selectedPlan && selectedPlan.value) {
      (async () => {
        try {
          showLoader();
          const cap_Amount = await axios.get(
            `${apiUrl}/getPlanss/${selectedPlan.value}`
          );
          //console.log(cap_Amount.data)
          setCapitalAmount(cap_Amount.data.Capital_Amount);
          setEnrollmentPrefix(cap_Amount.data.Plan_Prefix);
          setSelectedRate(parseFloat(cap_Amount.data.Interest_Rate).toFixed(2));
          hideLoader();
        } catch (error) {
          console.error(error);
          hideLoader();
        }
      })();
    }
  }, [selectedPlan.value]);


  useEffect(() => {
    if (userData && userData.Org_Id) {
      LoadCategory();
    }
  }, [userData, selectedPlan.value]);

  const LoadCategory = async () => {
    showLoader();
    const Periods = await axios.get(
      `${apiUrl}/getPeriods?OrgId=${userData.Org_Id}`
    );
    const periodsOption = Periods.data.map((option) => ({
      label: option.Name,
      value: option.Value,
    }));
    setInvestmentPeriodOption(periodsOption);

    const PercentageTypes = await axios.get(
      `${apiUrl}/getPercentageTypes?OrgId=${userData.Org_Id}`
    );
    const percentageTypesOption = PercentageTypes.data.map((option) => ({
      label: option.Percentage_Type_Name,
      value: option.Sr_No,
    }));
    //console.log(percentageTypesOption)
    setPercentageTypeOption(percentageTypesOption);
    setSelectedPercentageType({ label: 'Fixed', value: 2 });

    const IDProof = await axios.get(
      `${apiUrl}/getIDProofTypes?OrgId=${userData.Org_Id}`
    );
    const IDProofOption = IDProof.data.map((option) => ({
      label: option.ID_Proof_Name,
      value: option.ID_Proof_Name,
    }));
    setIdProofOption(IDProofOption);

    const AddressProof = await axios.get(
      `${apiUrl}/getAddressProofTypes?OrgId=${userData.Org_Id}`
    );
    const AddressProofOption = AddressProof.data.map((option) => ({
      label: option.Address_Proof_Name,
      value: option.Address_Proof_Name,
    }));
    setAddressProofOption(AddressProofOption);

    const plan = await axios.get(`${apiUrl}/getPlans?OrgId=${userData.Org_Id}`);
    const planOption = plan.data.map((option) => ({
      label: option.Plan_Name,
      value: option.Plan_Id,
    }));
    setPlanOption(planOption);

    const res = await axios.get(`${apiUrl}/getBanks?OrgId=${userData.Org_Id}`);
    const bankOption = res.data.map((option) => ({
      label: option.Bank_Name,
      value: option.Bank_Name,
    }));
    setBankOption(bankOption);

    hideLoader();
  };



  function generateNextInvoiceNumber(existingInvoices) {
    
    if (existingInvoices.length === 0) {
      return `ETIFM0001`;
    }
    const lastInvoiceNumber = existingInvoices.replace(
      `ETIFM`,
      ""
    );

    const lastNumber = parseInt(lastInvoiceNumber);
    const nextNumber = lastNumber + 1;

    // Format the next invoice number with leading zeros
    const nextInvoiceNumber = `${'ETIFM'}${String(nextNumber).padStart(
      4,
      "0"
    )}`;

    return nextInvoiceNumber;
  }

  function randomString(length, chars) {
    var mask = "";
    if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
    if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (chars.indexOf("#") > -1) mask += "0123456789";
    if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
    var result = "";
    for (var i = length; i > 0; --i)
      result += mask[Math.floor(Math.random() * mask.length)];
    return result;
  }

  const [customer, setCustomer] = useState({
    Enrollment_No: "",
    Start_Date: "",
    Investment_Type_Id: "",
    Investment_Type: "",
    Capital_Amount: "",
    Rate_Of_Int: 0,
    Mode_Of_Investment: "",
    Payment_Doc:"",
    Bank: "",
    Branch_Name: "",
    Cheque_No: "",
    Cheque_Date: "",
    Reference_No: "",
    Investment_Date: "",
    Percentage_Type_Id: "",
    Percentage_Type: "",
    Investment_Period: "",
    Investment_Period_Value: "",
    Remarks: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    DOB: "",
    Gender: "",
    Marital_Status: "",
    Mobile: "",
    Email: "",
    Pan_No: "",
    Aadhar_No: "",
    Reference_Type: "",
    Reference: "",
    Student: "",
    Address: "",
    Bank_Name: "",
    IFCE_Code: "",
    Account_No: "",
    Account_Holder_Name: "",
    Co_applicant: false,
    Co_applicant_Photo: "",
    Co_applicant_Full_Name: "",
    Co_applicant_DOB: "",
    Co_applicant_Birth_Proof: "",
    Co_applicant_Occupation: "",
    Co_applicant_Relationship: "",
    Co_applicant_Address: "",
    Photo: "",
    Id_Proof_Type: "",
    Id_Proof_Attachment: "",
    Address_Proof_Type: "",
    Address_Proof_Attachment: "",
    Nominee_Full_Name: "",
    Nominee_Address_Proof:"",
    Nominee_DOB: "",
    Nominee_Birth_Proof: "",
    Nominee_Photo: "",
    Nominee_Sign_Attachment: "",
    Added_By_Employee_Id: "",
    Added_By_Id: "",
    Added_By: "",
    Org_Id: "",
    SlabWiseInterest: []
  });

  const showSummarry = async (event) => {
    event.preventDefault();
    debugger;

    const sumSlabAmount = slabwiseCustomers.reduce((sum, entry) => {
      // Convert To_Amount to a number and add to the sum
      return sum + (entry.To_Amount ? parseInt(entry.To_Amount) : 0);
    }, 0);

    setData({
      ...updateCustomer,
      ...customer,
      SlabWiseInterest: slabwiseCustomers,
      Enrollment_No: invoiceNumber,
      Capital_Amount: capitalAmount,
      Bank: selectedBankName.value,
      Bank_Name: selectedBank.value,
      Rate_Of_Int: selectedRate ? selectedRate : 0,
      Investment_Type_Id: selectedPlan.value,
      Investment_Type: selectedPlan.label,
      Mode_Of_Investment: selectedPayMode.value,
      Gender: selectedGender.value,
      Marital_Status: selectedMaritalStatus.value,
      Id_Proof_Type: selectedIdProof.value,
      Address_Proof_Type: selectedAddressProof.value,
      Student: IsStudent.value,
      Reference_Type: referenceType.value,
      Percentage_Type_Id: selectedPercentageType.value,
      Percentage_Type: selectedPercentageType.label,
      Investment_Period: selectedInvestmentPeriod.label,
      Investment_Period_Value: selectedInvestmentPeriod.value,
      User_Role: userData.role,
      Added_By_Employee_Id: userData.Employee_Id ? userData.Employee_Id : "",
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName:userData.UserName,
    });

   // console.log(updateCustomer.SlabWiseInterest);

    const validationErrors = {};

    if (selectedPlan.label === "SlabWise" && sumSlabAmount !== parseFloat(capitalAmount)) {
      validationErrors.sumMismatch = "Sum of slab amounts must be equal to capital amount.";
    }

    if (Object.keys(validationErrors).length === 0) {

      setIsPreview(true);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSubmit = () => {
    //debugger;
    setLoading(true);

    const formdata = new FormData();
    formdata.append("SlabWiseInterest", JSON.stringify(updateCustomer.SlabWiseInterest));
    formdata.append("User_Role", updateCustomer.User_Role);
    formdata.append("UserName", updateCustomer.UserName);
    formdata.append("Enrollment_No", updateCustomer.Enrollment_No);
    formdata.append("Start_Date", customer.Start_Date);
    formdata.append("Payment_Doc", customer.Payment_Doc);
    formdata.append("Investment_Date", customer.Investment_Date);
    if (customer.Branch_Name) {
      formdata.append("Branch_Name", customer.Branch_Name);
    }
    if (customer.Cheque_No) {
      formdata.append("Cheque_No", customer.Cheque_No);
    }
    if (customer.Cheque_Date) {
      formdata.append("Cheque_Date", customer.Cheque_Date);
    }
    if (customer.Reference_No) {
      formdata.append("Reference_No", customer.Reference_No);
    }
    formdata.append("First_Name", customer.First_Name);
    formdata.append("Middle_Name", customer.Middle_Name);
    formdata.append("Last_Name", customer.Last_Name);
    formdata.append("Remarks", customer.Remarks);
    formdata.append("DOB", customer.DOB);
    formdata.append("Mobile", customer.Mobile);
    formdata.append("Email", customer.Email);
    formdata.append("Pan_No", customer.Pan_No);
    formdata.append("Aadhar_No", customer.Aadhar_No);
    formdata.append("Reference_Type", updateCustomer.Reference_Type);
    formdata.append("Reference", customer.Reference);
    formdata.append("Address", customer.Address);
    formdata.append("IFCE_Code", customer.IFCE_Code);
    formdata.append("Account_No", customer.Account_No);
    formdata.append("Account_Holder_Name", customer.Account_Holder_Name);
    formdata.append("Co_applicant", customer.Co_applicant);
    formdata.append("Co_applicant_Full_Name", customer.Co_applicant_Full_Name);
    formdata.append("Co_applicant_DOB", customer.Co_applicant_DOB);
    formdata.append("Co_applicant_Photo", customer.Co_applicant_Photo);
    formdata.append(
      "Co_applicant_Birth_Proof",
      customer.Co_applicant_Birth_Proof
    );
    formdata.append(
      "Co_applicant_Occupation",
      customer.Co_applicant_Occupation
    );
    formdata.append(
      "Co_applicant_Relationship",
      customer.Co_applicant_Relationship
    );
    formdata.append("Co_applicant_Address", customer.Co_applicant_Address);
    formdata.append("Photo", customer.Photo);
    formdata.append("Id_Proof_Type", updateCustomer.Id_Proof_Type);
    formdata.append("Id_Proof_Attachment", updateCustomer.Id_Proof_Attachment);
    formdata.append("Address_Proof_Type", updateCustomer.Address_Proof_Type);
    formdata.append(
      "Address_Proof_Attachment",
      customer.Address_Proof_Attachment
    );

    formdata.append("Nominee_Full_Name", customer.Nominee_Full_Name);
    formdata.append("Nominee_DOB", customer.Nominee_DOB);
    formdata.append(
      "Nominee_Sign_Attachment",
      customer.Nominee_Sign_Attachment
    );
    formdata.append("Nominee_Birth_Proof", customer.Nominee_Birth_Proof);
    formdata.append("Nominee_Photo", customer.Nominee_Photo);
    formdata.append("Nominee_Address_Proof", customer.Nominee_Address_Proof);
    formdata.append("Mode_Of_Investment", updateCustomer.Mode_Of_Investment);
    formdata.append("Capital_Amount", updateCustomer.Capital_Amount);
    formdata.append("Percentage_Type_Id", updateCustomer.Percentage_Type_Id);
    formdata.append("Percentage_Type", updateCustomer.Percentage_Type);
    formdata.append("Investment_Period", updateCustomer.Investment_Period);
    formdata.append(
      "Investment_Period_Value",
      updateCustomer.Investment_Period_Value
    );
    formdata.append("Rate_Of_Int", updateCustomer.Rate_Of_Int);
    formdata.append("Bank_Name", updateCustomer.Bank_Name);
    if (updateCustomer.Bank) {
      formdata.append("Bank", updateCustomer.Bank);
    }
    formdata.append("Investment_Type_Id", updateCustomer.Investment_Type_Id);
    formdata.append("Investment_Type", updateCustomer.Investment_Type);
    formdata.append("Gender", updateCustomer.Gender);
    formdata.append("Student", updateCustomer.Student);
    formdata.append("Marital_Status", updateCustomer.Marital_Status);
    formdata.append(
      "Added_By_Employee_Id",
      updateCustomer.Added_By_Employee_Id
    );
    formdata.append("Added_By_Id", updateCustomer.Added_By_Id);
    formdata.append("Added_By", updateCustomer.Added_By);
    formdata.append("Org_Id", updateCustomer.Org_Id);


    showLoader();
    axios
      .post(`${apiUrl}/addCustomer`, formdata)
      .then((res) => {
        Swal.fire("Success!", "Customer has been Added.", "success");
        navigate("/viewCustomer");
        setLoading(false);
        showLoader();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Add Customer. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
        setLoading(false);
      });
  };

  const closed = (status) => {

    if (status) {
      handleSubmit();
    }
    setIsPreview(false);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Add Customer</h5>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={showSummarry}>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-map-pin mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Choose Investment Plan
                  </h5>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="selectedPlan" className="form-label">
                          Investment Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          options={plan}
                          value={selectedPlan}
                          onChange={HandelPlanChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="txtFname" className="form-label">
                          Enrollment ID<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="txtFname"
                          className="form-control "
                          required
                          disabled
                          placeholder="Enter Enrollment ID"
                          value={invoiceNumber}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Enrollment_No: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Investment_Date" className="form-label">
                          Investment Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          id="Investment_Date"
                          className="form-control "
                         // placeholder="Enter Mobile Number"
                          required
                          dateFormat="dd-MM-yyyy"
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Investment_Date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="txtLname" className="form-label">
                          Capital Amount<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="txtLname"
                          className="form-control"
                          placeholder="Enter Capital Amount"
                          required
                          //disabled
                          value={capitalAmount}
                          onChange={(e) => {
                            const input = e.target.value;
                            const decimalInput = input.replace(/[^0-9.]/g, "");
                            const amount = decimalInput.replace(
                              /^(\d*\.\d*)\..*$/,
                              "$1"
                            );
                            setCapitalAmount(amount);
                            setErrors({ ...errors, sumMismatch:''});
                            }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Start_Date" className="form-label">
                        Start Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        id="Start_Date"
                        className="form-control "
                        required
                        dateFormat="dd-MM-yyyy"
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Start_Date: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          Mode Of Investment
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          options={Payment_Mode}
                          value={selectedPayMode}
                          onChange={setSelectedPayMode}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="Payment_Doc"
                      >
                      Payment Doc
                      </label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="Payment_Doc"
                          name="Payment_Doc"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) => {
                            const fileName =
                              e.target.files[0]?.name || "Choose file";
                            setPaymentDocFileName(fileName);

                            setCustomer({
                              ...customer,
                              Payment_Doc: e.target.files[0],
                            });
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="Payment_Doc"
                          style={{ overflow: "hidden" }}
                        >
                          {PaymentDocFileName}
                        </label>
                      </div>
                    </div>
                  </div>
                   
                  </div>

                  <div className="row">
                    {["Cheque", "DD"].includes(selectedPayMode.value) && (
                      <>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="Bank" className="form-label">
                              Bank Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              name="Bank"
                              id="Bank"
                              options={Bank}
                              value={selectedBankName}
                              onChange={setSelectedBankName}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="Branch_Name" className="form-label">
                            IFSC/Branch Name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="Branch_Name"
                              name="Branch_Name"
                              placeholder="Enter IFSC/Branch Name"
                              required={true}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Branch_Name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="Cheque_No" className="form-label">
                              Cheque No <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="Cheque_No"
                              name="Cheque_No"
                              placeholder="Enter Cheque No"
                              value={customer.Cheque_No}
                              required={true}
                              onChange={(e) => {
                                const input = e.target.value;
                                const numericInput = input.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setCustomer({
                                  ...customer,
                                  Cheque_No: numericInput,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="Cheque_Date" className="form-label">
                              Cheque Date{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              id="Cheque_Date"
                              name="Cheque_Date"
                              dateFormat="dd-MM-yyyy"
                              required={true}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Cheque_Date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row">
                    {[
                      "TPT",
                      "IMPS",
                      "GPAY",
                      "Online",
                      "PhonePay",
                      "PAYTM",
                      "NEFT",
                      "RTGS",
                    ].includes(selectedPayMode.value) && (
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="Reference_No" className="form-label">
                              Transaction ID{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="Reference_No"
                              name="Reference_No"
                              required={true}
                              placeholder="Enter Transaction ID"
                              value={customer.Reference_No}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Reference_No: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="row">
                    <div className={selectedPercentageType.label === "Fixed" ? "col-md-4" : "col-md-6"}>
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          Investment Period
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          options={InvestmentPeriod}
                          value={selectedInvestmentPeriod}
                          onChange={setSelectedInvestmentPeriod}
                          required
                        />
                      </div>
                    </div>
                    <div className={selectedPercentageType.label === "Fixed" ? "col-md-4" : "col-md-6"}>
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          Percentage Type
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          isDisabled
                          options={PercentageType}
                          value={selectedPercentageType}
                          onChange={setSelectedPercentageType}
                          required
                        />
                      </div>
                    </div>
                    {selectedPercentageType.label === "Fixed" && (
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Rate_Of_Int">
                            Rate Of Int.<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Rate_Of_Int"
                            required={selectedPercentageType.label === "Fixed"}
                            //options={rateOption}
                            disabled
                            value={selectedRate}
                            //onChange={setSelectedRate}
                            onChange={(e) => {
                              const input = e.target.value;
                              const decimalInput = input.replace(/[^0-9.]/g, "");
                              const amount = decimalInput.replace(
                                /^(\d*\.\d*)\..*$/,
                                "$1"
                              );
                              setSelectedRate(amount);
                              //setErrors({ ...errors, sumMismatch:''});
                              }}
                            id="Rate_Of_Int"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {selectedPercentageType.label === "Slabwise" && (
                    <div>
                      {slabwiseCustomers.map((customer, index) => (
                        <div className="row" key={index}>
                         {/* <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor={`From_Amount_${index}`} className="form-label">
                                From Amount<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id={`From_Amount_${index}`}
                                name={`From_Amount_${index}`}
                                className="form-control"
                                placeholder="Enter From Amount"
                                value={customer.From_Amount}
                                required={true}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  const decimalInput = input.replace(/[^0-9.]/g, "");
                                  const amount = decimalInput.replace(
                                    /^(\d*\.\d*)\..*$/,
                                    "$1"
                                  );
                                  handleInputChange(index, 'From_Amount', amount)
                                  }}
                               
                              />
                            </div>
                                </div>*/}
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor={`To_Amount_${index}`} className="form-label">
                                Slab Amount<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id={`To_Amount_${index}`}
                                name={`To_Amount_${index}`}
                                className="form-control"
                                placeholder="Enter Slab Amount"
                                value={customer.To_Amount}
                                required={true}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  const decimalInput = input.replace(/[^0-9.]/g, "");
                                  const amount = decimalInput.replace(
                                    /^(\d*\.\d*)\..*$/,
                                    "$1"
                                  );
                                  handleInputChange(index, 'To_Amount', amount)
                                  }}
                               
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" htmlFor={`Interest${index}`}>
                                Rate Of Int.<span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                type="text"
                                name={`Interest${index}`}
                                required={true}
                                options={rateOption}
                                value={customer.Rate_Of_Int}
                                onChange={(selectedOption) => handleInputChange(index, 'Interest', selectedOption.value)}
                                id={`Interest${index}`}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            {slabwiseCustomers.length > 1 && (
                              <Tooltip title="Remove">
                                <RemoveCircleOutlineIcon
                                  onClick={() => handleRemove(index)}
                                  style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    top: "36px",
                                    left: "30px",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                      <div
                        className="row"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="col-md-2">
                          <Tooltip title="Add">
                            <AddCircleOutlineIcon
                              style={{
                                cursor: "pointer",
                                position: "relative",
                                top: "-50px",
                              }}
                              onClick={handleReplicate}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>)}
                    <p style={{ color: "red" }}>{errors.sumMismatch}</p>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Remarks
                        </label>
                        <textarea
                          type="text"
                          id="exampleFormControlTextarea1"
                          className="form-control"
                          placeholder="Enter Remarks"
                          rows="2"
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Remarks: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-user-shield mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Personal Details
                  </h5>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="First_Name" className="form-label">
                          First Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="First_Name"
                          name="First_Name"
                          className="form-control "
                          placeholder="Enter First Name"
                          required
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              First_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Middle_Name" className="form-label">
                          Middle Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="Middle_Name"
                          name="Middle_Name"
                          className="form-control "
                          placeholder="Enter Middle Name"
                          required
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Middle_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Last_Name" className="form-label">
                          Last Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="Last_Name"
                          name="Last_Name"
                          className="form-control "
                          placeholder="Enter Last Name"
                          required
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Last_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="DOB" className="form-label">
                          DOB<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          id="DOB"
                          name="DOB"
                          required
                          dateFormat="dd-MM-yyyy"
                          className="form-control "
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              DOB: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Gender" className="form-label">
                          Gender<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          id="Gender"
                          name="Gender"
                          required
                          options={Gender}
                          value={selectedGender}
                          onChange={setSelectedGender}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Marital_Status" className="form-label">
                          Marital Status<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          id="Marital_Status"
                          name="Marital_Status"
                          required
                          options={Marital_Status}
                          value={selectedMaritalStatus}
                          onChange={setSelectedMaritalStatus}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Mobile" className="form-label">
                          Mobile<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          maxLength={10}
                          value={customer.Mobile}
                          id="Mobile"
                          name="Mobile"
                          className={`form-control ${isValidMobile ? "" : "is-invalid"
                            }`}
                          placeholder="Enter Mobile Number"
                          required
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            const isValid = /^[6-9][0-9]{0,9}$/.test(
                              numericInput
                            );
                            setIsValidMobile(isValid);
                            if (isValid || numericInput === "") {
                              setCustomer({
                                ...customer,
                                Mobile: numericInput,
                              });
                            }
                          }}
                        />

                        {!isValidMobile && (
                          <div className="invalid-feedback">
                            Invalid mobile number.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Pan_No">
                          Pan No<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="Pan_No"
                          className="form-control  "
                          id="Pan_No"
                          required
                          placeholder="Enter Pan No"
                          value={customer.Pan_No}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Pan_No: e.target.value.toUpperCase(),
                            })
                          }
                          maxLength={10}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Aadhar_No">
                          Aadhar No<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="Aadhar_No"
                          className="form-control  "
                          id="Aadhar_No"
                          required
                          placeholder="Enter Aadhar No"
                          value={customer.Aadhar_No}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            setCustomer({
                              ...customer,
                              Aadhar_No: numericInput,
                            });
                          }}
                          maxLength={12}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        referenceType && referenceType.value === "Reference"
                          ? "col-md-3"
                          : "col-md-4"
                      }
                    >
                      <div className="mb-3">
                        <label htmlFor="Email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          id="Email"
                          name="Email"
                          className="form-control "
                          placeholder="Enter Email "
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Email: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={
                        referenceType && referenceType.value === "Reference"
                          ? "col-md-3"
                          : "col-md-4"
                      }
                    >
                      <div className="mb-3">
                        <label htmlFor="Reference_Type" className="form-label">
                          Reference Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="Reference_Type"
                          id="Reference_Type"
                          options={ReferenceOption}
                          value={referenceType}
                          onChange={setReferenceType}
                          required
                        />
                      </div>
                    </div>
                    {referenceType && referenceType.value === "Reference" && (
                      <div
                        className={
                          referenceType && referenceType.value === "Reference"
                            ? "col-md-3"
                            : "col-md-4"
                        }
                      >
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Reference">
                            Reference<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            name="Reference"
                            className="form-control  "
                            id="Reference"
                            required={referenceType.value === "Reference"}
                            placeholder="Enter Reference"
                            value={customer.Reference}
                            onChange={(e) =>
                              setCustomer({
                                ...customer,
                                Reference: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        referenceType && referenceType.value === "Reference"
                          ? "col-md-3"
                          : "col-md-4"
                      }
                    >
                      <div className="mb-3">
                        <label htmlFor="Student" className="form-label">
                          Student<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="Student"
                          id="Student"
                          options={StudentOption}
                          value={IsStudent}
                          onChange={setIsStudent}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="Address" className="form-label">
                          Address<span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          type="text"
                          id="Address"
                          required
                          name="Address"
                          className="form-control"
                          placeholder="Enter Address"
                          rows="2"
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Address: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-university mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Bank Details
                  </h5>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Bank_Name" className="form-label">
                          Bank Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="Bank_Name"
                          id="Bank_Name"
                          placeholder="Select Bank Name"
                          options={Bank}
                          value={selectedBank}
                          onChange={setSelectedBank}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="IFCE_Code" className="form-label">
                          IFSC Code<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="IFCE_Code"
                          name="IFCE_Code"
                          className="form-control "
                          placeholder="Enter IFSC Code"
                          required
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              IFCE_Code: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Account_No" className="form-label">
                          Account No<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="Account_No"
                          name="Account_No"
                          className="form-control "
                          placeholder="Enter Account No"
                          required
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Account_No: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          htmlFor="Account_Holder_Name"
                          className="form-label"
                        >
                          Account Holder Name
                        </label>
                        <input
                          type="text"
                          id="Account_Holder_Name"
                          name="Account_Holder_Name"
                          className="form-control "
                          placeholder="Enter Account Holder Name "
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Account_Holder_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fas fa-globe mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Document
                  </h5>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Photo">
                          Photo
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Photo"
                            name="Photo"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setAplicantPhotoFileName(fileName);

                              setCustomer({
                                ...customer,
                                Photo: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Photo"
                            style={{ overflow: "hidden" }}
                          >
                            {aplicantPhotoFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          ID Proof Type
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          options={IdProof}
                          value={selectedIdProof}
                          onChange={setSelectedIdProof}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Id_Proof_Attachment"
                        >
                          ID Proof Attachment
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Id_Proof_Attachment"
                            name="Id_Proof_Attachment"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setIdProofFileName(fileName);

                              setCustomer({
                                ...customer,
                                Id_Proof_Attachment: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Id_Proof_Attachment"
                            style={{ overflow: "hidden" }}
                          >
                            {idProofFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          Address Proof Type
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          options={AddressProof}
                          value={selectedAddressProof}
                          onChange={setSelectedAddressProof}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Address_Proof_Attachment"
                        >
                          Address Proof Attachment
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Address_Proof_Attachment"
                            name="Address_Proof_Attachment"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setAddressProofFileName(fileName);

                              setCustomer({
                                ...customer,
                                Address_Proof_Attachment: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Address_Proof_Attachment"
                            style={{ overflow: "hidden" }}
                          >
                            {addressProofFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-user-tag mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Co-Applicant
                  </h5>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <div className="custom-control custom-checkbox ml-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="Co_applicant"
                            checked={customer.Co_applicant}
                            //value={customer.Co_applicant}
                            onChange={(e) =>
                              setCustomer({
                                ...customer,
                                Co_applicant: e.target.checked,
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="Co_applicant"
                          >
                            Co-Applicant
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {customer.Co_applicant && customer.Co_applicant === true && (
                    <div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              htmlFor="Co_applicant_Full_Name"
                              className="form-label"
                            >
                              Full Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="Co_applicant_Full_Name"
                              name="Co_applicant_Full_Name"
                              className="form-control "
                              placeholder="Enter Full Name"
                              required={customer.Co_applicant === true}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Co_applicant_Full_Name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              htmlFor="Co_applicant_DOB"
                              className="form-label"
                            >
                              DOB<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="date"
                              id="Co_applicant_DOB"
                              name="Co_applicant_DOB"
                              className="form-control"
                              dateFormat="dd-MM-yyyy"
                              required={customer.Co_applicant === true}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Co_applicant_DOB: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              htmlFor="Co_applicant_Relationship"
                              className="form-label"
                            >
                              Relationship
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="Co_applicant_Relationship"
                              name="Co_applicant_Relationship"
                              className="form-control "
                              placeholder="Enter Relationship "
                              required={customer.Co_applicant === true}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Co_applicant_Relationship: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="Co_applicant_Photo"
                            >
                              Photo
                            </label>
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="Co_applicant_Photo"
                                name="Co_applicant_Photo"
                                accept=".jpg, .jpeg, .png"
                                onChange={(e) => {
                                  const fileName =
                                    e.target.files[0]?.name || "Choose file";
                                  setCoaplicantPhotoFileName(fileName);

                                  setCustomer({
                                    ...customer,
                                    Co_applicant_Photo: e.target.files[0],
                                  });
                                }}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="Co_applicant_Photo"
                                style={{ overflow: "hidden" }}
                              >
                                {coaplicantPhotoFileName}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="Co_applicant_Birth_Proof"
                            >
                              DOB Proof
                            </label>
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="Co_applicant_Birth_Proof"
                                name="Co_applicant_Birth_Proof"
                                accept=".jpg, .jpeg, .png, .pdf"
                                onChange={(e) => {
                                  const fileName =
                                    e.target.files[0]?.name || "Choose file";
                                  setCoaplicantDOBProofFileName(fileName);

                                  setCustomer({
                                    ...customer,
                                    Co_applicant_Birth_Proof: e.target.files[0],
                                  });
                                }}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="Co_applicant_Birth_Proof"
                                style={{ overflow: "hidden" }}
                              >
                                {coaplicantDOBProofFileName}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              htmlFor="Co_applicant_Occupation"
                              className="form-label"
                            >
                              Occupation
                            </label>
                            <input
                              type="text"
                              id="Co_applicant_Occupation"
                              name="Co_applicant_Occupation"
                              className="form-control "
                              placeholder="Enter Occupation "
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Co_applicant_Occupation: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              htmlFor="Co_applicant_Address"
                              className="form-label"
                            >
                              Address
                            </label>
                            <textarea
                              type="text"
                              id="Co_applicant_Address"
                              name="Co_applicant_Address"
                              className="form-control"
                              placeholder="Enter Address"
                              rows="2"
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Co_applicant_Address: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-user-clock mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Nominee
                  </h5>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="Nominee_Full_Name"
                          className="form-label"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          id="Nominee_Full_Name"
                          name="Nominee_Full_Name"
                          className="form-control "
                          placeholder="Enter First Name"
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Nominee_Full_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Nominee_DOB" className="form-label">
                          DOB
                        </label>
                        <input
                          type="date"
                          id="Nominee_DOB"
                          name="Nominee_DOB"
                          className="form-control "
                          dateFormat="dd-MM-yyyy"
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Nominee_DOB: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Nominee_Photo">
                          Photo
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Nominee_Photo"
                            name="Nominee_Photo"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setNomineePhotoFileName(fileName);

                              setCustomer({
                                ...customer,
                                Nominee_Photo: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Nominee_Photo"
                            style={{ overflow: "hidden" }}
                          >
                            {nomineePhotoFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Nominee_Address_Proof">
                          Nominee Address Proof
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Nominee_Address_Proof"
                            name="Nominee_Address_Proof"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setNomineeAddressProofFileName(fileName);

                              setCustomer({
                                ...customer,
                                Nominee_Address_Proof: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Nominee_Address_Proof"
                            style={{ overflow: "hidden" }}
                          >
                            {nomineeAddressProofFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Nominee_Birth_Proof"
                        >
                          DOB Proof
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Nominee_Birth_Proof"
                            name="Nominee_Birth_Proof"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setNomineeDOBProofFileName(fileName);

                              setCustomer({
                                ...customer,
                                Nominee_Birth_Proof: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Nominee_Birth_Proof"
                            style={{ overflow: "hidden" }}
                          >
                            {nomineeDOBProofFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Nominee_Sign_Attachment"
                        >
                          Sign Attachment
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Nominee_Sign_Attachment"
                            name="Nominee_Sign_Attachment"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setNomineeSignFileName(fileName);

                              setCustomer({
                                ...customer,
                                Nominee_Sign_Attachment: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Nominee_Sign_Attachment"
                            style={{ overflow: "hidden" }}
                          >
                            {nomineeSignFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary mb-1"
                      ref={buttonElement}
                      type="submit"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      {IsPreview && (
        <CustomerPreview
          open={IsPreview}
          close={(status) => closed(status)}
          data={updateCustomer}
        />
      )}
      {loader}
    </div>
  );
};

export default AddCustomer;
