
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
export const Success = (text) => {
    debugger; 
    Swal.fire({
        title: 'Success',
        text: text,
        icon: 'success',
        confirmButtonColor: '#089d15',
        confirmButtonText: 'Ok',
      })
}
export const error = (text) => {
    debugger; 
    Swal.fire({
        title: 'error',
        text: text,
        icon: 'error',
        confirmButtonColor: '#ff0000',
        confirmButtonText: 'Ok',
      })
}
export const Confirmation = (text) => {
    return Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      console.log(result.isConfirmed);
      return result.isConfirmed;
    });
  };
