import React, { useState, useEffect } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { Paper, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GridViewIcon from "@mui/icons-material/GridView";
import { Link } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { apiUrl } from "../../componets/apiConfig";
import { useParams } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { useUser } from "../../componets/UserContext";
import { formatDate, formatInputDate } from "../../mui/dateFormate"; 
import Swal from "sweetalert2";
import AgreementPreview from "./AgreementPreview"

const Agreement = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [errors, setErrors] = useState({});
    const [IsPreview, setIsPreview] = useState(false);



    // const [customer, setCustomer] = useState({
    //     Date: new Date(),
    //     Location: "New York",
    //     Lender_PreName: "Mr.",
    //     Lender_Name: "John Doe",
    //     Lender_Address: "1234 Elm Street, New York, NY, 10001",
    //     Borrower_PreName: "Mr.",
    //     Borrower_Name: "Pravin Mahadev Dhuri",
    //     Borrower_Address: "Flat No. 503, C Wing, Sarvoday Pooja Chs, 5 Th Floor, Barave Road, Near Ayush Hospital, Khadakpada, Kalyan (W) Thane, Maharashtra 421301",
    //     Loan_Amount_txt: "Fifty Thousand Dollars",
    //     Loan_Amount_num: 50000,
    //     Loan_Start_Date: "2024-01-01",
    //     Loan_End_Date: "2025-01-01",
    //     Mode_of_Payment: "Bank Transfer",
    //     Loan_Disbursal_Date: "2024-01-05",
    //     Transaction_ID: "TRX123456789",
    //     Bank: "Bank of America",
    //     Total_Loan_Amount: 50000,
    //     Received_Date: '2024-01-01',
    //     Tenure: "Six months",
    //     KYC_Date: "2024-01-01",
    //     KYC_Time: "10:00 AM",
    //     Lender_Mobile_No: "123-456-7890",
    //     Lender_Registered_Mobile_No: "123-456-7890",
    //     Lender_Email_ID: "johndoe@example.com",
    //     Borrower_Email_ID: "johndoe@example.com",
    //     LockIn_Period_From_Date: "2024-01-01",
    //     LockIn_Period_To_Date: "2025-01-01",
    //     // 2.2.5 Lender's Bank Details for Pay-in
    //     PayIn_Bank_Account_Holder_PreName: "Mr.",
    //     PayIn_Bank_Account_Holder_Name: "John Doe",
    //     PayIn_Bank_Name: "Bank of America",
    //     PayIn_Account_Number: "1234567890",
    //     PayIn_IFSC_Code: "BOFAUS3NXXX",
    //     PayIn_Branch: "New York Main Branch",
    //     PayIn_Account_Holder_Type: "Individual",
    //     PayIn_Account_Type: "Savings",
    //     // 2.2.6 Lender's Bank Details for Payout
    //     Payout_Bank_Account_Holder_PreName:"Mr.",
    //     Payout_Bank_Account_Holder_Name: "John Doe",
    //     Payout_Bank_Name: "Bank of America",
    //     Payout_Account_Number: "0987654321",
    //     Payout_IFSC_Code: "BOFAUS3NXXX",
    //     Payout_Branch: "New York Branch",
    //     Payout_Account_Holder_Type: "Individual",
    //     Payout_Account_Type: "Savings",
    //     // 2.2.7 Nominee Details
    //     Nominee1_PreName: "Mr.",
    //     Nominee1_Name: "Jane Doe",
    //    // Nominee_Name_2: "Jack Doe",
    //     Nominee1_Address: "ABC",
    //     Nomination_Gender: "His",
    //     Nominee_Loan_Amount: "50000",
    //     Nomination_Date: "2024-02-01",
    //     Nominee_DOB: "1985-06-15",
    //     Nominee_Relation: "Spouse",
    //     // 2.2.8 Declaration
    //     Declaration_Lender_Name: "John Doe",
    //     Declaration_Lender_Address: "1234 Elm Street, New York, NY, 10001",
    //     Declaration_Loan_Amount_Num: 50000,
    //     Declaration_Loan_Amount_Words: "Fifty Thousand Dollars",
    //     Declaration_Nominee_Name: "Jane Doe",
    //     Declaration_Nominee_Address: "5678 Oak Avenue, New York, NY, 10002",
    //     Declaration_Witness_PreName: "Mr.",
    //     Declaration_Witness_Name: "Michael Smith",
    //     Declaration_Witness_Address: "7890 Pine Road, New York, NY, 10003"

    // });

    const [customer, setCustomer] = useState({
        Date: new Date(),
        Location: "",
        Lender_PreName: "Mr.",
        Lender_Name: "",
        Lender_Address: "",
        Lender_Mobile_No: "",
        Lender_Registered_Mobile_No: "",
        Lender_Email_ID: "",
        Borrower_PreName: "Mr.",
        Borrower_Name: "",
        Borrower_Address: "",
        Borrower_Email_ID: "",
        Borrower_Mobile: "",
        Loan_Amount_txt: "",
        Loan_Amount_num: "",
        Loan_Start_Date: "",
        Loan_End_Date: "",
        Mode_of_Payment: "",
        Loan_Disbursal_Date: "",
        Transaction_ID: "",
        Bank: "",
        Total_Loan_Amount: "",
        Received_Date: "",
        Tenure: "Six months",
        KYC_Date: "",
        KYC_Time: "",

        LockIn_Period_From_Date: "",
        LockIn_Period_To_Date: "",
        PayIn_Bank_Account_Holder_PreName: "Mr.",
        PayIn_Bank_Account_Holder_Name: "",
        PayIn_Bank_Name: "",
        PayIn_Account_Number: "",
        PayIn_IFSC_Code: "",
        PayIn_Branch: "",
        PayIn_Account_Holder_Type: "",
        PayIn_Account_Type: "",

        Payout_Bank_Account_Holder_PreName: "Mr.",
        Payout_Bank_Account_Holder_Name: "",
        Payout_Bank_Name: "",
        Payout_Account_Number: "",
        Payout_IFSC_Code: "",
        Payout_Branch: "",
        Payout_Account_Holder_Type: "",
        Payout_Account_Type: "",

        Nominee1_PreName: "Mr.",
        Nominee1_Name: "",
        Nominee1_Address: "",
        Nomination_Gender: "his",
        Nominee_Loan_Amount: "",
        Nomination_Date: "",
        Nominee_DOB: "",
        Nominee_Relation: "",

        Declaration_Witness_PreName: "Mr.",
        Declaration_Witness_Name: "",
        Declaration_Witness_Address: ""
    });


    const generateTaxInvoiceAndSavePDF = async (invoice) => {
        const element = document.getElementById("printablediv");
        const opt = {
            margin: 10,
            filename: "TaxInvoice.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        try {
            const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");

            const whatsapp = {
                Customer: invoice?.Customer_Name,
                Certificate: invoice?.Certificate_Name,
                number: invoice?.Mobile,
                Email: invoice?.Email,
            };
            //console.log(whatsapp);
            showLoader();

            const formData = new FormData();
            formData.append("pdf", pdfBlob, "TaxInvoice.pdf");

            const response = await axios.post(`/saveAgreementDoc`, formData);
            hideLoader();
           
          
        } catch (error) {
            // Handle any errors
            console.error("Error saving PDF:", error);
            hideLoader();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            [name]: value,
        }));
    };

      const handleSubmit = async () => {



    // const formdata = new FormData();
    // formdata.append("Agreement_Date", customer.Agreement_Date);
    // formdata.append("Receiver_Name", customer.Receiver_Name);
    // formdata.append("DropEmpId", customer.DropEmpId);
    // formdata.append("Emp_Name", customer.Emp_Name);
    // formdata.append("Attachment", customer.Attachment);
    // formdata.append("File_No", customer.File_No);
    // formdata.append("File_Location", customer.File_Location);
    // formdata.append("Postal_Address", customer.Postal_Address);
    // formdata.append("Postal_Receipt", customer.Postal_Receipt);
    // formdata.append("Postal_Compony", customer.Postal_Compony);
    // formdata.append("AWB_No", customer.AWB_No);
    // formdata.append("Agreement_Remarks", customer.Agreement_Remarks);
    // formdata.append("User_Role", customer.User_Role);
    // formdata.append("Emp_Id", customer.Emp_Id);
    // formdata.append("Added_By_Id", customer.Added_By_Id);
    // formdata.append("Added_By", customer.Added_By);
    // formdata.append("UserName", customer.UserName);
    // formdata.append("Enrollment_No", customer.Enrollment_No);
    // formdata.append("Start_Date", customer.Start_Date);
    // formdata.append("Investment_Date", customer.Investment_Date);
    // if (customer.Branch_Name) {
    //   formdata.append("Branch_Name", customer.Branch_Name);
    // }
    // if (customer.Cheque_No) {
    //   formdata.append("Cheque_No", customer.Cheque_No);
    // }
    // if (customer.Cheque_Date) {
    //   formdata.append("Cheque_Date", customer.Cheque_Date);
    // }
    // if (customer.Reference_No) {
    //   formdata.append("Reference_No", customer.Reference_No);
    // }
    // formdata.append("First_Name", customer.First_Name);
    // formdata.append("Middle_Name", customer.Middle_Name);
    // formdata.append("Last_Name", customer.Last_Name);
    // formdata.append("Remarks", customer.Remarks);
    // formdata.append("DOB", customer.DOB);
    // formdata.append("Mobile", customer.Mobile);
    // formdata.append("Email", customer.Email);
    // formdata.append("Pan_No", customer.Pan_No);
    // formdata.append("Aadhar_No", customer.Aadhar_No);
    // formdata.append("Reference_Type", customer.Reference_Type);
    // formdata.append("Reference", customer.Reference);
    // formdata.append("Address", customer.Address);
    // formdata.append("IFCE_Code", customer.IFCE_Code);
    // formdata.append("Account_No", customer.Account_No);
    // formdata.append("Account_Holder_Name", customer.Account_Holder_Name);
    // formdata.append("Co_applicant", customer.Co_applicant);
    // formdata.append("Co_applicant_Full_Name", customer.Co_applicant_Full_Name);
    // formdata.append("Payment_Doc", customer.Payment_Doc);
    // formdata.append("Co_applicant_DOB", customer.Co_applicant_DOB);
    // formdata.append("Co_applicant_Photo", customer.Co_applicant_Photo);
    // formdata.append(
    //   "Co_applicant_Birth_Proof",
    //   customer.Co_applicant_Birth_Proof
    // );
    // formdata.append(
    //   "Co_applicant_Occupation",
    //   customer.Co_applicant_Occupation
    // );
    // formdata.append(
    //   "Co_applicant_Relationship",
    //   customer.Co_applicant_Relationship
    // );
    // formdata.append("Co_applicant_Address", customer.Co_applicant_Address);
    // formdata.append("Photo", customer.Photo);
    // formdata.append("Id_Proof_Type", customer.Id_Proof_Type);
    // formdata.append("Id_Proof_Attachment", customer.Id_Proof_Attachment);
    // formdata.append("Address_Proof_Type", customer.Address_Proof_Type);
    // formdata.append(
    //   "Address_Proof_Attachment",
    //   customer.Address_Proof_Attachment
    // );
    // formdata.append("Nominee_Full_Name", customer.Nominee_Full_Name);
    // formdata.append("Nominee_DOB", customer.Nominee_DOB);
    // formdata.append(
    //   "Nominee_Sign_Attachment",
    //   customer.Nominee_Sign_Attachment
    // );
    // formdata.append("Nominee_Birth_Proof", customer.Nominee_Birth_Proof);
    // formdata.append("Nominee_Photo", customer.Nominee_Photo);
    // formdata.append("Mode_Of_Investment", customer.Mode_Of_Investment);
    // formdata.append("Capital_Amount", customer.Capital_Amount);
    // formdata.append("Percentage_Type_Id", customer.Percentage_Type_Id);
    // formdata.append("Percentage_Type", customer.Percentage_Type);
    // formdata.append("Investment_Period", customer.Investment_Period);
    // formdata.append(
    //   "Investment_Period_Value",
    //   customer.Investment_Period_Value
    // );
    // formdata.append("Bank_Name", customer.Bank_Name);
    // if (customer.Bank) {
    //   formdata.append("Bank", customer.Bank);
    // }
    // formdata.append("Investment_Type_Id", customer.Investment_Type_Id);
    // formdata.append("Investment_Type", customer.Investment_Type);
    // formdata.append("Gender", customer.Gender);
    // formdata.append("Student", customer.Student);
    // formdata.append("Marital_Status", customer.Marital_Status);
    // formdata.append(
    //   "Updated_By_Employee_Id",
    //   customer.Updated_By_Employee_Id
    // );
    // formdata.append("Rate_Of_Int", customer.Rate_Of_Int);
    // formdata.append("Updated_By_Id", customer.Updated_By_Id);
    // formdata.append("Updated_By", customer.Updated_By);
    // formdata.append("Org_Id", customer.Org_Id);


    const validationErrors = {};

    if (Object.keys(validationErrors).length === 0) {
      showLoader();

      axios
          .post(`${apiUrl}/addLoanAgreement`, customer)
        .then((res) => {
          //Swal.fire("Success!", "Customer has been Updated.", "success");
          //navigate("/viewCustomer");
          //handleCancelEdit();
          //LoadCategory();
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error",
            text: "Failed to Update. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });

          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };

    const showSummarry = async (event) => {
        event.preventDefault();
        const validationErrors = {};

        if (Object.keys(validationErrors).length === 0) {

            setIsPreview(true);
        } else {
            setErrors(validationErrors);
        }
    };
    
    const closed = (status) => {
        if (status) {
            handleSubmit();
        }
        setIsPreview(false);
    };
    return (
        <div className="container-fluid">
            <h5 className="page-title">Fresh Agreement</h5>
            <div className="card">
                
                <div className="card-body" style={{ paddingTop: "5px" }}>
                    <div>

                        <form onSubmit={showSummarry}>
                            <div className="row">
                                {/* Date */}
                                <div className="col-md-3 mb-3">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        name="Date"
                                        required
                                        value={formatInputDate(customer.Date)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Location */}
                                <div className="col-md-9 mb-3">
                                    <label>Location</label>
                                    <input
                                        type="text"
                                        name="Location"
                                        required
                                        value={customer.Location}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Lender Details */}
                                <h6 className="col-md-12"><strong>Lender Details: </strong></h6>

                                <div className="col-md-3 mb-3">
                                    <label>Lender Initial </label>
                                    <select
                                        name="Lender_PreName"
                                        value={customer.Lender_PreName}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Lender Name</label>
                                    <input
                                        type="text"
                                        name="Lender_Name"
                                        required
                                        value={customer.Lender_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Lender Address</label>
                                    <input
                                        name="Lender_Address"
                                        required
                                        value={customer.Lender_Address}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>Lender Mobile Number</label>
                                    <input
                                        type="text"
                                        required
                                        name="Lender_Registered_Mobile_No"
                                        value={customer.Lender_Registered_Mobile_No}
                                        onChange={handleChange}
                                        className="form-control"
                                        
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>Lender Alt Mobile Number</label>
                                    <input
                                        type="text"
                                        name="Lender_Mobile_No"
                                        required
                                        value={customer.Lender_Mobile_No}
                                        onChange={handleChange}
                                        className="form-control"
                                       
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>Lender Email ID</label>
                                    <input
                                        type="email"
                                        name="Lender_Email_ID"
                                        required
                                        value={customer.Lender_Email_ID}
                                        onChange={handleChange}
                                        className="form-control"
                                       
                                    />
                                </div>


                                {/* Borrower Details */}
                                <h6 className="col-md-12"><strong>Borrower Details: </strong></h6>
                                <div className="col-md-3 mb-3">
                                    <label>Borrower Initial </label>
                                    <select
                                        name="Borrower_PreName"
                                        required
                                        value={customer.Borrower_PreName}
                                        onChange={handleChange}
                                        className="form-control"
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label>Borrower Name</label>
                                    <input
                                        type="text"
                                        name="Borrower_Name"
                                        required
                                        value={customer.Borrower_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label>Borrower Mobile Number</label>
                                    <input
                                        type="text"
                                        required
                                        name="Borrower_Mobile"
                                        value={customer.Borrower_Mobile}
                                        onChange={handleChange}
                                        className="form-control"

                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label>Borrower Email ID</label>
                                    <input
                                        type="email"
                                        name="Borrower_Email_ID"
                                        required
                                        value={customer.Borrower_Email_ID}
                                        onChange={handleChange}
                                        className="form-control"

                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label>Borrower Address</label>
                                    <input
                                        name="Borrower_Address"
                                        required
                                        value={customer.Borrower_Address}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Loan Amount */}
                                <h6 className="col-md-12"><strong>Agreement Details: </strong></h6>
                                <div className="col-md-3 mb-3">
                                    <label>Loan Amount (Number)</label>
                                    <input
                                        type="text"
                                        name="Loan_Amount_num"
                                        required
                                        value={customer?.Loan_Amount_num}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const decimalInput = input.replace(/[^0-9.]/g, "");
                                            const amount = decimalInput.replace(
                                                /^(\d*\.\d*)\..*$/,
                                                "$1"
                                            );
                                            setCustomer({
                                                ...customer,
                                                Loan_Amount_num: amount,
                                            });

                                        }}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Loan Amount (Text)</label>
                                    <input
                                        type="text"
                                        required
                                        name="Loan_Amount_txt"
                                        value={customer.Loan_Amount_txt}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                              
                                {/* Loan Dates */}
                                <div className="col-md-3 mb-3">
                                    <label>Loan Start Date</label>
                                    <input
                                        type="date"
                                        required
                                        name="Loan_Start_Date"
                                        value={formatInputDate(customer.Loan_Start_Date)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Loan End Date</label>
                                    <input
                                        type="date"
                                        required
                                        name="Loan_End_Date"
                                        value={formatInputDate(customer.Loan_End_Date)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                
                                {/* Mode of Payment */}
                                <div className="col-md-3 mb-3">
                                    <label>Mode of Payment</label>
                                    <input
                                        type="text"
                                        required
                                        name="Mode_of_Payment"
                                        value={customer.Mode_of_Payment}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Loan Disbursal Date</label>
                                    <input
                                        type="date"
                                        required
                                        name="Loan_Disbursal_Date"
                                        value={customer.Loan_Disbursal_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Transaction ID */}
                                <div className="col-md-3 mb-3">
                                    <label>Transaction ID</label>
                                    <input
                                        type="text"
                                        name="Transaction_ID"
                                        required
                                        value={customer.Transaction_ID}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Bank */}
                                <div className="col-md-3 mb-3">
                                    <label>Bank</label>
                                    <input
                                        type="text"
                                        name="Bank"
                                        required
                                        value={customer.Bank}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                           
                                <div className="col-md-3 mb-3">
                                    <label>Total Loan Amount</label>
                                    <input
                                        type="text"
                                        name="Total_Loan_Amount"
                                        required
                                        value={customer.Total_Loan_Amount}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const decimalInput = input.replace(/[^0-9.]/g, "");
                                            const amount = decimalInput.replace(
                                                /^(\d*\.\d*)\..*$/,
                                                "$1"
                                            );
                                            setCustomer({
                                                ...customer,
                                                Total_Loan_Amount: amount,
                                            });

                                        }}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label>Received Date</label>
                                    <input
                                        type="date"
                                        name="Received_Date"
                                        required
                                        value={customer.Received_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                {/* Tenure */}
                                <div className="col-md-3 mb-3">
                                    <label>Tenure</label>
                                    <select
                                        className="form-control"
                                        name="Tenure"
                                        required
                                        value={customer.Tenure}
                                        onChange={handleChange}
                                  
                                    >
                                        <option value="Six months">Six months</option>
                                        <option value="One Year">One Year</option>
                                    </select>
                                </div>

                                {/* KYC Date & Time */}
                                <div className="col-md-3 mb-3">
                                    <label>KYC Date</label>
                                    <input
                                        type="date"
                                        name="KYC_Date"
                                        required
                                        value={customer.KYC_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>KYC Time</label>
                                    <input
                                        type="time"
                                        name="KYC_Time"
                                        required
                                        value={customer.KYC_Time}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                {/* Lock-in Period From Date */}
                                <div className="col-md-4 mb-3">
                                    <label>Lock-In Period From Date</label>
                                    <input
                                        type="date"
                                        name="LockIn_Period_From_Date"
                                        required
                                        value={customer.LockIn_Period_From_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Lock-in Period To Date */}
                                <div className="col-md-4 mb-3">
                                    <label>Lock-In Period To Date</label>
                                    <input
                                        type="date"
                                        name="LockIn_Period_To_Date"
                                        required
                                        value={customer.LockIn_Period_To_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                <h6 className="col-md-12"><strong>Pay-In Details: </strong></h6>

                                {/* PayIn Bank Account Holder Initial  */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Initial </label>
                                    <select
                                        name="PayIn_Bank_Account_Holder_PreName"
                                        value={customer.PayIn_Bank_Account_Holder_PreName}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options as needed */}
                                    </select>
                                </div>

                                {/* PayIn Bank Account Holder Name */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Name</label>
                                    <input
                                        type="text"
                                        name="PayIn_Bank_Account_Holder_Name"
                                        required
                                        value={customer.PayIn_Bank_Account_Holder_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                        ///placeholder="Enter account holder name"
                                    />
                                </div>

                                {/* PayIn Bank Name */}
                                <div className="col-md-3 mb-3">
                                    <label>Bank Name</label>
                                    <input
                                        type="text"
                                        name="PayIn_Bank_Name"
                                        value={customer.PayIn_Bank_Name}
                                        onChange={handleChange} required
                                        className="form-control"
                                       // placeholder="Enter bank name"
                                    />
                                </div>

                                {/* PayIn Account Number */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Number</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Number"
                                        value={customer.PayIn_Account_Number}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        //placeholder="Enter account number"
                                    />
                                </div>

                                {/* PayIn IFSC Code */}
                                <div className="col-md-3 mb-3">
                                    <label>IFSC Code</label>
                                    <input
                                        type="text"
                                        name="PayIn_IFSC_Code"
                                        value={customer.PayIn_IFSC_Code}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        //placeholder="Enter IFSC code"
                                    />
                                </div>

                                {/* PayIn Branch */}
                                <div className="col-md-3 mb-3">
                                    <label>Branch</label>
                                    <input
                                        type="text"
                                        name="PayIn_Branch"
                                        value={customer.PayIn_Branch}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                        //placeholder="Enter branch name"
                                    />
                                </div>

                                {/* PayIn Account Holder Type */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Type</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Holder_Type"
                                        value={customer.PayIn_Account_Holder_Type}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        //placeholder="Enter account holder type"
                                    />
                                </div>

                                {/* PayIn Account Type */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Type</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Type"
                                        value={customer.PayIn_Account_Type}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                        //placeholder="Enter account type"
                                    />
                                </div>

                                <h6 className="col-md-12"><strong>Payout Details: </strong></h6>

                                {/* Payout Bank Account Holder Initial  */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Initial </label>
                                    <select
                                        name="Payout_Bank_Account_Holder_PreName"
                                        value={customer.Payout_Bank_Account_Holder_PreName}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>

                                {/* Payout Bank Account Holder Name */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Name</label>
                                    <input
                                        type="text"
                                        name="Payout_Bank_Account_Holder_Name"
                                        required
                                        value={customer.Payout_Bank_Account_Holder_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                       // placeholder="Enter account holder name"
                                    />
                                </div>

                                {/* Payout Bank Name */}
                                <div className="col-md-3 mb-3">
                                    <label>Bank Name</label>
                                    <input
                                        type="text"
                                        name="Payout_Bank_Name"
                                        required
                                        value={customer.Payout_Bank_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                        //placeholder="Enter bank name"
                                    />
                                </div>

                                {/* Payout Account Number */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Number</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Number"
                                        required
                                        value={customer.Payout_Account_Number}
                                        onChange={handleChange}
                                        className="form-control"
                                       // placeholder="Enter account number"
                                    />
                                </div>

                                {/* Payout IFSC Code */}
                                <div className="col-md-3 mb-3">
                                    <label>IFSC Code</label>
                                    <input
                                        type="text"
                                        name="Payout_IFSC_Code"
                                        value={customer.Payout_IFSC_Code}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        //placeholder="Enter IFSC code"
                                    />
                                </div>

                                {/* Payout Branch */}
                                <div className="col-md-3 mb-3">
                                    <label>Branch</label>
                                    <input
                                        type="text"
                                        name="Payout_Branch"
                                        value={customer.Payout_Branch}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        //placeholder="Enter branch name"
                                    />
                                </div>

                                {/* Payout Account Holder Type */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Type</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Holder_Type"
                                        value={customer.Payout_Account_Holder_Type}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                        //placeholder="Enter account holder type"
                                    />
                                </div>

                                {/* Payout Account Type */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Type</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Type"
                                        required
                                        value={customer.Payout_Account_Type}
                                        onChange={handleChange}
                                        className="form-control"
                                        //placeholder="Enter account type"
                                    />
                                </div>
                                <h6 className="col-md-12"><strong>Nominee Details: </strong></h6>

                                {/* Nominee Details */}
                                {/* Nominee 1 Initial  */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Initial </label>
                                    <select
                                        name="Nominee1_PreName"
                                        required
                                        value={customer.Nominee1_PreName}
                                        onChange={handleChange}
                                        className="form-control"
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>

                                {/* Nominee 1 Name */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Name</label>
                                    <input
                                        type="text"
                                        name="Nominee1_Name"
                                        value={customer.Nominee1_Name}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    />
                                </div>

                                {/* Nominee 1 Address */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Address</label>
                                    <input
                                        type="text"
                                        name="Nominee1_Address"
                                        required
                                        value={customer.Nominee1_Address}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Nomination Gender */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Gender</label>
                                    <select
                                        name="Nomination_Gender"
                                        value={customer.Nomination_Gender}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="his">Male</option>
                                        <option value="her">Female</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>


                                {/* Nominee Loan Amount */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Loan Amount</label>
                                    <input
                                        type="text"
                                        name="Nominee_Loan_Amount"
                                        required
                                        value={customer.Nominee_Loan_Amount}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const decimalInput = input.replace(/[^0-9.]/g, "");
                                            const amount = decimalInput.replace(
                                                /^(\d*\.\d*)\..*$/,
                                                "$1"
                                            );
                                            setCustomer({
                                                ...customer,
                                                Nominee_Loan_Amount: amount,
                                            });

                                        }}
                                        className="form-control"
                                    />
                                </div>

                                {/* Nomination Date */}
                                <div className="col-md-3 mb-3">
                                    <label>Nomination Date</label>
                                    <input
                                        type="date"
                                        name="Nomination_Date"
                                        required
                                        value={formatInputDate(customer.Nomination_Date)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Nominee Date of Birth */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee DOB</label>
                                    <input
                                        type="date"
                                        name="Nominee_DOB"
                                        required
                                        value={formatInputDate(customer.Nominee_DOB)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Nominee Relation */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Relation</label>
                                    <input
                                        type="text"
                                        name="Nominee_Relation"
                                        required
                                        value={customer.Nominee_Relation}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>


                                {/* Declaration */}
                       
                                <h6 className="col-md-12"><strong>Witness Details: </strong></h6>

                                <div className="col-md-3 mb-3">
                                    <label>Witness Initial </label>
                                    <select
                                        name="Declaration_Witness_PreName"
                                        value={customer.Declaration_Witness_PreName}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Witness Name</label>
                                    <input
                                        type="text"
                                        name="Declaration_Witness_Name"
                                        required
                                        value={customer.Declaration_Witness_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Witness Address</label>
                                    <input
                                        type="text"
                                        name="Declaration_Witness_Address"
                                        required
                                        value={customer.Declaration_Witness_Address}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                            </div>
                            <div className="row mt-4">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary mt-2" type="submit">
                                        Submit
                                    </button>
                            </div>
                            </div>

                        </form>
                      
                    </div>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                        onClick={handleGeneratePDF}
                    >
                        Download PDF
                    </Button> */}
                    {loader}

                    {IsPreview && (
                        <AgreementPreview 
                            open={IsPreview}
                            close={(status) => closed(status)}
                            customer={customer}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Agreement;
