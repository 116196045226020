import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { useUser } from "../../componets/UserContext";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import { apiUrl } from "../../componets/apiConfig";
import { error } from "../Alerts/alert";
import { useNavigate, useParams } from "react-router-dom";
import useButtonLoader from "../../mui/useButtonLoader";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DownloadIcon from "@mui/icons-material/Download";
import Visibility from "@mui/icons-material/Visibility";
import OTP from "./NewAccountOTP";
import { formatDate, formatInputDate } from "../../mui/dateFormate"; 

const Payment_Mode = [
  { label: "Online", value: "Online" },
  { label: "TPT", value: "TPT" },
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "IMPS", value: "IMPS" },
  { label: "GPAY", value: "GPAY" },
  { label: "PhonePay", value: "PhonePay" },
  { label: "PAYTM", value: "PAYTM" },
  { label: "DD", value: "DD" },
  { label: "NEFT", value: "NEFT" },
  { label: "RTGS", value: "RTGS" },
];

const Gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const Marital_Status = [
  { label: "Married", value: "Married" },
  { label: "Unmarried", value: "Unmarried" },
];

const StudentOption = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const ReferenceOption = [
  { label: "Direct", value: "Direct" },
  { label: "Reference", value: "Reference" },
];

function generateOTP() {
  const otp = Math.floor(1000 + Math.random() * 9000);
  return otp.toString();
}

const UpdateCustomer = () => {
  const [selectedPayMode, setSelectedPayMode] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [addressProofFileName, setAddressProofFileName] =
    useState("Choose file");
  const [idProofFileName, setIdProofFileName] = useState("Choose file");
  const [AttachmentFileName, setAttachmentFileName] = useState("Choose file");
  const [PostalReceiptFileName, setPostalReceiptFileName] = useState("Choose file");
  const [nomineeAddressProofFileName, setNomineeAddressProofFileName] =
    useState("Choose file");
  const [nomineeSignFileName, setNomineeSignFileName] = useState("Choose file");
  const [nomineePhotoFileName, setNomineePhotoFileName] =
    useState("Choose file");
  const [nomineeDOBProofFileName, setNomineeDOBProofFileName] =
    useState("Choose file");
  const [coaplicantPhotoFileName, setCoaplicantPhotoFileName] =
    useState("Choose file");
  const [coaplicantDOBProofFileName, setCoaplicantDOBProofFileName] =
    useState("Choose file");
  const [aplicantPhotoFileName, setAplicantPhotoFileName] =
    useState("Choose file");
  const [PaymentDocFileName, setPaymentDocFileName] = useState("Choose file");
  const [IsStudent, setIsStudent] = useState([]);
  const [referenceType, setReferenceType] = useState([]);
  const [PercentageType, setPercentageTypeOption] = useState([]);
  const [selectedPercentageType, setSelectedPercentageType] = useState([]);

  const [InvestmentPeriod, setInvestmentPeriodOption] = useState([]);
  const [selectedInvestmentPeriod, setSelectedInvestmentPeriod] = useState([]);

  const [IdProof, setIdProofOption] = useState([]);
  const [selectedIdProof, setSelectedIdProof] = useState([]);

  const [AddressProof, setAddressProofOption] = useState([]);
  const [selectedAddressProof, setSelectedAddressProof] = useState([]);
  const [isValidMobile, setIsValidMobile] = useState(true);
  const navigate = useNavigate();
  const { type, Sr_No } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [Bank, setBankOption] = useState([]);
  const [selectedBank, setSelectedBank] = useState([]);
  const [selectedBankName, setSelectedBankName] = useState([]);

  const [capitalAmount, setCapitalAmount] = useState(0);
  const [errors, setErrors] = useState({});
  const { userData } = useUser();
  const [plan, setPlanOption] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState([]);
  const [EmpDropdown, setEmpDropdown] = useState();
  const [rateOption, setRateOption] = useState([]);
  const [selectedRate, setSelectedRate] = useState(0);

  const [isEditing, setIsEditing] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState("0");
  const [isPlanEnable, setIsPlanEnable] = useState(false);
  const showSummarry = () => {
    setIsModal(true);
  };
  const closed = (e) => {
    setIsModal(false);
    // console.log(e);
    if (e === 'verified') {
      setIsEditing(true);
      if (totalInvestment === 1) {
        setIsPlanEnable(true);
      }
      else {
        setIsPlanEnable(false);
      }
    }
  };

  const handleEditClick = (e) => {
    if (userData.role !== "Admin" && customer.IsActive === 'Active' && type === 'edit') {
      insertOTP(e);
    } else {
      setIsEditing(true);
      if (totalInvestment === 1) {
        setIsPlanEnable(true);
      }
      else {
        setIsPlanEnable(false);
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setIsPlanEnable(false);
  };

  useEffect(() => {
    if (selectedPlan && selectedPlan.value) {
      (async () => {
        showLoader();
        const cap_Amount = await axios.get(
          `${apiUrl}/getPlanss/${selectedPlan.value}`
        );
        //setCapitalAmount(cap_Amount.data.Capital_Amount);
        setSelectedRate(parseFloat(cap_Amount.data.Interest_Rate).toFixed(2));
        hideLoader();
      })();
    }
  }, [selectedPlan.value]);



  useEffect(() => {
    if (userData && userData.Org_Id) {
      //debugger;
      LoadCategory();
    }
  }, [userData, Sr_No]);

  useEffect(() => {
    LoadplanCount();
  }, [Sr_No]);

  const LoadplanCount = async () => {
    const planCount = await axios.get(`${apiUrl}/getInvestmentCount/${Sr_No}`)
    setTotalInvestment(planCount.data[0].TotalInvestment);

    if (planCount.data[0].TotalInvestment === 1 && isEditing) {
      setIsPlanEnable(true);
    }
    else {
      setIsPlanEnable(false);
    }

  }

  const LoadCategory = async () => {
    showLoader();
    const customer = await axios.get(`${apiUrl}/getCustomerById/${Sr_No}`);
    setCustomer(customer.data);
    //setSelectedRate(parseFloat(customer.data.Rate_Of_Int).toFixed(2));
    setCapitalAmount(parseFloat(customer.data.Capital_Amount).toFixed(2));
    setSelectedBank({
      label: customer.data.Bank_Name,
      value: customer.data.Bank_Name,
    });
    setSelectedBankName({
      label: customer.data.Bank,
      value: customer.data.Bank,
    });
    setSelectedPlan({
      label: customer.data.Investment_Type,
      value: customer.data.Investment_Type_Id,
    });
    //console.log(customer.data.Investment_Type, customer.data.Investment_Type_Id,);
    setSelectedGender({
      label: customer.data.Gender,
      value: customer.data.Gender,
    });
    setSelectedMaritalStatus({
      label: customer.data.Marital_Status,
      value: customer.data.Marital_Status,
    });
    setSelectedPayMode({
      label: customer.data.Mode_Of_Investment,
      value: customer.data.Mode_Of_Investment,
    });

    setSelectedPercentageType({
      label: customer.data.Percentage_Type,
      value: customer.data.Percentage_Type_Id,
    });
    setSelectedInvestmentPeriod({
      label: customer.data.Investment_Period,
      value: customer.data.Investment_Period_Value,
    });
    setIsStudent({
      label: customer.data.Student,
      value: customer.data.Student,
    });

    setSelectedIdProof({
      label: customer.data.Id_Proof_Type,
      value: customer.data.Id_Proof_Type,
    });

    setSelectedAddressProof(
      customer?.data?.Address_Proof_Type?{
      label: customer.data.Address_Proof_Type,
      value: customer.data.Address_Proof_Type,
    }:null);
    setReferenceType({
      label: customer.data.Reference_Type,
      value: customer.data.Reference_Type,
    });

    const Periods = await axios.get(
      `${apiUrl}/getPeriods?OrgId=${userData.Org_Id}`
    );
    const periodsOption = Periods.data.map((option) => ({
      label: option.Name,
      value: option.Value,
    }));
    setInvestmentPeriodOption(periodsOption);

    const PercentageTypes = await axios.get(
      `${apiUrl}/getPercentageTypes?OrgId=${userData.Org_Id}`
    );
    const percentageTypesOption = PercentageTypes.data.map((option) => ({
      label: option.Percentage_Type_Name,
      value: option.Sr_No,
    }));
    setPercentageTypeOption(percentageTypesOption);
    setSelectedPercentageType({ label: 'Fixed', value: 2 });

    const IDProof = await axios.get(
      `${apiUrl}/getIDProofTypes?OrgId=${userData.Org_Id}`
    );
    const IDProofOption = IDProof.data.map((option) => ({
      label: option.ID_Proof_Name,
      value: option.ID_Proof_Name,
    }));
    setIdProofOption(IDProofOption);

    const AddressProof = await axios.get(
      `${apiUrl}/getAddressProofTypes?OrgId=${userData.Org_Id}`
    );
    const AddressProofOption = AddressProof.data.map((option) => ({
      label: option.Address_Proof_Name,
      value: option.Address_Proof_Name,
    }));
    setAddressProofOption(AddressProofOption);

    const plan = await axios.get(`${apiUrl}/getPlans?OrgId=${userData.Org_Id}`);
    const planOption = plan.data.map((option) => ({
      label: option.Plan_Name,
      value: option.Plan_Id,
    }));
    setPlanOption(planOption);

    const res = await axios.get(`${apiUrl}/getBanks?OrgId=${userData.Org_Id}`);
    const bankOption = res.data.map((option) => ({
      label: option.Bank_Name,
      value: option.Bank_Name,
    }));
    setBankOption(bankOption);

    const staffResult = await axios.get(
      `${apiUrl}/getUsers?OrgId=${userData.Org_Id}`
    );
    const staffOption = staffResult.data.map((option) => ({
      value: option.EmpId,
      label: `${option.First_Name} ${option.Last_Name}`,
    }));
    setEmpDropdown(staffOption)
    hideLoader();
  };


  const [customer, setCustomer] = useState({
    Enrollment_No: "",
    Start_Date: "",
    Investment_Type_Id: "",
    Investment_Type: "",
    Capital_Amount: "",
    Rate_Of_Int: "",
    Mode_Of_Investment: "",
    Payment_Doc: "",
    Bank: "",
    Branch_Name: "",
    Cheque_No: "",
    Cheque_Date: "",
    Reference_No: "",
    Investment_Date: "",
    Percentage_Type_Id: "",
    Percentage_Type: "",
    Investment_Period: "",
    Investment_Period_Value: "",
    Remarks: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    DOB: "",
    Gender: "",
    Marital_Status: "",
    Mobile: "",
    Email: "",
    Pan_No: "",
    Aadhar_No: "",
    Reference_Type: "",
    Reference: "",
    Student: "",
    Address: "",
    Bank_Name: "",
    IFCE_Code: "",
    Account_No: "",
    Account_Holder_Name: "",
    Co_applicant: false,
    Co_applicant_Photo: "",
    Co_applicant_Full_Name: "",
    Co_applicant_DOB: "",
    Co_applicant_Birth_Proof: "",
    Co_applicant_Occupation: "",
    Co_applicant_Relationship: "",
    Co_applicant_Address: "",
    Photo: "",
    Id_Proof_Type: "",
    Id_Proof_Attachment: "",
    Address_Proof_Type: "",
    Address_Proof_Attachment: "",
    Nominee_Full_Name: "",
    Nominee_Address_Proof: "",
    Nominee_DOB: "",
    Nominee_Birth_Proof: "",
    Nominee_Photo: "",
    Nominee_Sign_Attachment: "",
    Agreement_Date: "",
    Receiver_Name: "",
    DropEmpId: "",
    Emp_Name: "",
    Attachment: "",
    File_No: "",
    File_Location: "",
    Postal_Address: "",
    Postal_Receipt: "",
    Postal_Compony: "",
    AWB_No: "",
    Agreement_Remarks: "",
    Updated_By_Employee_Id: "",
    Updated_By_Id: "",
    Updated_By: "",
    Org_Id: "",
  });

  //console.log(customer)

  const handleSubmit = async (event) => {
    event.preventDefault();
debugger;
    const updateCustomer = {
      ...customer,
      
      Attachment: customer.Attachment,
      Postal_Receipt: customer.Postal_Receipt,
      Id_Proof_Attachment: customer.Id_Proof_Attachment,
      Address_Proof_Attachment: customer.Address_Proof_Attachment,
      Nominee_Sign_Attachment: customer.Nominee_Sign_Attachment,
      Nominee_Photo: customer.Nominee_Photo,
      Nominee_Birth_Proof: customer.Nominee_Birth_Proof,
      Co_applicant_Photo: customer.Co_applicant_Photo,
      Co_applicant_Birth_Proof: customer.Co_applicant_Birth_Proof,
      Photo: customer.Photo,
      Capital_Amount: capitalAmount,
      Bank_Name: selectedBank.value,
      Bank: selectedBankName.value,
      Rate_Of_Int: selectedRate,
      Investment_Type_Id: selectedPlan.value,
      Investment_Type: selectedPlan.label,
      Mode_Of_Investment: selectedPayMode.value,
      Gender: selectedGender.value,
      Marital_Status: selectedMaritalStatus.value,
      Id_Proof_Type: selectedIdProof.value,
      Address_Proof_Type: selectedAddressProof.value,
      Reference_Type: referenceType.value,
      Student: IsStudent.value,
      Percentage_Type_Id: selectedPercentageType.value,
      Percentage_Type: selectedPercentageType.label,
      Investment_Period: selectedInvestmentPeriod.label,
      Investment_Period_Value: selectedInvestmentPeriod.value,
      Updated_By_Employee_Id: userData.Employee_Id ? userData.Employee_Id : "",
      Updated_By_Id: userData.userId,
      Updated_By: `${userData.firstName} ${userData.lastName}`,
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName: userData.UserName,
    };

    const formdata = new FormData();
    formdata.append("Agreement_Date", customer.Agreement_Date);
    formdata.append("Receiver_Name", customer.Receiver_Name);
    formdata.append("DropEmpId", customer.DropEmpId);
    formdata.append("Emp_Name", customer.Emp_Name);
    formdata.append("Attachment", updateCustomer.Attachment);
    formdata.append("File_No", customer.File_No);
    formdata.append("File_Location", customer.File_Location);
    formdata.append("Postal_Address", customer.Postal_Address);
    formdata.append("Postal_Receipt", updateCustomer.Postal_Receipt);
    formdata.append("Postal_Compony", customer.Postal_Compony);
    formdata.append("AWB_No", customer.AWB_No);
    formdata.append("Agreement_Remarks", customer.Agreement_Remarks);
    formdata.append("User_Role", updateCustomer.User_Role);
    formdata.append("Emp_Id", updateCustomer.Emp_Id);
    formdata.append("Added_By_Id", updateCustomer.Added_By_Id);
    formdata.append("Added_By", updateCustomer.Added_By);
    formdata.append("UserName", updateCustomer.UserName);
    formdata.append("Enrollment_No", updateCustomer.Enrollment_No);
    formdata.append("Start_Date", customer.Start_Date);
    formdata.append("Investment_Date", customer.Investment_Date);
    if (customer.Branch_Name) {
      formdata.append("Branch_Name", customer.Branch_Name);
    }
    if (customer.Cheque_No) {
      formdata.append("Cheque_No", customer.Cheque_No);
    }
    if (customer.Cheque_Date) {
      formdata.append("Cheque_Date", customer.Cheque_Date);
    }
    if (customer.Reference_No) {
      formdata.append("Reference_No", customer.Reference_No);
    }
    formdata.append("First_Name", customer.First_Name);
    formdata.append("Middle_Name", customer.Middle_Name);
    formdata.append("Last_Name", customer.Last_Name);
    formdata.append("Remarks", customer.Remarks);
    formdata.append("DOB", customer.DOB);
    formdata.append("Mobile", customer.Mobile);
    formdata.append("Email", customer.Email);
    formdata.append("Pan_No", customer.Pan_No);
    formdata.append("Aadhar_No", customer.Aadhar_No);
    formdata.append("Reference_Type", updateCustomer.Reference_Type);
    formdata.append("Reference", customer.Reference);
    formdata.append("Address", customer.Address);
    formdata.append("IFCE_Code", customer.IFCE_Code);
    formdata.append("Account_No", customer.Account_No);
    formdata.append("Account_Holder_Name", customer.Account_Holder_Name);
    formdata.append("Co_applicant", customer.Co_applicant);
    formdata.append("Co_applicant_Full_Name", customer.Co_applicant_Full_Name);
    formdata.append("Payment_Doc", customer.Payment_Doc);
    formdata.append("Co_applicant_DOB", customer.Co_applicant_DOB);
    formdata.append("Co_applicant_Photo", updateCustomer.Co_applicant_Photo);
    formdata.append(
      "Co_applicant_Birth_Proof",
      updateCustomer.Co_applicant_Birth_Proof
    );
    formdata.append(
      "Co_applicant_Occupation",
      customer.Co_applicant_Occupation
    );
    formdata.append(
      "Co_applicant_Relationship",
      customer.Co_applicant_Relationship
    );
    formdata.append("Co_applicant_Address", customer.Co_applicant_Address);
    formdata.append("Photo", updateCustomer.Photo);
    formdata.append("Id_Proof_Type", updateCustomer.Id_Proof_Type);
    formdata.append("Id_Proof_Attachment", updateCustomer.Id_Proof_Attachment);
    formdata.append("Address_Proof_Type", updateCustomer.Address_Proof_Type);
    formdata.append(
      "Address_Proof_Attachment",
      updateCustomer.Address_Proof_Attachment
    );
    formdata.append("Nominee_Full_Name", customer.Nominee_Full_Name);
    formdata.append("Nominee_DOB", customer.Nominee_DOB);
    formdata.append(
      "Nominee_Sign_Attachment",
      updateCustomer.Nominee_Sign_Attachment
    );
    formdata.append("Nominee_Birth_Proof", updateCustomer.Nominee_Birth_Proof);
    formdata.append("Nominee_Photo", updateCustomer.Nominee_Photo);
    formdata.append("Nominee_Address_Proof", updateCustomer.Nominee_Address_Proof);
    formdata.append("Mode_Of_Investment", updateCustomer.Mode_Of_Investment);
    formdata.append("Capital_Amount", updateCustomer.Capital_Amount);
    formdata.append("Percentage_Type_Id", updateCustomer.Percentage_Type_Id);
    formdata.append("Percentage_Type", updateCustomer.Percentage_Type);
    formdata.append("Investment_Period", updateCustomer.Investment_Period);
    formdata.append(
      "Investment_Period_Value",
      updateCustomer.Investment_Period_Value
    );
    formdata.append("Bank_Name", updateCustomer.Bank_Name);
    if (updateCustomer.Bank) {
      formdata.append("Bank", updateCustomer.Bank);
    }
    formdata.append("Investment_Type_Id", updateCustomer.Investment_Type_Id);
    formdata.append("Investment_Type", updateCustomer.Investment_Type);
    formdata.append("Gender", updateCustomer.Gender);
    formdata.append("Student", updateCustomer.Student);
    formdata.append("Marital_Status", updateCustomer.Marital_Status);
    formdata.append(
      "Updated_By_Employee_Id",
      updateCustomer.Updated_By_Employee_Id
    );
    formdata.append("Rate_Of_Int", updateCustomer.Rate_Of_Int);
    formdata.append("Updated_By_Id", updateCustomer.Updated_By_Id);
    formdata.append("Updated_By", updateCustomer.Updated_By);
    formdata.append("Org_Id", updateCustomer.Org_Id);
    formdata.append("TotalInvestment", totalInvestment);

    const validationErrors = {};

    if (Object.keys(validationErrors).length === 0) {
      showLoader();

      axios
        .put(`${apiUrl}/updateCustomer/${Sr_No}`, formdata)
        .then((res) => {
          Swal.fire("Success!", "Customer has been Updated.", "success");
          //navigate("/viewCustomer");
          handleCancelEdit();
          LoadCategory();
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error",
            text: "Failed to Update. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });

          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const [adminData, setAdminData] = useState({});

  useEffect(() => {
    axios
      .get(`${apiUrl}/getAdminMobile`)
      .then((res) => {
        const data = res.data;
        // console.log(data);
        setAdminData(data);
      });

  }, []);

  const insertOTP = async (event) => {
    event.preventDefault();
    const newOTP = generateOTP();
    const otp = {
      Employee: `${userData.firstName} ${userData.lastName}`,
      OTP: newOTP,
      Customer: `${customer.First_Name} ${customer.Last_Name}`,
      OrgId: userData.Org_Id,
      Email: type === 'edit' ? adminData.Email : userData.email,
      number: type === 'edit' ? adminData.Mobile : userData.Mobile,
      messageTitle:
        type === "edit"
          ? "Customer Update OTP"
          : "Account Opening Verification",
    };
    showLoader();

    axios
      .put(`${apiUrl}/insertNewAcountOpt/${Sr_No}`, otp)
      .then((res) => {
        //Swal.fire("Success!", "OTP has been Generated.", "success");
        //navigate("/viewCustomer");
        //LoadCategory();

        showSummarry();
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Generate OTP. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        handleCancelEdit();
        hideLoader();
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Update Customer</h5>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-map-pin mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Choose Investment Plan
                  </h5>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="selectedPlan" className="form-label">
                          Investment Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          options={plan}
                          isDisabled={!isPlanEnable}
                          value={selectedPlan}
                          onChange={setSelectedPlan}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Enrollment_No" className="form-label">
                          Enrollment No<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="Enrollment_No"
                          className="form-control "
                          required
                          disabled
                          value={customer.Enrollment_No}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Investment_Date" className="form-label">
                          Investment Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          id="Investment_Date"
                          className="form-control "
                          //placeholder="Enter Mobile Number"
                          required
                          dateFormat="dd-MM-yyyy"
                          disabled={!isEditing}
                          value={formatInputDate(customer.Investment_Date)}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Investment_Date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="txtLname" className="form-label">
                          Capital Amount<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="txtLname"
                          className="form-control "
                          placeholder="Enter Capital Amount"
                          required
                          disabled={!isPlanEnable}
                          value={capitalAmount}
                          onChange={(e) => {
                            const input = e.target.value;
                            const decimalInput = input.replace(/[^0-9.]/g, "");
                            const amount = decimalInput.replace(
                              /^(\d*\.\d*)\..*$/,
                              "$1"
                            );
                            setCapitalAmount(amount);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Start_Date" className="form-label">
                          Start Date<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          id="Start_Date"
                          className="form-control "
                          required
                          dateFormat="dd-MM-yyyy"
                          disabled={!isEditing}
                          value={formatInputDate(customer.Start_Date)}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Start_Date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          Mode Of Investment
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          options={Payment_Mode}
                          value={selectedPayMode}
                          isDisabled={!isEditing}
                          onChange={setSelectedPayMode}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Payment_Doc">
                          Payment Doc
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Payment_Doc"
                            name="Payment_Doc"
                            disabled={!isEditing}
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setPaymentDocFileName(fileName);

                              setCustomer({
                                ...customer,
                                Payment_Doc: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Payment_Doc"
                            style={{ overflow: "hidden" }}
                          >
                            {PaymentDocFileName}
                          </label>
                        </div>

                      </div>
                    </div>
                    <dive className="col-md-1" style={{ marginTop: "33px" }}>
                      {customer.Payment_Doc ? (
                        <>
                          <a style={{ color: "blue" }} href={`${apiUrl}/paymentDocView/` + customer.Payment_Doc} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                            <Visibility fontSize="medium" />
                          </a> /
                          <a style={{ color: "blue" }} href={`${apiUrl}/paymentDoc/` + customer.Payment_Doc} download className="cursor-pointer">
                            <DownloadIcon fontSize="medium" />
                          </a></>
                      ) : (
                        <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                      )}
                    </dive>
                  </div>

                  <div className="row">
                    {["Cheque", "DD"].includes(selectedPayMode.value) && (
                      <>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="Bank" className="form-label">
                              Bank Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              type="text"
                              name="Bank"
                              id="Bank"
                              options={Bank}
                              isDisabled={!isEditing}
                              value={selectedBankName}
                              onChange={setSelectedBankName}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="Branch_Name" className="form-label">
                              IFSC/Branch Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="Branch_Name"
                              name="Branch_Name"
                              disabled={!isEditing}
                              value={customer.Branch_Name}
                              placeholder="IFSC/Branch Name"
                              required={true}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Branch_Name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="Cheque_No" className="form-label">
                              Cheque No <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="Cheque_No"
                              name="Cheque_No"
                              disabled={!isEditing}
                              placeholder="Enter Cheque No"
                              value={customer.Cheque_No}
                              required={true}
                              onChange={(e) => {
                                const input = e.target.value;
                                const numericInput = input.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                setCustomer({
                                  ...customer,
                                  Cheque_No: numericInput,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="Cheque_Date" className="form-label">
                              Cheque Date{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              id="Cheque_Date"
                              name="Cheque_Date"
                              required={true}
                              dateFormat="dd-MM-yyyy"
                              value={formatInputDate(customer.Cheque_Date)}
                              disabled={!isEditing}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Cheque_Date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row">
                    {[
                      "TPT",
                      "IMPS",
                      "GPAY",
                      "Online",
                      "PhonePay",
                      "PAYTM",
                      "NEFT",
                      "RTGS",
                    ].includes(selectedPayMode.value) && (
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="Reference_No" className="form-label">
                              Transaction ID{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              disabled={!isEditing}
                              id="Reference_No"
                              name="Reference_No"
                              required={true}
                              placeholder="Enter Transaction ID"
                              value={customer.Reference_No}
                              onChange={(e) =>
                                setCustomer({
                                  ...customer,
                                  Reference_No: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="row">
                    <div
                      className={
                        selectedPercentageType.label === "Fixed"
                          ? "col-md-4"
                          : "col-md-6"
                      }
                    >
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          Investment Period
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          isDisabled={!isEditing}
                          options={InvestmentPeriod}
                          value={selectedInvestmentPeriod}
                          onChange={setSelectedInvestmentPeriod}
                          required
                        />
                      </div>
                    </div>
                    <div
                      className={
                        selectedPercentageType.label === "Fixed"
                          ? "col-md-4"
                          : "col-md-6"
                      }
                    >
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          Percentage Type
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          isDisabled
                          options={PercentageType}
                          value={selectedPercentageType}
                          onChange={setSelectedPercentageType}
                          required
                        />
                      </div>
                    </div>
                    {selectedPercentageType.label === "Fixed" && (
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Rate_Of_Int">
                            Rate Of Int.<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="Rate_Of_Int"
                            required={selectedPercentageType.label === "Fixed"}
                            //options={rateOption}
                            disabled
                            value={selectedRate}
                            //onChange={setSelectedRate}
                            onChange={(e) => {
                              const input = e.target.value;
                              const decimalInput = input.replace(/[^0-9.]/g, "");
                              const amount = decimalInput.replace(
                                /^(\d*\.\d*)\..*$/,
                                "$1"
                              );
                              setSelectedRate(amount);
                              //setErrors({ ...errors, sumMismatch:''});
                            }}
                            id="Rate_Of_Int"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Remarks
                        </label>
                        <textarea
                          type="text"
                          id="exampleFormControlTextarea1"
                          className="form-control"
                          placeholder="Enter Remarks"
                          rows="2"
                          disabled={!isEditing}
                          value={customer.Remarks}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Remarks: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-user-shield mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Personal Details
                  </h5>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="First_Name" className="form-label">
                          First Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="First_Name"
                          name="First_Name"
                          className="form-control "
                          placeholder="Enter First Name"
                          required
                          disabled={!isEditing}
                          value={customer.First_Name}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              First_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Middle_Name" className="form-label">
                          Middle Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="Middle_Name"
                          name="Middle_Name"
                          className="form-control "
                          placeholder="Enter Middle Name"
                          required
                          disabled={!isEditing}
                          value={customer.Middle_Name}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Middle_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Last_Name" className="form-label">
                          Last Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="Last_Name"
                          name="Last_Name"
                          className="form-control "
                          placeholder="Enter Last Name"
                          required
                          disabled={!isEditing}
                          value={customer.Last_Name}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Last_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="DOB" className="form-label">
                          DOB<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          id="DOB"
                          name="DOB"
                          required
                          dateFormat="dd-MM-yyyy"
                          value={formatInputDate(customer.DOB)}
                          className="form-control "
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              DOB: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Gender" className="form-label">
                          Gender<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          id="Gender"
                          name="Gender"
                          required
                          isDisabled={!isEditing}
                          options={Gender}
                          value={selectedGender}
                          onChange={setSelectedGender}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Marital_Status" className="form-label">
                          Marital Status<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          id="Marital_Status"
                          name="Marital_Status"
                          required
                          isDisabled={!isEditing}
                          options={Marital_Status}
                          value={selectedMaritalStatus}
                          onChange={setSelectedMaritalStatus}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Mobile" className="form-label">
                          Mobile<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          maxLength={10}
                          value={customer.Mobile}
                          id="Mobile"
                          name="Mobile"
                          className={`form-control ${isValidMobile ? "" : "is-invalid"
                            }`}
                          placeholder="Enter Mobile Number"
                          required
                          disabled={!isEditing}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            const isValid = /^[6-9][0-9]{0,9}$/.test(
                              numericInput
                            );
                            setIsValidMobile(isValid);
                            if (isValid) {
                              setCustomer({
                                ...customer,
                                Mobile: numericInput,
                              });
                            }
                          }}
                        />
                        {!isValidMobile && (
                          <div className="invalid-feedback">
                            Invalid mobile number.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Pan_No">
                          Pan No<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="Pan_No"
                          className="form-control  "
                          id="Pan_No"
                          required
                          placeholder="Enter Pan No"
                          value={customer.Pan_No}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Pan_No: e.target.value.toUpperCase(),
                            })
                          }
                          maxLength={10}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Aadhar_No">
                          Aadhar No<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          name="Aadhar_No"
                          className="form-control  "
                          id="Aadhar_No"
                          required
                          placeholder="Enter Aadhar No"
                          disabled={!isEditing}
                          value={customer.Aadhar_No}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            setCustomer({
                              ...customer,
                              Aadhar_No: numericInput,
                            });
                          }}
                          maxLength={12}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        referenceType && referenceType.value === "Reference"
                          ? "col-md-3"
                          : "col-md-4"
                      }
                    >
                      <div className="mb-3">
                        <label htmlFor="Email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          id="Email"
                          name="Email"
                          value={customer.Email}
                          disabled={!isEditing}
                          className="form-control "
                          placeholder="Enter Email "
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Email: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={
                        referenceType && referenceType.value === "Reference"
                          ? "col-md-3"
                          : "col-md-4"
                      }
                    >
                      <div className="mb-3">
                        <label htmlFor="Reference_Type" className="form-label">
                          Reference Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="Reference_Type"
                          id="Reference_Type"
                          options={ReferenceOption}
                          value={referenceType}
                          isDisabled={!isEditing}
                          onChange={setReferenceType}
                          required
                        />
                      </div>
                    </div>
                    {referenceType && referenceType.value === "Reference" && (
                      <div
                        className={
                          referenceType && referenceType.value === "Reference"
                            ? "col-md-3"
                            : "col-md-4"
                        }
                      >
                        <div className="mb-3">
                          <label className="form-label" htmlFor="Reference">
                            Reference<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            name="Reference"
                            className="form-control  "
                            id="Reference"
                            placeholder="Enter Reference"
                            required={referenceType.value === "Reference"}
                            value={customer.Reference}
                            disabled={!isEditing}
                            onChange={(e) =>
                              setCustomer({
                                ...customer,
                                Reference: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        referenceType && referenceType.value === "Reference"
                          ? "col-md-3"
                          : "col-md-4"
                      }
                    >
                      <div className="mb-3">
                        <label htmlFor="Student" className="form-label">
                          Student<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="Student"
                          id="Student"
                          isDisabled={!isEditing}
                          options={StudentOption}
                          value={IsStudent}
                          onChange={setIsStudent}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="Address" className="form-label">
                          Address<span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          type="text"
                          id="Address"
                          name="Address"
                          className="form-control"
                          placeholder="Enter Address..."
                          required
                          rows="2"
                          disabled={!isEditing}
                          value={customer.Address}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Address: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-university mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Bank Details
                  </h5>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Bank_Name" className="form-label">
                          Bank Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="Bank_Name"
                          id="Bank_Name"
                          placeholder="Select Bank Name"
                          options={Bank}
                          isDisabled={!isEditing}
                          value={selectedBank}
                          onChange={setSelectedBank}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="IFCE_Code" className="form-label">
                          IFSC Code<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="IFCE_Code"
                          name="IFCE_Code"
                          className="form-control "
                          placeholder="Enter IFSC Code"
                          required
                          disabled={!isEditing}
                          value={customer.IFCE_Code}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              IFCE_Code: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="Account_No" className="form-label">
                          Account No<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="Account_No"
                          name="Account_No"
                          className="form-control "
                          placeholder="Enter Account No"
                          required
                          disabled={!isEditing}
                          value={customer.Account_No}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Account_No: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          htmlFor="Account_Holder_Name"
                          className="form-label"
                        >
                          Account Holder Name
                        </label>
                        <input
                          type="text"
                          id="Account_Holder_Name"
                          name="Account_Holder_Name"
                          value={customer.Account_Holder_Name}
                          className="form-control "
                          disabled={!isEditing}
                          placeholder="Enter Account Holder Name "
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Account_Holder_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fas fa-globe mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Document
                  </h5>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Photo">
                          Photo
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Photo"
                            name="Photo"
                            disabled={!isEditing}
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setAplicantPhotoFileName(fileName);

                              setCustomer({
                                ...customer,
                                Photo: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Photo"
                            style={{ overflow: "hidden" }}
                          >
                            {aplicantPhotoFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {customer.Photo ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Photo} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium"  disabled={isEditing}/>
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Photo} className="cursor-pointer">
                              <DownloadIcon fontSize="medium"  disabled={isEditing}/>
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          ID Proof Type
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          isDisabled={!isEditing}
                          options={IdProof}
                          value={selectedIdProof}
                          onChange={setSelectedIdProof}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Id_Proof_Attachment"
                        >
                          ID Proof Attachment
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Id_Proof_Attachment"
                            disabled={!isEditing}
                            name="Id_Proof_Attachment"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setIdProofFileName(fileName);

                              setCustomer({
                                ...customer,
                                Id_Proof_Attachment: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Id_Proof_Attachment"
                            style={{ overflow: "hidden" }}
                          >
                            {idProofFileName}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {customer.Id_Proof_Attachment ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Id_Proof_Attachment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Id_Proof_Attachment} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="txtEmail" className="form-label">
                          Address Proof Type
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          type="text"
                          name="selectedPlan"
                          id="selectedPlan"
                          isDisabled={!isEditing}
                          options={AddressProof}
                          value={selectedAddressProof}
                          onChange={setSelectedAddressProof}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Address_Proof_Attachment"
                        >
                          Address Proof Attachment
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Address_Proof_Attachment"
                            name="Address_Proof_Attachment"
                            accept=".jpg, .jpeg, .png, .pdf"
                            disabled={!isEditing}
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setAddressProofFileName(fileName);

                              setCustomer({
                                ...customer,
                                Address_Proof_Attachment: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Address_Proof_Attachment"
                            style={{ overflow: "hidden" }}
                          >
                            {addressProofFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ marginTop: "33px" }} >
                      <div className="mb-3">
                        {customer.Address_Proof_Attachment ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Address_Proof_Attachment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Address_Proof_Attachment} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-user-tag mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Co-applicant
                  </h5>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <div className="custom-control custom-checkbox ml-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="Co_applicant"
                            value={
                              customer.Co_applicant
                                ? customer.Co_applicant
                                : null
                            }
                            disabled={!isEditing}
                            onChange={(e) =>
                              setCustomer({
                                ...customer,
                                Co_applicant: e.target.checked,
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="Co_applicant"
                          >
                            Co-Applicant
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {customer.Co_applicant &&
                    customer.Co_applicant === "true" && (
                      <div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="Co_applicant_Full_Name"
                                className="form-label"
                              >
                                Full Name<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="Co_applicant_Full_Name"
                                name="Co_applicant_Full_Name"
                                className="form-control "
                                placeholder="Enter Full Name"
                                disabled={!isEditing}
                                value={customer.Co_applicant_Full_Name}
                                required={customer.Co_applicant === true}
                                onChange={(e) =>
                                  setCustomer({
                                    ...customer,
                                    Co_applicant_Full_Name: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="Co_applicant_DOB"
                                className="form-label"
                              >
                                DOB<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="date"
                                id="Co_applicant_DOB"
                                name="Co_applicant_DOB"
                                className="form-control"
                                disabled={!isEditing}
                                dateFormat="dd-MM-yyyy"
                                value={formatInputDate(
                                  customer.Co_applicant_DOB
                                )}
                                required={customer.Co_applicant === true}
                                onChange={(e) =>
                                  setCustomer({
                                    ...customer,
                                    Co_applicant_DOB: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="Co_applicant_Relationship"
                                className="form-label"
                              >
                                Relationship
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="Co_applicant_Relationship"
                                name="Co_applicant_Relationship"
                                className="form-control "
                                placeholder="Enter Relationship "
                                disabled={!isEditing}
                                value={customer.Co_applicant_Relationship}
                                required={customer.Co_applicant === true}
                                onChange={(e) =>
                                  setCustomer({
                                    ...customer,
                                    Co_applicant_Relationship: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="Co_applicant_Photo"
                              >
                                Photo
                              </label>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="Co_applicant_Photo"
                                  name="Co_applicant_Photo"
                                  accept=".jpg, .jpeg, .png"
                                  disabled={!isEditing}
                                  onChange={(e) => {
                                    const fileName =
                                      e.target.files[0]?.name || "Choose file";
                                    setCoaplicantPhotoFileName(fileName);

                                    setCustomer({
                                      ...customer,
                                      Co_applicant_Photo: e.target.files[0],
                                    });
                                  }}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="Co_applicant_Photo"
                                  style={{ overflow: "hidden" }}
                                >
                                  {coaplicantPhotoFileName}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1" style={{ marginTop: "33px" }}>
                            <div className="mb-3">
                              {customer.Co_applicant_Photo ? (
                                <>
                                  <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Co_applicant_Photo} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                    <Visibility fontSize="medium" />
                                  </a> /
                                  <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Co_applicant_Photo} className="cursor-pointer">
                                    <DownloadIcon fontSize="medium" />
                                  </a></>
                              ) : (
                                <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                              )}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="Co_applicant_Birth_Proof"
                              >
                                DOB Proof
                              </label>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="Co_applicant_Birth_Proof"
                                  name="Co_applicant_Birth_Proof"
                                  accept=".jpg, .jpeg, .png, .pdf"
                                  disabled={!isEditing}
                                  onChange={(e) => {
                                    const fileName =
                                      e.target.files[0]?.name || "Choose file";
                                    setCoaplicantDOBProofFileName(fileName);

                                    setCustomer({
                                      ...customer,
                                      Co_applicant_Birth_Proof:
                                        e.target.files[0],
                                    });
                                  }}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="Co_applicant_Birth_Proof"
                                  style={{ overflow: "hidden" }}
                                >
                                  {coaplicantDOBProofFileName}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1" style={{ marginTop: "33px" }}>
                            <div className="mb-3">
                              {customer.Co_applicant_Birth_Proof ? (
                                <>
                                  <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Co_applicant_Birth_Proof} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                    <Visibility fontSize="medium" />
                                  </a> /
                                  <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Co_applicant_Birth_Proof} className="cursor-pointer">
                                    <DownloadIcon fontSize="medium" />
                                  </a></>
                              ) : (
                                <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="Co_applicant_Occupation"
                                className="form-label"
                              >
                                Occupation
                              </label>
                              <input
                                type="text"
                                id="Co_applicant_Occupation"
                                name="Co_applicant_Occupation"
                                className="form-control "
                                placeholder="Enter Occupation "
                                disabled={!isEditing}
                                value={customer.Co_applicant_Occupation}
                                onChange={(e) =>
                                  setCustomer({
                                    ...customer,
                                    Co_applicant_Occupation: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="Co_applicant_Address"
                                className="form-label"
                              >
                                Address
                              </label>
                              <textarea
                                type="text"
                                id="Co_applicant_Address"
                                name="Co_applicant_Address"
                                className="form-control"
                                placeholder="Enter Address"
                                rows="2"
                                disabled={!isEditing}
                                onChange={(e) =>
                                  setCustomer({
                                    ...customer,
                                    Co_applicant_Address: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-user-clock mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Nominee
                  </h5>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="Nominee_Full_Name"
                          className="form-label"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          id="Nominee_Full_Name"
                          name="Nominee_Full_Name"
                          className="form-control "
                          placeholder="Enter First Name"
                          value={customer.Nominee_Full_Name}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Nominee_Full_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Nominee_DOB" className="form-label">
                          DOB
                        </label>
                        <input
                          type="date"
                          id="Nominee_DOB"
                          name="Nominee_DOB"
                          dateFormat="dd-MM-yyyy"
                          value={formatInputDate(customer.Nominee_DOB)}
                          disabled={!isEditing}
                          className="form-control "
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Nominee_DOB: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Nominee_Photo">
                          Photo
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Nominee_Photo"
                            disabled={!isEditing}
                            name="Nominee_Photo"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setNomineePhotoFileName(fileName);

                              setCustomer({
                                ...customer,
                                Nominee_Photo: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Nominee_Photo"
                            style={{ overflow: "hidden" }}
                          >
                            {nomineePhotoFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {customer.Nominee_Photo ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Nominee_Photo} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Nominee_Photo} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Nominee_Address_Proof">
                          Nominee Address Proof
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Nominee_Address_Proof"
                            disabled={!isEditing}
                            name="Nominee_Address_Proof"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setNomineeAddressProofFileName(fileName);

                              setCustomer({
                                ...customer,
                                Nominee_Address_Proof: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Nominee_Address_Proof"
                            style={{ overflow: "hidden" }}
                          >
                            {nomineeAddressProofFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {customer.Nominee_Address_Proof ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Nominee_Address_Proof} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Nominee_Address_Proof} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Nominee_Birth_Proof"
                        >
                          DOB Proof
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Nominee_Birth_Proof"
                            name="Nominee_Birth_Proof"
                            disabled={!isEditing}
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setNomineeDOBProofFileName(fileName);

                              setCustomer({
                                ...customer,
                                Nominee_Birth_Proof: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Nominee_Birth_Proof"
                            style={{ overflow: "hidden" }}
                          >
                            {nomineeDOBProofFileName}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {customer.Nominee_Birth_Proof ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Nominee_Birth_Proof} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Nominee_Birth_Proof} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Nominee_Sign_Attachment"
                        >
                          Sign Attachment
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Nominee_Sign_Attachment"
                            disabled={!isEditing}
                            name="Nominee_Sign_Attachment"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setNomineeSignFileName(fileName);

                              setCustomer({
                                ...customer,
                                Nominee_Sign_Attachment: e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Nominee_Sign_Attachment"
                            style={{ overflow: "hidden" }}
                          >
                            {nomineeSignFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {customer.Nominee_Sign_Attachment ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Nominee_Sign_Attachment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Nominee_Sign_Attachment} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "#f2f2f7",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    <i
                      className="fas fa-file-invoice mr-2"
                      style={{ fontSize: "18px" }}
                    ></i>
                    Loan Agreement Details
                  </h5>

                  <div className="row">

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="Agreement_Date"
                          className="form-label"
                        >
                          Agreement Date
                        </label>
                        <input
                          type="date"
                          id="Agreement_Date"
                          name="Agreement_Date"
                          className="form-control "
                          //placeholder="Enter First Name"
                          value={formatInputDate(customer.Agreement_Date)}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Agreement_Date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="Receiver_Name"
                          className="form-label"
                        >
                          Receiver Name
                        </label>
                        <input
                          type="text"
                          id="Receiver_Name"
                          name="Receiver_Name"
                          className="form-control "
                          placeholder="Enter Receiver Name"
                          value={customer.Receiver_Name==='null'?"":customer.Receiver_Name}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Receiver_Name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="EmpId"
                          className="form-label"
                        >
                          Given By Employee
                        </label>
                        <Select
                          id="example-select"
                          options={EmpDropdown}
                          value={customer.Emp_Name? [{value:customer.DropEmpId, label:customer.Emp_Name}]:[]}
                          isDisabled={!isEditing}
                          onChange={(selectedOption) => {
                            setCustomer({
                              ...customer,
                              DropEmpId: selectedOption.value,
                              Emp_Name: selectedOption.label
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Attachment"
                        >
                          Attachment
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Attachment"
                            name="Attachment"
                            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                            disabled={!isEditing}
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setAttachmentFileName(fileName);

                              setCustomer({
                                ...customer,
                                Attachment:
                                  e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Attachment"
                            style={{ overflow: "hidden" }}
                          >
                            {AttachmentFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {customer.Attachment ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Attachment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Attachment} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="File_No"
                          className="form-label"
                        >
                          File No
                        </label>
                        <input
                          type="text"
                          id="File_No"
                          name="File_No"
                          className="form-control "
                          placeholder="Enter File No"
                          value={customer.File_No}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              File_No: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="File_Location"
                          className="form-label"
                        >
                          File Location
                        </label>
                        <input
                          type="text"
                          id="File_Location"
                          name="File_Location"
                          className="form-control "
                          placeholder="Enter File Location"
                          value={customer.File_Location}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              File_Location: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="Postal_Compony"
                          className="form-label"
                        >
                          Postal Compony
                        </label>
                        <input
                          type="text"
                          id="Postal_Compony"
                          name="Postal_Compony"
                          className="form-control "
                          placeholder="Enter Postal Compony Name"
                          value={customer.Postal_Compony}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Postal_Compony: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="AWB_No"
                          className="form-label"
                        >
                         Tracking ID
                        </label>
                        <input
                          type="text"
                          id="AWB_No"
                          name="AWB_No"
                          className="form-control "
                          placeholder="Enter AWB No"
                          value={customer.AWB_No}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              AWB_No: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Postal_Receipt"
                        >
                          Postal Receipt
                        </label>
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="Postal_Receipt"
                            name="Postal_Receipt"
                            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                            disabled={!isEditing}
                            onChange={(e) => {
                              const fileName =
                                e.target.files[0]?.name || "Choose file";
                              setPostalReceiptFileName(fileName);

                              setCustomer({
                                ...customer,
                                Postal_Receipt:
                                  e.target.files[0],
                              });
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="Postal_Receipt"
                            style={{ overflow: "hidden" }}
                          >
                            {PostalReceiptFileName}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {customer.Postal_Receipt ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + customer.Postal_Receipt} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + customer.Postal_Receipt} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="Postal_Address"
                          className="form-label"
                        >
                          Postal Address
                        </label>
                        <textarea
                          type="text"
                          id="Postal_Address"
                          name="Postal_Address"
                          className="form-control "
                          placeholder="Enter Postal Address..."
                          value={customer.Postal_Address}
                          rows={2}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Postal_Address: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="Agreement_Remarks"
                          className="form-label"
                        >
                          Agreement Remarks
                        </label>
                        <textarea
                          type="text"
                          id="Agreement_Remarks"
                          name="Agreement_Remarks"
                          className="form-control "
                          placeholder="Enter Agreement Remarks..."
                          value={customer.Agreement_Remarks}
                          rows={2}
                          disabled={!isEditing}
                          onChange={(e) =>
                            setCustomer({
                              ...customer,
                              Agreement_Remarks: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div></div>
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    {!isEditing && (
                      <button
                        className="btn btn-primary mt-2"
                        onClick={handleEditClick}
                      >
                        Edit
                      </button>
                    )}{" "}
                    &nbsp;
                    {!isEditing && (
                      <button
                        className="btn btn-success mt-2"
                        style={{ display: type === "edit" ? "none" : "" }}
                        onClick={(e) => {
                          e.preventDefault();
                          insertOTP(e);
                        }}
                      >
                        Verify
                      </button>
                    )}
                    {isEditing && (
                      <button
                        className="btn btn-primary mt-2"
                        onClick={

                          handleCancelEdit
                        }
                      >
                        Close
                      </button>
                    )}
                    &nbsp;
                    {isEditing && (
                      <button className="btn btn-primary mt-2" type="submit">
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isModal && (
        <OTP
          open={isModal}
          Sr_No={Sr_No}
          onClose={closed}
          role={userData?.role}
          name={`${customer?.First_Name} ${customer?.Last_Name}`}
          plan={customer?.Investment_Type}
          enroll={customer?.Enrollment_No}
          Mobile={customer?.Mobile}
          type={type}
        />
      )}
      {loader}
    </div>
  );
};

export default UpdateCustomer;
