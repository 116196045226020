import React, { useState, useEffect } from "react";
import "./CustomerPreview.css";
import { Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import Visibility from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import { apiUrl } from "../../componets/apiConfig";
import { useUser } from "../../componets/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import InterestPayment from "../Reports/InterestPayment";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TopUpInvestment from "./TopUpInvestment";
import UpgradePlan from "./UpgradePlan";

const InvestmentLog = ({ CustomerId }) => {
    const { userData } = useUser();
    const [indexValue, setIndex] = useState();
    const [isModal, setIsModal] = useState(false);
    const [isUpgrade, setIsUpgrade] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [planDrop, setplanDrop] = useState([]);

    const dataLoad = async () => {
        showLoader();
        const Result = await axios.get(
            `${apiUrl}/getInvestmentLog?OrgId=${userData.Org_Id}&CustomerId=${CustomerId}`
        );
        setData(Result.data);
        console.log(Result.data);
        hideLoader();
    }
    const loadCustomers = async () => {
        showLoader();
        try {
            const planResult = await axios.get(
                `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
            );
            if (planResult.data.length === 0) {
                setplanDrop([]);
                hideLoader();
            } else {
                setplanDrop(planResult.data);
                hideLoader();
            }
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };
    useEffect(() => {
        if (userData && userData.Org_Id && CustomerId) {
            dataLoad();
            loadCustomers();
        }
    }, [userData])
    const updateData = (index) => {
        setIndex(index);
        setIsModal(true);
    }
    const closed = () => {
        setIsModal(false);
        dataLoad();
    };
    const closeUpgradePlan = () => {
        setIsUpgrade(false);
        dataLoad();
    };
    const upgradePlan = (index) => {
        setIndex(index);
        setIsUpgrade(true);
    }
    return (
        <div className="card">
            <div className="card-body bio-graph-info" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                <div className="col-12 mt-2" style={{ padding: 0 }}>
                    <div className=" table-responsive">
                        <table id="dataTableHover" className="table align-items-center table-flush table-hover">
                            <thead>
                                <tr>
                                    <th className="text-left" style={{ padding: "10px", width: "50px" }} >Sr. No.</th>
                                    <th className="text-left" style={{ padding: "10px", width: "110px" }}>Start Date</th>
                                    <th className="text-left" style={{ padding: "10px" }}>Amount</th>
                                    <th className="text-left" style={{ padding: "10px" }}>Interest</th>
                                    <th className="text-left" style={{ padding: "10px" }}>Detail</th>
                                    <th className="text-left" style={{ padding: "10px" }}>Attatchment</th>
                                    <th className="text-left" style={{ padding: "10px" }}>Status</th>
                                    <th className="text-left" style={{ padding: "10px" }}>Remarks</th>
                                    <th className="text-left" style={{ padding: "10px" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ? (
                                    data.map((item, index) => {
                                        return (
                                            <tr key={index} >
                                                <td className="text-left" style={{ padding: "10px" }}>{index + 1} </td>
                                                <td className="text-left" style={{ padding: "10px" }}>
                                                    {dayjs(item.Start_Date).format("DD/MM/YYYY")}
                                                </td>
                                                <td className="text-left" style={{ padding: "10px" }}>
                                                    {item.Type === 'Upgrade'? parseFloat(item.Total_Amount):parseFloat(item.Investment_Amount)}
                                                </td>
                                                <td className="text-left">
                                                    {parseFloat(item.Rate_Of_Int)}%
                                                </td>
                                                <td className="text-left" style={{ padding: "10px" }}>
                                                    {item.type !== "Upgrade" ? (
                                                        <>
                                                            <b>Mode : </b>{item.Mode_Of_Investment}<br />
                                                            {item.Mode_Of_Investment !== "Cheque" && item.Mode_Of_Investment !== "DD" &&
                                                                item.Mode_Of_Investment !== "Cash" &&
                                                                (
                                                                    <>
                                                                        <b>Transaction ID : </b>{item.Reference_No}<br />
                                                                    </>
                                                                )}
                                                            {(item.Mode_Of_Investment === "Cheque" || item.Mode_Of_Investment === "DD") &&
                                                                (
                                                                    <>
                                                                        <b>Bank : </b>{item.Bank}<br />
                                                                        <b>Cheque No : </b>{item.Cheque_No}<br />
                                                                        <b>Cheque Date : </b>{dayjs(item.Cheque_Date).format("DD/MM/YYYY")}<br />
                                                                    </>
                                                                )}
                                                        </>) : (<>--</>)}
                                                </td>
                                                <td className="text-center" style={{ padding: "10px" }}>
                                                    {item.Payment_Doc ? (
                                                        <>
                                                            <a style={{ color: "blue" }} href={`${apiUrl}/paymentDocView/` + item.Payment_Doc} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                                                <Visibility fontSize="medium" />
                                                            </a> /
                                                            <a style={{ color: "blue" }} href={`${apiUrl}/paymentDoc/` + item.Payment_Doc} download className="cursor-pointer">
                                                                <DownloadIcon fontSize="medium" />
                                                            </a></>
                                                    ) : (
                                                        <a style={{ color: "grey" }}>
                                                            --
                                                        </a>
                                                    )}
                                                </td>
                                                <td className="text-center" style={{ padding: "10px" }}>
                                                    <a
                                                        id="punchStatus"
                                                        style={{ cursor: "pointer" }}
                                                        className={`badge rounded-pill ${item.IsActive === "Active"
                                                            ? "badge badge-success text-white"
                                                            : item.IsActive === "Pending"
                                                                ? "badge badge-warning text-white"
                                                                : item.IsActive === "Closed"
                                                                ? "badge badge-danger text-white"
                                                                : "bg-primary-subtle text-primary"
                                                            } rounded-pill`}
                                                    >
                                                        {item.IsActive}
                                                    </a>
                                                </td>
                                                <td className="text-left" style={{ padding: "10px" }}>
                                                    <Tooltip title={item.Remarks} style={{ cursor: "pointer" }}>
                                                        {item.Remarks}
                                                    </Tooltip>
                                                </td>
                                                <td>
                                                    {item.IsActive === "Pending" && (
                                                        <div className="dropdown">
                                                            <a type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i>
                                                                    <MoreVertIcon style={{ color: "grey", fontSize: "18px", cursor: "pointer", }} />
                                                                </i>
                                                            </a>
                                                            <div className="dropdown-menu">
                                                                {item.Type !== "New" ? (
                                                                    <a className="dropdown-item" onClick={() => item.Type === "Topup" ? updateData(index) : upgradePlan(index)}>
                                                                        <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "}
                                                                        Edit
                                                                    </a>) : (
                                                                    <Link className="dropdown-item" to={`/updateCustomer/edit/${item.Customer_Id}`} target="_blank">
                                                                        <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "}
                                                                        Edit
                                                                    </Link>
                                                                )}
                                                                {/* {item.IsActive === "Active" && (
                                                               
                                                            )} */}
                                                            </div>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            className="text-center text-danger"
                                            colspan={7}
                                        >
                                            Data Not Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                isModal && (
                    <>
                        {console.log(data[indexValue])}
                        <TopUpInvestment
                            open={isModal}
                            onClose={closed}
                            detail={data[indexValue]}
                            type="Update"
                            customKey="NoVerify"
                        />
                    </>
                )
            }
            {
                isUpgrade && (
                    <>
                        <UpgradePlan
                            open={isUpgrade}
                            onClose={closeUpgradePlan}
                            detail={data[indexValue]}
                            type="Update"
                            plans={planDrop}
                            customKey="NoVerify"
                        />
                    </>
                )
            }
            {loader}
        </div>
    );
};

export default InvestmentLog;
