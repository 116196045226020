import React from "react";
import "./App.css";
import Dashboard from "./componets/dashboard";
import { UserProvider } from "./componets/UserContext";
import PageNotFound from "./componets/404";
import Login from "./componets/Login";
import Home from "./pages/Home/HomeDashboard";
import Calendar from './pages/Home/Calendar';
import Agreement from './pages/Agreement/agreement';
import Renewal_Agreement from './pages/Agreement/Renewal_Agreement';

import Customer from "./pages/Customers/ViewCustomer";
import AddCustomer from "./pages/Customers/AddCustomer";
import UpdateCustomer from "./pages/Customers/UpdateCustomer";
import CloseCustomerAccount from "./pages/Customers/CloseCustomerAccount";
import VerifyNewAccounts from "./pages/Customers/VerifyNewAccounts";
import VerifyCloseAccounts from "./pages/Customers/VerifyCloseAccounts";
import User from "./pages/User/ViewUser";
import AddUser from "./pages/User/AddUser";
import VerifyTopup from "./pages/Customers/VerifyTopup";
import VerifyUpgradation from "./pages/Customers/VerifyUpgradation";
import VerifyUser from "./pages/User/VerifyUser";
import UpdateUser from "./pages/User/updateUser";
import Plan from "./pages/Setting/Plan"
import Bank from "./pages/Setting/Bank"
import InterestRate from "./pages/Setting/InterestRates"
import Report from "./pages/Reports/Reports"
import ActivityLog from "./pages/Reports/ActivityLog"
import ActiveAccount from "./pages/Reports/ActiveCustomer"
import ClosedAccount from "./pages/Reports/ClosedCustomer"
import Receipt  from "./pages/Reports/Receipt"
import LedgerReport  from "./pages/Reports/Ledger_Report"
import ViewPaidInterest  from "./pages/Reports/PaidInterest"
import IDProofName from "./pages/Setting/IDProofType"
import AddressProofName from "./pages/Setting/AddressProofTypes"
import PercentageType from "./pages/Setting/PercentageType"
import InvestmentPeriod from "./pages/Setting/InvestmentPeriod"
import CustomerWiseDashBoard from "./pages/Customers/CustomerWiseDashBoard"
import WhatsappTemplate from "./pages/Setting/WhatsappTemplate";
import ManualWhatsapp from "./pages/Setting/ManualWhatsapp";
import PayWithdrawalAmount from "./pages/Reports/CloseAccountWithdrawal";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <UserProvider>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Dashboard />}>
            <Route path="" element={<Home />} />
            {/* <Route path="*" element={<PageNotFound />} /> */}
            <Route path="/addUser" element={<AddUser />} />
            <Route path="/viewUser" element={<User />} />
            <Route path="/updateUser/:type/:EmpId" element={<UpdateUser />} />
            <Route path="/addCustomer" element={<AddCustomer />} />
            <Route path="/viewCustomer" element={<Customer />} />
            <Route path="/updateCustomer/:type/:Sr_No" element={<UpdateCustomer />} />
            <Route path="/closeCustomerAccount/:type/:Sr_No" element={<CloseCustomerAccount />} />
            <Route path="/verifyNewAccounts" element={<VerifyNewAccounts />} />
            <Route path="/verifyCloseAccounts" element={<VerifyCloseAccounts />} />
            <Route path="/verifyUser" element={<VerifyUser />} />
            <Route path="/verifyTopup" element={<VerifyTopup />} />
            <Route path="/verifyUpgradation" element={<VerifyUpgradation />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/bank" element={<Bank />} />
            <Route path="/report" element={<Report />} />
            <Route path="/iDProofName" element={<IDProofName />} />
            <Route path="/addressProofName" element={<AddressProofName />} />
            <Route path="/percentageType" element={<PercentageType />} />
            <Route path="/investmentPeriod" element={<InvestmentPeriod />} />
            <Route path="/receipt" element={<Receipt />} />
            <Route path="/viewPaidInterest" element={<ViewPaidInterest />} />
            <Route path="/interestRate" element={<InterestRate />} />
            <Route path="/activeAccount" element={<ActiveAccount />} />
            <Route path="/closedAccount" element={<ClosedAccount />} />
            <Route path="/customerWiseDashBoard/:Sr_No" element={<CustomerWiseDashBoard />} />
            <Route path="/WhatsappTemplate" element={<WhatsappTemplate />} />
            <Route path="/ManualWhatsapp" element={<ManualWhatsapp />} />
            <Route path="/PayWithdrawalAmount/:date" element={<PayWithdrawalAmount />} />
            <Route path="/activityLog" element={<ActivityLog />} />
            <Route path="/ledgerReport" element={<LedgerReport />} />
            <Route path="/FullCalendar" element={<Calendar />} />
            <Route path="/Fresh_Agreement" element={<Agreement />} />
            <Route path="/Renewal_Agreement" element={<Renewal_Agreement />} />


          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
