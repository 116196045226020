import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../componets/apiConfig";
import axios from "axios";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import dayjs from "dayjs";
import Calendar from "./Calendar";

const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
};


const HomeDashboard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [clientCountsExSum, setClientCountsExSum] = useState({
    TotalCustomerCount: 0,
    ActiveCustomerCount: 0,
    InactiveCustomerCount: 0,
    PaidInterestAmount: 0,
    PaidCapitalAmount: 0,
    InvestmentAmount: 0,
    CloseCustomerCount: 0
  });
  const [filter, setFilter] = useState("ThisMonth");
  const [newpending, setNewPending] = useState(0);
  const [closepending, setClosePending] = useState(0);
  const [pendingUser, setPendingUser] = useState(0);
  const [pendingTopup, setPendingTopup] = useState(0);
  const [closedCpaitalAmount, setClosedCpaitalAmount] = useState(0);
  const [getCustCount, setCustCount] = useState([]);
  const [data, setAttendanceData] = useState([]);
  const [dataTillDate, setAttendanceDataTillDate] = useState([]);
  const [investmentLog, setInvetmentLog] = useState([]);
  const [filterr, setFilterr] = useState({
    Investment_Type_Id: "-1",
    Investment_Percentage_Type: "-1"
  });
  const [tillDateInterestAmount, setTillDateInterestAmount] = useState(0);
  const [InterestAmount, setInterestAmount] = useState(0);
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const previousMonth = currentDate.getMonth() + 1;
  const [currentMonth, setCurrentMonth] = useState(previousMonth);
  const [currentYear, setCurrentYear] = useState(currentMonth === 12 ? new Date().getFullYear() - 1 : new Date().getFullYear());
  const [monthChanged] = useState(new Date().getMonth() + 1);
  const [yearChanged] = useState(new Date().getFullYear());
  const [monthDate, setDate] = useState([]);
  const [Intrest, setIntrest] = useState([]);
  const [IntrestTillDate, setIntrestTillDate] = useState([]);

  console.log(dataTillDate)
  function calculate() {
    const interest = data.map((item) => {
      const { startDate, endDate } = getStartAndEndDates(
        currentYear,
        currentMonth
      );
      // console.log(startDate)
      // console.log(item)
      let intrest = 0, days = 0;
      let startIDate, endIDate;
      if (item.IsActive === "Active") {
        if (new Date(item.Start_Date).getMonth() + 1 === currentMonth && new Date(item.Start_Date).getFullYear() === currentYear) {
          startIDate = item.Start_Date;
          endIDate = endDate;
          intrest = calculateMIP(
            item.Start_Date,
            endDate,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Start_Date).daysInMonth()
          );
        } else if (new Date(item.Request_Date).getMonth() + 1 === currentMonth && new Date(item.Request_Date).getFullYear() === currentYear) {
          startIDate = startDate;
          endIDate = item.Request_Date;
          intrest = calculateCloseMIP(
            startDate,
            item.Request_Date,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Request_Date).daysInMonth(),
            item.Last_Payable_Int_days,
            item.IsActive
          );
        } else {
          startIDate = startDate;
          endIDate = endDate;
          intrest = calculateRegularMIP(
            item.Capital_Amount,
            item.Rate_Of_Int
          );
        }
        const matchingInterest = investmentLog.find(
          (interest) =>
            parseInt(interest.Month) === currentMonth && parseInt(interest.Year) === currentYear &&
            interest.Customer_Id === item.Sr_No &&
            interest.Amount === parseFloat(intrest.toFixed(2))
        );
        let IntrestPaid = matchingInterest ? 1 : 0;
        return {
          Enrollment_No: item.Enrollment_No,
          Customer_Id: item.Sr_No,
          Plan: item.Investment_Type,
          Start_Date: item.Start_Date,
          startDate: startIDate,
          enddate: endIDate,
          First_Name: item.First_Name,
          Middle_Name: item.Middle_Name,
          Last_Name: item.Last_Name,
          IsActive: item.IsActive,
          Request_Date: item.Request_Date,
          InterestAmount: intrest,
          Mobile: item.Mobile,
          Rate_Of_Int: item.Rate_Of_Int,
          Capital_Amount: item.Capital_Amount,
          IntrestPaid: IntrestPaid,
          Percentage_Type: item.Percentage_Type
        }
      }
      else if (item.IsActive === "Closed") {
        let i = new Date(item.Request_Date).getMonth() + 1;
        if (new Date(item.Request_Date).getMonth() + 1 === currentMonth && new Date(item.Request_Date).getFullYear() === currentYear) {

          startIDate = startDate;
          endIDate = item.Request_Date;
          intrest = calculateCloseMIP(
            startDate,
            item.Request_Date,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Request_Date).daysInMonth(),
            item.Last_Payable_Int_days,
            item.IsActive
          );
          const matchingInterest = investmentLog.find(
            (interest) =>
              parseInt(interest.Month) === currentMonth && parseInt(interest.Year) === currentYear &&
              interest.Customer_Id === item.Sr_No &&
              interest.Amount === parseFloat(intrest.toFixed(2))
          );
          let IntrestPaid = matchingInterest ? 1 : 0;
          return {
            Enrollment_No: item.Enrollment_No,
            Customer_Id: item.Sr_No,
            Plan: item.Investment_Type,
            Start_Date: item.Start_Date,
            startDate: startIDate,
            enddate: endIDate,
            First_Name: item.First_Name,
            Middle_Name: item.Middle_Name,
            Last_Name: item.Last_Name,
            IsActive: item.IsActive,
            Request_Date: item.Request_Date,
            InterestAmount: intrest,
            Mobile: item.Mobile,
            Rate_Of_Int: item.Rate_Of_Int,
            Capital_Amount: item.Capital_Amount,
            Percentage_Type: item.Percentage_Type,
            IntrestPaid: IntrestPaid
          }
        }

        else {
          return null;
        }

      }
      else {
        return null;
      }
    }).filter(Boolean);
    return interest;
  }

  function calculateTillDateInterest() {
    const interest = dataTillDate.map((item) => {
      console.log(monthChanged, yearChanged)
      const { startDate, endDate } = getStartAndEndDates(yearChanged, monthChanged);
      const today = new Date();
      const todayDate = today.getDate();
      const todayMonth = today.getMonth() + 1;
      const todayYear = today.getFullYear();

      let intrest = 0, days = 0;
      let startIDate, endIDate;

      if (item.IsActive === "Active") {
        if (new Date(item.Start_Date).getMonth() + 1 === monthChanged && new Date(item.Start_Date).getFullYear() === yearChanged) {
          startIDate = item.Start_Date;
          endIDate = (todayYear === yearChanged && todayMonth === monthChanged) ? today : endDate;
          intrest = calculateMIP(
            item.Start_Date,
            endIDate,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Start_Date).daysInMonth()
          );
        } else if (new Date(item.Request_Date).getMonth() + 1 === monthChanged && new Date(item.Request_Date).getFullYear() === yearChanged) {
          startIDate = startDate;
          endIDate = (todayYear === yearChanged && todayMonth === monthChanged) ? today : (today < new Date(item.Request_Date) ? today : new Date(item.Request_Date));
          intrest = calculateCloseMIP(
            startDate,
            endIDate,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Request_Date).daysInMonth(),
            item.Last_Payable_Int_days,
            item.IsActive
          );
        } else {
          startIDate = startDate;
          endIDate = (todayYear === yearChanged && todayMonth === monthChanged) ? today : endDate;
          if (todayYear === yearChanged && todayMonth === monthChanged) {
            intrest = calculateMIP(
              startIDate,
              endIDate,
              item.Capital_Amount,
              item.Rate_Of_Int,
              dayjs(item.Start_Date).daysInMonth()
            );
          } else {
            intrest = calculateRegularMIP(
              item.Capital_Amount,
              item.Rate_Of_Int
            );
          }
        }

        const matchingInterest = investmentLog.find(
          (interest) =>
            parseInt(interest.Month) === monthChanged && parseInt(interest.Year) === yearChanged &&
            interest.Customer_Id === item.Sr_No &&
            interest.Amount === parseFloat(intrest.toFixed(2))
        );
        let IntrestPaid = matchingInterest ? 1 : 0;

        return {
          Enrollment_No: item.Enrollment_No,
          Customer_Id: item.Sr_No,
          Plan: item.Investment_Type,
          Start_Date: item.Start_Date,
          startDate: startIDate,
          enddate: endIDate,
          First_Name: item.First_Name,
          Middle_Name: item.Middle_Name,
          Last_Name: item.Last_Name,
          IsActive: item.IsActive,
          Request_Date: item.Request_Date,
          InterestAmount: intrest,
          Mobile: item.Mobile,
          Rate_Of_Int: item.Rate_Of_Int,
          Capital_Amount: item.Capital_Amount,
          IntrestPaid: IntrestPaid,
          Percentage_Type: item.Percentage_Type
        }
      } else if (item.IsActive === "Closed") {
        if (new Date(item.Request_Date).getMonth() + 1 === monthChanged && new Date(item.Request_Date).getFullYear() === yearChanged) {
          startIDate = startDate;
          endIDate = (todayYear === yearChanged && todayMonth === monthChanged) ? today : (today < new Date(item.Request_Date) ? today : new Date(item.Request_Date));
          intrest = calculateCloseMIP(
            startDate,
            endIDate,
            item.Capital_Amount,
            item.Rate_Of_Int,
            dayjs(item.Request_Date).daysInMonth(),
            item.Last_Payable_Int_days,
            item.IsActive
          );
          const matchingInterest = investmentLog.find(
            (interest) =>
              parseInt(interest.Month) === monthChanged && parseInt(interest.Year) === yearChanged &&
              interest.Customer_Id === item.Sr_No &&
              interest.Amount === parseFloat(intrest.toFixed(2))
          );
          let IntrestPaid = matchingInterest ? 1 : 0;
          return {
            Enrollment_No: item.Enrollment_No,
            Customer_Id: item.Sr_No,
            Plan: item.Investment_Type,
            Start_Date: item.Start_Date,
            startDate: startIDate,
            enddate: endIDate,
            First_Name: item.First_Name,
            Middle_Name: item.Middle_Name,
            Last_Name: item.Last_Name,
            IsActive: item.IsActive,
            Request_Date: item.Request_Date,
            InterestAmount: intrest,
            Mobile: item.Mobile,
            Rate_Of_Int: item.Rate_Of_Int,
            Capital_Amount: item.Capital_Amount,
            Percentage_Type: item.Percentage_Type,
            IntrestPaid: IntrestPaid
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).filter(Boolean);
    return interest;
  }


  useEffect(() => {

    showLoader();
    if (data.length > 0) {
      setIntrest(calculate());
      setIntrestTillDate(calculateTillDateInterest());
    }
    hideLoader();
  }, [data]);



  const calculateDaysDifference = (startDate, endDate) => {
    const start = dayjs(startDate).startOf("day");
    const end = dayjs(endDate).startOf("day");
    const daysDifference = end.diff(start, "day");
    //console.log(daysDifference)
    return daysDifference;
  };

  const calculateMIP = (
    startDate,
    endDate,
    principalAmount,
    monthlyInterestRate,
    monthDayscount
  ) => {
    const countDays = calculateDaysDifference(startDate, endDate);
    const updatedDays = countDays + 1;
    const InterestAmount = (principalAmount * monthlyInterestRate) / 100;
    const monthlyPayout = (InterestAmount * updatedDays) / monthDayscount;

    return monthlyPayout;
  };

  const calculateRegularMIP = (principalAmount, monthlyInterestRate) => {
    const monthlyPayout = (principalAmount * monthlyInterestRate) / 100;

    return monthlyPayout;
  };

  const calculateCloseMIP = (
    startDate,
    endDate,
    principalAmount,
    monthlyInterestRate,
    monthDayscount,
    LastPayableIntdays,
    isActive
  ) => {
    const countDays = calculateDaysDifference(
      new Date(startDate),
      new Date(endDate)
    );
    let updatedDays = 0;
    debugger;
    if (isActive !== "Closed") {
      updatedDays = countDays + 1;
    }
    else {
      updatedDays = countDays + 1 - LastPayableIntdays;
    }
    const InterestAmount = (principalAmount * monthlyInterestRate) / 100;
    const monthlyPayout = (InterestAmount * updatedDays) / monthDayscount;

    return monthlyPayout;
  };

  function getDaysInMonth(month, year) {
    var date = new Date(year, month - 1, 1);
    var days = [];

    while (date.getMonth() === month - 1) {
      days.push({
        mDate: new Date(date).toLocaleDateString("en-GB"),
        Day: new Date(date).toLocaleDateString("en-GB", { weekday: "long" }),
      });
      date.setDate(date.getDate() + 1);
    }

    return days;
  }

  useEffect(() => {
    showLoader();
    // Calculate total interest when data changes
    const totalInterest = Intrest.reduce((total, item) => {

      return total + parseFloat(item.InterestAmount);
    }, 0);

    setInterestAmount(totalInterest);

    const totalInteresttillDate = IntrestTillDate.reduce((total, item) => {

      return total + parseFloat(item.InterestAmount);
    }, 0);

    setTillDateInterestAmount(totalInteresttillDate);
    //console.log(totalInterest);
    hideLoader();
  }, [Intrest, IntrestTillDate, currentMonth, currentYear, monthChanged, yearChanged]);


  useEffect(() => {
    if (userData && userData.Org_Id) {
      Load();
    }
  }, [userData]);

  const Load = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getCustomersbyMonthYear?OrgId=${userData.Org_Id}&Plan=${filterr.Investment_Type_Id}&Month=${currentMonth}&Year=${currentYear}&Type=${filterr.Investment_Percentage_Type}`
      );
      const resultTillDate = await axios.get(
        `${apiUrl}/getCustomersbyMonthYear?OrgId=${userData.Org_Id}&Plan=${filterr.Investment_Type_Id}&Month=${monthChanged}&Year=${yearChanged}&Type=${filterr.Investment_Percentage_Type}`
      );
      const res = await axios.get(
        `${apiUrl}/getInterestPaymentLogInReport?OrgId=${userData?.Org_Id}`
      );
      const Customer = await axios.get(
        `${apiUrl}/getCustomerCountByPlan?OrgId=${userData?.Org_Id}`
      );
      setInvetmentLog(res.data)
      setCustCount(Customer.data)
      //console.log(result);
      if (result.data.length === 0) {
        setAttendanceData([]);
        setAttendanceDataTillDate([])
        setIntrest([]);
        hideLoader();
      } else {
        setAttendanceData(result.data);
        setAttendanceDataTillDate(resultTillDate.data);
        //console.log(resultTillDate.data);
        setDate(getDaysInMonth(currentMonth, currentYear));
        hideLoader();
      }
    } catch (error) {
      hideLoader();
      setAttendanceData([]);
    }
  };

  function getStartAndEndDates(year, month) {
    // Ensure month value is 0-indexed (January is 0, February is 1, and so on)
    const monthIndex = month - 1;

    // Get the start date of the specified month and year
    const startDate = dayjs()
      .year(year)
      .month(monthIndex)
      .startOf("month")
      .format("YYYY-MM-DD");

    // Get the end date of the specified month and year
    const endDate = dayjs()
      .year(year)
      .month(monthIndex)
      .endOf("month")
      .format("YYYY-MM-DD");

    return {
      startDate,
      endDate,
    };
  }

  useEffect(() => {
    (async () => {
      handleFilterButton();
    })();
  }, [userData, filter]);


  const handleFilterButton = async () => {
    showLoader();
    if (userData && userData.Org_Id && filter) {
      const result = await axios.get(
        `${apiUrl}/getCustomerDashboard?OrgId=${userData.Org_Id}&condition=${filter}`
      );
      setClientCountsExSum(result.data);
    }
    hideLoader();
  };



  useEffect(() => {
    if (userData && userData.Org_Id && filter) {
      Loadd();
    }
  }, [userData, filter]);


  //console.log(clientCountsExSum)
  const Loadd = async () => {

    showLoader();
    const result = await axios.get(
      `${apiUrl}/getCustomerDashboard?OrgId=${userData.Org_Id}&condition=${filter}`
    );
    setClientCountsExSum(result.data);
    hideLoader();

  };

  useEffect(() => {
    if (userData && userData.Org_Id) {
      getAmount();
    }
    if (userData && userData.Org_Id && userData.role) {
      Pending();
    }
  }, [userData]);

  const getAmount = async () => {
    try {
      showLoader();

      const closedCpaitalAmount = await axios.get(
        `${apiUrl}/getCloedCapitalAmount?OrgId=${userData.Org_Id}`
      );
      setClosedCpaitalAmount(closedCpaitalAmount.data.TotalCapitalAmount);

      hideLoader();
    } catch (error) {
      console.error(error);
    }
  };

  const Pending = async () => {
    try {
      showLoader();
      const result = await axios.get(
        `${apiUrl}/getUnverifiedNewAccountCount?OrgId=${userData.Org_Id}&Role=${userData.role}`
      );
      const res = await axios.get(
        `${apiUrl}/getUnverifiedColseAccountsCount?OrgId=${userData.Org_Id}&Role=${userData.role}`
      );
      const re = await axios.get(
        `${apiUrl}/getUnverifiedNewUserCount?OrgId=${userData.Org_Id}&Role=${userData.role}`
      );

      const Topupres = await axios.get(
        `${apiUrl}/getUnverifiedTopupCount?OrgId=${userData.Org_Id}&Role=${userData.role}`
      );
      setNewPending(result.data.UnverifiedCount);
      setPendingUser(re.data.UnverifiedUserCount);
      setClosePending(res.data.UnverifiedAccountsCount);
      setPendingTopup(Topupres.data.UnverifiedCount);
      // console.log(userData.role);
      hideLoader();
    } catch (error) {
      console.error(error);
    }
  };

  const CountWithCommas = (number) => {
    if (number === null || isNaN(number)) {
      return "0";
    }
    return number.toLocaleString();
  };

  const handelChange = (e) => {
    setFilter(e);
  }

  // Count of IntrestPaid equal to 0
  const countIntrestPaidZero = Intrest.filter(item => item.IntrestPaid === 0).length;

  // Count of IntrestPaid equal to 1
  const countIntrestPaidOne = Intrest.filter(item => item.IntrestPaid === 1).length;

  let totalPaidAmount = 0;
  let totalUnpaidAmount = 0;

  // Loop through the array to calculate the total amounts
  Intrest.forEach(item => {
    if (item.IntrestPaid === 1) {
      totalPaidAmount += item.InterestAmount;
    } else {
      totalUnpaidAmount += item.InterestAmount;
    }

  });




  return (
    <div className="container-fluid" id="container-wrapper">
      <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column mb-2">
        <h5 className="page-title">Dashboard</h5>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column mb-2">
            <h5 className="page-title"></h5>
            <div className="col-md-2">
              <select
                className="form-control"
                id="example-select"
                onChange={(e) => handelChange(e.target.value)}
              >
                <option value="OverAll" label="Overall"></option>
                <option value="ThisMonth" label="This Month" selected></option>
                <option value="LastMonth" label="Last Month"></option>
                <option value="Today" label="Today"></option>
                <option value="Yesterday" label="Yesterday"></option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/viewCustomer" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          Total Customer/Active
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {CountWithCommas(clientCountsExSum.TotalCustomerCount)}/ {CountWithCommas(clientCountsExSum.ActiveCustomerCount)}
                        </div>
                        {/*<div className="mt-2 mb-0 text-muted text-xs">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up"></i> 3.48%
                    </span>
                    <span>Since last month</span>
  </div>*/}
                      </div>
                      <div className="col-auto">
                        <i className="fas  fa-users fa-2x text-primary"></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/viewCustomer" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          Close Req./Inactive
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {CountWithCommas(clientCountsExSum.InactiveCustomerCount)}/{CountWithCommas(clientCountsExSum.CloseCustomerCount)}
                        </div>
                        {/*<div className="mt-2 mb-0 text-muted text-xs">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up"></i> 3.48%
                    </span>
                    <span>Since last month</span>
  </div>*/}
                      </div>
                      <div className="col-auto">

                        <i className="fas fa-users fa-2x text-danger" style={{ opacity: 0.5 }}></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/activeAccount" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          Invested Amount
                        </div>
                        <div className="h5 mb-0  mt-2 font-weight-bold text-gray-800">
                          ₹{CountWithCommas(clientCountsExSum.InvestmentAmount)}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-rupee-sign fa-2x text-danger"></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/viewPaidInterest" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          Paid Interest
                        </div>
                        <div className="h5 mb-0  mt-2 font-weight-bold text-gray-800">
                          ₹{CountWithCommas(clientCountsExSum.PaidInterestAmount)}
                        </div>
                      </div>
                      <div className="col-auto">

                        <i className="fas fa-hands-helping fa-2x text-info"></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> </div> </div> </div>

      <div className="card ">
        <div className="card-body">
          <div className="row ">
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <Link to={`/PayWithdrawalAmount/${new Date().toISOString()}`} style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          Payable Capital Amount
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {parseFloat(closedCpaitalAmount).toFixed(2)}
                        </div>
                      </div>
                      <div className="col-auto">

                        <i className="fas fa-wallet fa-2x " style={{ opacity: 0.6, color: "brown" }}></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/report" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          current Month Interest
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {parseFloat(tillDateInterestAmount).toFixed(2)}
                        </div>
                      </div>
                      <div className="col-auto">

                        <i className="fas fa-comments-dollar fa-2x text-info" style={{ opacity: 0.6 }}></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/verifyNewAccounts" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          New Account Verification
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {CountWithCommas(newpending)}{" "}
                          <span style={{ fontSize: "16px" }}>Pending</span>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-exclamation-circle fa-2x text-success"></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <Link
                    to="/verifyCloseAccounts"
                    style={{ textDecoration: "none", color: "#757575" }}
                  >
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1 ">
                          Closure Verification
                        </div>
                        <div className="h5 mb-0 mt-2 mr-3 font-weight-bold text-gray-800">
                          {CountWithCommas(closepending)}{" "}
                          <span style={{ fontSize: "16px" }}>Pending</span>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-exclamation-triangle fa-2x text-warning"></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {(userData?.role === "Admin" || userData?.role === "Supervisor") && (
              <div className="col-xl-3 col-md-6 mb-3">
                <div className="card h-100">
                  <div className="card-body">
                    <Link
                      to="/verifyUser"
                      style={{ textDecoration: "none", color: "#757575" }}
                    >
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase mb-1 ">
                            User Verification
                          </div>
                          <div className="h5 mb-0 mt-2 mr-3 font-weight-bold text-gray-800">
                            {CountWithCommas(pendingUser)}{" "}
                            <span style={{ fontSize: "16px" }}>Pending</span>
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-user-clock fa-2x text-warning" style={{ opacity: 0.5 }}></i>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>)}
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <Link
                    to="/verifyTopup"
                    style={{ textDecoration: "none", color: "#757575" }}
                  >
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1 ">
                          Topup Verification
                        </div>
                        <div className="h5 mb-0 mt-2 mr-3 font-weight-bold text-gray-800">
                          {CountWithCommas(pendingTopup)}{" "}
                          <span style={{ fontSize: "16px" }}>Pending</span>
                        </div>
                      </div>
                      <div className="col-auto">

                        <i className="far fa-money-bill-alt fa-2x text-primary" style={{ opacity: 0.5 }}></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div> </div>

      </div>
      <div className="card ">
        <div className="card-body">
          <h6 style={{ fontWeight: "bold" }}>Intrest details of previous month</h6>
          <div className="row ">
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/report" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          Total Interest
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {parseFloat(InterestAmount).toFixed(2)} / {Intrest.length}
                        </div>
                      </div>

                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/report" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          Paid Interest
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {parseFloat(totalPaidAmount).toFixed(2)} / {countIntrestPaidOne}
                        </div>
                      </div>

                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <Link to="/report" style={{ textDecoration: "none", color: "#757575" }}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          Unpaid Interest
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {parseFloat(totalUnpaidAmount).toFixed(2)} / {countIntrestPaidZero}
                        </div>
                      </div>

                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div> </div> </div>
      <div className="card ">
        <div className="card-body">
          <h6 style={{ fontWeight: "bold" }} >Plan Wise Customer Count</h6>
          <div className="row ">
            {getCustCount.map(plan => (
              <div className="col-xl-3 col-md-6 mb-3" key={plan.Plan_Id}>
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-uppercase mb-1">
                          {plan.Plan_Name}
                        </div>
                        <div className="h5 mb-0 mt-2 font-weight-bold text-gray-800">
                          {CountWithCommas(plan.Customer_Count)}
                        </div>
                      </div>
                      <div className="col-auto">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
      <>
        <Calendar />
      </>

      {loader}
    </div>
  );
};

export default HomeDashboard;
