import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import { apiUrl } from "./apiConfig";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">
        Masys
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function validateForm(values) {
  const errors = {};

  if (!values.Username) {
    errors.Username = "Enter Username";
  }

  if (!values.Password) {
    errors.Password = "Enter Password";
  }

  return errors;
}

const defaultTheme = createTheme();

const Login = () => {
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState({
    Username: "",
    Password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;
  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });
  const [loginError, setLoginError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const formErrors = validateForm(values);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setLoading(true);
      axios
        .post(`${apiUrl}/login`, values)
        .then((res) => {
          if (res.data.Status === "Success") {
            navigate("/");
            setLoading(false);
          } else {
            setLoginError("Login Failed. Please check your Credentials.");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoginError("An error occurred during login.");
        });
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="container-login">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-4 mt-5">
            <div className="card shadow-sm ">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="login-form">
                      <div className="brand-logo"  align="center">
                        <img style={{width:"125px"}} src="/img/loginLogo.png" alt="logo" />
                      </div>
                      <h5 align="center" className="mt-1">
                        <b>Sign In</b>
                      </h5>
                      {loginError && (
                        <Typography
                          variant="body2"
                          color="error"
                          sx={{ mt: 1 }}
                        >
                          {loginError}
                        </Typography>
                      )}

                      <form className="pt-3" onSubmit={handleSubmit}>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="username"
                          label="Username"
                          name="username"
                          autoComplete="username"
                          autoFocus
                          onChange={(e) =>
                            setValues({ ...values, Username: e.target.value })
                          }
                          error={Boolean(errors.Username)}
                          helperText={errors.Username}
                          sx={{ width: "100%" }}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          id="password"
                          autoComplete="current-password"
                          onChange={(e) =>
                            setValues({ ...values, Password: e.target.value })
                          }
                          error={Boolean(errors.Password)}
                          helperText={errors.Password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <i
                                style={{cursor:"pointer"}}
                                  aria-label="toggle password visibility"
                                  onClick={handleTogglePasswordVisibility}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff  />
                                  ) : (
                                    <Visibility />
                                  )}
                                </i>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ width: "100%" }}
                        />
                        {!isLoading && (
                          <Button
                          className="btn btn-primary"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, width: "100%" }}
                          >
                            Sign In
                          </Button>
                        )}
                        {isLoading && (
                          <Button
                          className="btn btn-primary"
                            type="submit"
                            fullWidth
                            disabled
                            variant="contained"
                            sx={{ mt: 3, mb: 2, width: "100%" }}
                          >
                            <CircularProgress size={21} color="inherit" />
                            {"  "} Sign In...
                          </Button>
                        )}

                        <Copyright sx={{ mt: 5 }} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Login;
