import React from "react";
import { Modal } from "react-bootstrap";
import useFullPageLoader from "../../mui/useFullPageLoader";

const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
};

const CustomerPreview = ({ open, onClose, data }) => {
    const handleClose = () => {
        onClose(false);
    };

   // console.log(data)
    return (
        <div>
            <Modal
                show={open}
                onHide={handleClose}
                size="lg"
                centered
                style={{ boxShadow: "none !important" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "17px" }}>Plan Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className=""
                        style={{ padding: "0 10px" }}
                    >
                        <div className="row">
                            <div className="col-md-4">
                                <p>
                                    <span>Plan Name </span>: {data?.Plan_Name}
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p>
                                    <span>Plan Prefix</span>: {data?.Plan_Prefix}
                                </p>
                            </div>

                            <div className="col-md-4">
                                <p>
                                    <span>Capital Amount</span>: {data?.Capital_Amount}
                                </p>
                            </div>

                            <div className="col-md-4">
                                <p>
                                    <span>Interest Rate </span>: {parseFloat(data?.Interest_Rate).toFixed(2)}%
                                </p>
                            </div>
                            {/* <div className="col-md-4">
                                <p>
                                    <span>Last Payable Int. days </span>: {data?.Last_Payable_Int_days}
                                </p>
                            </div>

                            <div className="col-md-4">
                                <p>
                                    <span>Capital Return Days </span>: {data?.Capital_Return_Days}
                                </p>
                            </div> */}

                            <div className="col-md-6">
                                <p>
                                    <span>Bank Name </span>: {data?.Bank_Name}
                                </p>
                            </div>
                           
                            <div className="col-md-4">
                                <p>
                                    <span>Branch Name</span>: {data?.Branch_Name}
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p>
                                    <span>IFSC Code </span>: {data?.IFSC_Code}
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p>
                                    <span>A/c No </span>: {data?.Acc_No}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <span>A/c Name </span>: {data?.Acc_Holder_Name}
                                </p>
                            </div>
                          

                            <div className="col-md-4">
                                <p>
                                    <span>Payout Interest Date (Day) </span>: {new Date(data?.Payout_Int_Date).getDate()}
                                </p>
                            </div>
                            <div className="col-md-12">
                                <p>
                                    <span>Description </span>: {data?.Description}
                                </p>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CustomerPreview;
