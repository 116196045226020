import React, { useState, useEffect } from 'react';
import "./CustomerPreview.css";
import { Modal } from "react-bootstrap";
import useFullPageLoader from "../../mui/useFullPageLoader";
import FileDownloadOffIcon from "@mui/icons-material/LinkOff";
import DownloadIcon from "@mui/icons-material/Visibility";
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import { apiUrl } from "../../componets/apiConfig";


const CustomerPreview = ({ open, close, data }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        close(false);
    };
    const handleSubmit = () => {
        setShow(false);
        close(true);
    };


    const Download = (file, name) => {
        debugger;
        if (file && name) {
            const blob = new Blob([file], { type: file.type });
            const url = URL.createObjectURL(blob);
    
            // Create a temporary anchor element to view the file
            const a = document.createElement('a');
            a.href = url;
            a.target = "_blank"; // Open in a new tab
            //a.download = name;
            document.body.appendChild(a);
            a.click();
    
            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    }


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Customer Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="bootstrap snippets bootdey" style={{ padding: "0 10px" }}>
                    <div className="row">
                        <div className="profile-nav col-md-3">
                            <div className="card">
                                <div className="user-heading round">
                                    <a href="#">
                                        {data.Photo ? (
                                            <img src={URL.createObjectURL(data.Photo)} alt="user" />) :
                                            (<img src="/img/dummy.png" alt="user" />)}
                                    </a>
                                    <h6>{data.First_Name} {data.Middle_Name} {data.Last_Name}</h6>
                                    <p>{data.Enrollment_No}</p>
                                </div>

                                <ul className="nav nav-pills nav-stacked">
                                    <li><span>Mobile No :</span> {data.Mobile}</li>
                                    <li><span>Email :</span> {data.Email}</li>
                                    <li><span>DOB :</span> {data.DOB != "" && data.DOB != null ? dayjs(data.DOB).format("DD/MM/YYYY") : (<></>)}</li>
                                    <li><span>Gender :</span> {data.Gender}</li>
                                    <li><span>Marital Status :</span> {data.Marital_Status}</li>
                                    <li><span>Pan No :</span> {data.Pan_No}</li>
                                    <li><span>Aadhar No :</span> {data.Aadhar_No}</li>
                                    <li><span>Reference Type :</span> {data.Reference_Type}</li>
                                    {data.Reference_Type == "Reference" && <li><span>Reference :</span> {data.Reference}</li>}
                                    <li><span>Student :</span> {data.Student}</li>
                                    <li><span>Address:</span> {data.Address}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="profile-info col-md-9">
                            <div className="card">
                                <div className="card-body bio-graph-info">
                                    <h5>Investment Detail</h5>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p><span>Investment Type : </span> {data.Investment_Type}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <p><span>Investment Date : </span> {data.Investment_Date != "" && data.Investment_Date != null ? dayjs(data.Investment_Date).format("DD/MM/YYYY") : (<></>)}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <p><span>Capital Amount : </span> {data.Capital_Amount}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <p><span>Start Date : </span>{data.Start_Date != "" && data.Start_Date != null ? dayjs(data.Start_Date).format("DD/MM/YYYY") : (<></>)}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <p><span>Mode Of Investment : </span> {data.Mode_Of_Investment}</p>
                                        </div>
                                    
                                        {data.Mode_Of_Investment !== "Cash" && data.Mode_Of_Investment !== "Cheque" &&
                                       
                                            <div className="col-md-12">
                                                <p><span>Transaction ID : </span> {data.Reference_No}</p>
                                            </div>}
                                        {data.Mode_Of_Investment === "Cheque" &&
                                            <>
                                                <div className="col-md-4">
                                                    <p><span>Bank Name : </span> {data.Bank}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p><span>Branch Name : </span> {data.Branch_Name}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p><span>Cheque No : </span> {data.Cheque_No}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p><span>Cheque Date : </span> {data.Cheque_Date != "" && data.Cheque_Date != null ? dayjs(data.Cheque_Date).format("DD/MM/YYYY") : (<></>)}</p>
                                                </div>
                                            </>}
                                            
                                        <div className={data.Percentage_Type === "Fixed" || data.Mode_Of_Investment === "Cheque" ? "col-md-4" : "col-md-6"}>
                                            <p><span>Investment Period : </span> {data.Investment_Period}</p>
                                        </div>
                                        <div className={data.Percentage_Type === "Fixed" || data.Mode_Of_Investment === "Cheque" ? "col-md-4" : "col-md-6"}>
                                            <p><span>Percentage Type : </span> {data.Percentage_Type}</p>
                                        </div>
                                        {data.Percentage_Type === "Fixed" && (<div className="col-md-4">
                                            <p><span>Rate Of Interest : </span> {data.Rate_Of_Int}%</p>
                                        </div>)}
                                        </div>
                                        {data.Percentage_Type === "Slabwise" && (
                                            <div> {data.SlabWiseInterest.map((slab, index) => (
                                                <div key={index} className="row">
                                                   {/* <div className="col-md-4">
                                                        <p><span>From Amount : </span> {slab.From_Amount}</p>
                                            </div>*/}
                                                    <div className="col-md-6">
                                                        <p><span>Slab Amount : </span> {slab.To_Amount}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p><span>Rate Of Interest : </span> {slab.Interest}%</p>
                                                    </div>  </div>))}</div>
                                        )}
                                        <div className="row">
                                        <div className="col-md-12">
                                            <p><span>Remarks : </span> {data.Remarks}</p>
                                        </div>   </div>
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5>Bank Detail</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p><span>Bank Name : </span> {data.Bank_Name}</p>
                                                </div>
                                                <div className="col-md-12">
                                                    <p><span>IFSC Code : </span> {data.IFCE_Code}</p>
                                                </div>
                                                <div className="col-md-12">
                                                    <p><span>Account No : </span> {data.Account_No}</p>
                                                </div>
                                                <div className="col-md-12">
                                                    <p><span>Account Holder : </span> {data.Account_Holder_Name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5>Nominee Detail</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p><span>Full Name : </span> {data.Nominee_Full_Name}</p>
                                                </div>
                                                <div className="col-md-12">
                                                    <p><span>DOB : </span>{data.Nominee_DOB != "" && data.Nominee_DOB != null ? dayjs(data.Nominee_DOB).format("DD/MM/YYYY") : (<></>)} |
                                                        {data.Nominee_Birth_Proof ? (
                                                            <a style={{ color: "blue" }} onClick={() => Download(data.Nominee_Birth_Proof, "Nominee_Birth_Proof.png")}
                                                                className="cursor-pointer"><DownloadIcon style={{cursor:'pointer'}}/>
                                                            </a>) : (<a style={{ color: "grey" }}><FileDownloadOffIcon /></a>)}
                                                    </p>

                                                </div>
                                                <div className="col-md-12">
                                                    <p><span>Photo : </span>
                                                        {data.Nominee_Photo ? (
                                                            <a style={{ color: "blue" }} onClick={() => Download(data.Nominee_Photo, "Nominee_Photo.png")}
                                                                className="cursor-pointer"><DownloadIcon style={{cursor:'pointer'}}/>
                                                            </a>) : (<a style={{ color: "grey" }}><FileDownloadOffIcon /></a>)}
                                                    </p>
                                                </div>
                                                <div className="col-md-12">
                                                    <p><span>Nominee Address Proof : </span>
                                                        {data.Nominee_Address_Proof ? (
                                                            <a style={{ color: "blue" }} onClick={() => Download(data.Nominee_Address_Proof, "Nominee_Address_Proof.png")}
                                                                className="cursor-pointer"><DownloadIcon style={{ cursor: 'pointer' }} />
                                                            </a>) : (<a style={{ color: "grey" }}><FileDownloadOffIcon /></a>)}
                                                    </p>
                                                </div>
                                                <div className="col-md-12">
                                                    <p><span>Signature : </span>
                                                        {data.Nominee_Sign_Attachment ? (
                                                            <a style={{ color: "blue" }} onClick={() => Download(data.Nominee_Sign_Attachment, "Nominee_Signature.png")}
                                                                className="cursor-pointer"><DownloadIcon style={{cursor:'pointer'}}/>
                                                            </a>) : (<a style={{ color: "grey" }}><FileDownloadOffIcon /></a>)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5>Documents</h5>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <p><span>ID Proof Type : </span> {data.Id_Proof_Type} |
                                                        {data.Id_Proof_Attachment ? (
                                                            <a style={{ color: "blue" }} onClick={() => Download(data.Id_Proof_Attachment, "Id_Proof_Attachment.png")}
                                                                className="cursor-pointer"><DownloadIcon style={{cursor:'pointer'}}/>
                                                            </a>) : (<a style={{ color: "grey" }}><FileDownloadOffIcon /></a>)}
                                                    </p>
                                                </div>
                                                <div className="col-md-6">
                                                    <p><span>Address Proof Type : </span> {data.Address_Proof_Type} |
                                                        {data.Address_Proof_Attachment ? (
                                                            <a style={{ color: "blue" }} onClick={() => Download(data.Address_Proof_Attachment, "Address_Proof_Attachment.png")}
                                                                className="cursor-pointer"><DownloadIcon style={{cursor:'pointer'}}/>
                                                            </a>) : (<a style={{ color: "grey" }}><FileDownloadOffIcon /></a>)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {data.Co_applicant &&
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5>Co-Applicant</h5>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p><span>Full Name : </span> {data.Co_applicant_Full_Name}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p><span>DOB : </span> {data.Co_applicant_DOB != "" && data.Co_applicant_DOB != null ? dayjs(data.Co_applicant_DOB).format("DD/MM/YYYY") : (<></>)}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p><span>Relationship : </span> {data.Co_applicant_Relationship}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p><span>Occupation : </span> {data.Co_applicant_Occupation}</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <p><span>Address : </span> {data.Co_applicant_Address}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p><span>Photo : </span> {data.Co_applicant_Photo ? (
                                                            <a style={{ color: "blue" }} onClick={() => Download(data.Co_applicant_Photo, "Co_applicant_Photo.png")}
                                                                className="cursor-pointer"><DownloadIcon style={{cursor:'pointer'}}/>
                                                            </a>) : (<a style={{ color: "grey" }}><FileDownloadOffIcon /></a>)}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p><span>Birth Proof : </span> {data.Co_applicant_Birth_Proof ? (
                                                            <a style={{ color: "blue" }} onClick={() => Download(data.Co_applicant_Birth_Proof, "Co_applicant_Birth_Proof.png")}
                                                                className="cursor-pointer"><DownloadIcon style={{cursor:'pointer'}}/>
                                                            </a>) : (<a style={{ color: "grey" }}><FileDownloadOffIcon /></a>)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-2">
                                    <button className="btn btn-primary mb-1" onClick={handleSubmit} >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loader}
            </Modal.Body>
        </Modal >
    );
}

export default CustomerPreview;
