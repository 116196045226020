import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import axios from "axios";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import TextField from "@mui/material/TextField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import "../../CSS/dataTables.bootstrap4.min.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import CapitalPayment from "./CapitalPayment";
import dayjs from "dayjs";
import Select from "react-select";


const ActivityLog = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [planData, setPlanData] = useState([]);
  const [oldData, setlanData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    EmpId: "-1",
    fromDate: dayjs().format("YYYY-MM-DD"),
    toDate: dayjs().format("YYYY-MM-DD"),
  });
  const customerLoad = async () => {
    showLoader();
    const staffResult = await axios.get(
      `${apiUrl}/getUsers?OrgId=${userData.Org_Id}`
    );
    setPlanData(staffResult.data);
    hideLoader();
  }

  const dataLoad = async () => {
    showLoader();
    const Result = await axios.get(
      `${apiUrl}/getActivityLog?OrgId=${userData.Org_Id}&UserId=${filter.EmpId}&FromDate=${filter.fromDate}&ToDate=${filter.toDate}`
    );
    //console.log(Result.data);
    // console.log(filter.EmpId);
    setData(Result.data);
    setlanData(Result.data);
    hideLoader();
  };
  useEffect(() => {
    if (userData && userData.Org_Id) {
      customerLoad();
      dataLoad();
    }
  }, [userData]);

  const filterData = () => {
    dataLoad();
  };


  const columns = [
    {
      name: "Task Date",
      selector: (row) => formatDate(row.Task_Date),
      sortable: true,
      width: "100px",
    },
    {
      name: "Role",
      selector: (row) => row.User_Role,
      sortable: true,
      width: "100px",
    },
    {
      name: "Employee Id",
      selector: (row) => row.Emp_Id,
      sortable: true,
      width: "120px",
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "Task Title",
      cell: (row) => (
        <LightTooltip title={row.Task_Title}>
          <span
            style={{
              maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Task_Title ? row.Task_Title : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "Task Description",
      cell: (row) => (
        <LightTooltip title={row.Task_Description}>
          <span
            style={{
              maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Task_Description ? row.Task_Description : "--"}
          </span>
        </LightTooltip>
      ),
    },

  ];

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };


  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };



  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));


  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Activity Log", 20, 10);

    const columns = [
      "Task_Date",
      "Employee Id",
      "Name",
      "Task Title",
      "Task Description"
    ];

    const rows = data.map((row) => [
      dayjs(row.Task_Date).format("DD/MM/YYYY"),
      row.Emp_Id,
      row.Name,
      row.Task_Title,
      row.Task_Description,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Activity Log.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Task_Date",
      "Employee Id",
      "Name",
      "Task Title",
      "Task Description"
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        dayjs(row.Task_Date).format("DD/MM/YYYY"),
        row.Emp_Id,
        row.Name,
        row.Task_Title,
        row.Task_Description,
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Activity Log");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Activity Log.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = oldData.filter((row) => {
      return (
        (row.Task_Description &&
          row.Task_Description.toLowerCase().includes(filterValue)) ||
        (row.Name &&
          row.Name.toLowerCase().includes(filterValue)) ||
        (row.Emp_Id &&
          row.Emp_Id.toLowerCase().includes(filterValue)) ||
        row?.Task_Title.toLowerCase().includes(filterValue) ||
        dayjs(row.Task_Date).format("DD/MM/YYYY").includes(filterValue) 
      );
    });
    setData(filteredData);
  }





  return (
    <div className="container-fluid">
      <div>
        <div className="row ">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
              <h5 className="page-title">Activity Log</h5>
            </div>
            <div className="card">
              <h5 className="page-title"></h5>
              <div className="card-body" style={{ paddingTop: "5px" }}>
                <div className="row justify-content-center mt-2">
                  <div className="col-4" >
                    <label htmlFor="example-select" className="form-label">
                      Employee
                    </label>
                    <Select
                      id="example-select"
                      options={[
                        { value: -1, label: 'All' },
                        ...planData.map((item, index) => ({
                          value: item.EmpId,
                          label: `${item.First_Name} ${item.Last_Name}`
                        }))
                      ]}
                      onChange={(selectedOption) => {
                        setFilter({
                          ...filter,
                          EmpId: selectedOption.value
                        });
                      }}
                    />

                  </div>


                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      From Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={filter.fromDate}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          fromDate: e.target.value,
                        });
                      }}
                    ></input>
                  </div>
                  <div className="col-4">
                    <label htmlFor="example-select" className="form-label">
                      To Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={filter.toDate}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          toDate: e.target.value,
                        });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={filterData}
                      style={{ width: "100%" }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <TextField
                    style={{ zIndex: 0 }}
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />
                  <Tooltip title="Export to PDF">
                    <i
                      className="mt-2"
                      style={{ cursor: "pointer", padding: "5px" }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      {" "}
                      <PictureAsPdfIcon />{" "}
                    </i>
                  </Tooltip>
                  <Tooltip title="Export to Excel">
                    <i
                      className="mt-2"
                      style={{ padding: "5px", cursor: "pointer" }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
                <div style={{ zIndex: 0, position: "relative" }}>
                  <DataTable
                    // title="Employee Data"
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                    customStyles={customStyles}
                    fixedHeader
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      {loader}
    </div>
  );
};

export default ActivityLog;
