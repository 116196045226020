import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Select from "react-select";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import DataTable from "react-data-table-component";
import useFullPageLoader from "../../mui/useFullPageLoader";

const InterestRates = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [Sr_No, setSrNo] = useState(null);
  const [Type, setType] = useState([]);
  const [TypeOption, SetTypeOption] = useState([]);
  const [data, setData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState("");
  const { userData } = useUser();
  const [Rate, setRate] = useState("");

  const columns = [
    {
      name: "Type",
      selector: (row) => row.Type,
      sortable: true,
    },
    {
      name: "Rate",
      selector: (row) => `${parseFloat(row.Rate).toFixed(2)}%`,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <a
            maxWidth="100px"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i>
              <MoreVertIcon
                style={{
                  color: "grey",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </i>
          </a>
          <div
            className="dropdown-menu"
            x-placement="bottom-start"
            style={{
              position: "absolute",
              willChange: "transform",
              top: "0px",
              left: "0px",
              transform: "translate3d(0px, 38px, 0px)",
            }}
          >
            <Link className="dropdown-item" onClick={() => editExpenses(row)}>
              <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "}
              Edit
            </Link>
            <Link
              className="dropdown-item"
              onClick={() => deleteUser(row.Sr_No)}
            >
              <DeleteIcon
                className="text-danger"
                style={{ fontSize: "18px" }}
              />
              Delete
            </Link>
          </div>
        </div>
      ),
      width: "80px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  useEffect(() => {
    if (userData && userData.Org_Id) {
      Load();
    }
  }, [userData]);

  const Load = async () => {
    showLoader();
    const result = await axios.get(
      `${apiUrl}/getInterestRates?OrgId=${userData.Org_Id}&Type_Id=0`
    );
    const res = await axios.get(
      `${apiUrl}/getPercentageTypes?OrgId=${userData.Org_Id}`
    );

    const option = res.data.map((option) => ({
      label: option.Percentage_Type_Name,
      value: option.Sr_No,
    }));
    SetTypeOption(option);
    const sortedData = result.data;
    //console.log(sortedData);
    setRecords(sortedData);
    hideLoader();
    return result;
  };

  async function save(event) {
    event.preventDefault();

    const error = {};

    if (Object.keys(error).length === 0) {
      try {
        showLoader();
        await axios.post(`${apiUrl}/addInterestRate`, {
          Rate: Rate,
          Type: Type.label,
          Type_Id: Type.value,
          Added_By_Id: userData.userId,
          Added_By: `${userData.firstName} ${userData.lastName}`,
          Org_Id: userData.Org_Id,
        });
        Swal.fire("Success!", "Rate has been Added.", "success");
        setError("");
        setRate("");
        setType([null]);
        setSrNo(null);
        Load();
        hideLoader();
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Failed to add Rate. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      }
    } else {
      setError(error);
    }
  }

  async function editExpenses(data) {
    setRate(data.Rate);
    setType({ label: data.Type, value: data.Type_Id });
    setSrNo(data.Sr_No);
    setEditingItem(data);
  }

  async function update(event) {
    event.preventDefault();

    try {
      const updatedData = {
        Sr_No: Sr_No,
        Rate: Rate,
        Type: Type.label,
        Type_Id: Type.value,
      };
      showLoader();
      await axios.put(`${apiUrl}/updateInterestRate/` + Sr_No, updatedData);
      setData((prevData) =>
        prevData.map((data) =>
          data.Sr_No === Sr_No ? { ...data, ...updatedData } : data
        )
      );
      setRate("");

      setType([null]);
      setSrNo(null);
      setEditingItem(null);
      Load();
      hideLoader();
      Swal.fire("Success!", "Rate updated successfully.", "success");
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Failed to Update. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  const deleteUser = (Sr_No) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No);
      }
    });
  };

  async function handleDelete(Sr_No) {
    await axios.delete(`${apiUrl}/deleteInterestRate/${Sr_No}`);
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    Load();
  }

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Interest Rate", 20, 10);

    const columns = ["Type", "Rate"];

    const rows = records.map((row) => [row.Rate, row.Type]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("InterestRate.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Type", "Rate"];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([row.Type, row.Rate]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "InterestRate");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "InterestRate.xlsx");
  };

  const [records, setRecords] = useState(data);

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Rate && row.Rate.toLowerCase().includes(filterValue)) ||
        (formatDate(row.Payout_Int_Date) &&
          formatDate(row.Payout_Int_Date).toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Interest Rate </h5>
          </div>
          <div className="card">
            <div className="card-body">
              <Box height={10} />
              <Stack>
                <form
                  className="form-horizontal"
                  onSubmit={editingItem ? update : save}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Rate">
                          Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          //type="text"
                          //className="form-control form-control-sm"
                          name="Type"
                          required
                          options={TypeOption}
                          value={Type}
                          onChange={(event) => {
                            setType(event);
                            setError("");
                          }}
                          id="Type"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Rate">
                          Rate<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="Rate"
                          required
                          value={Rate}
                          onChange={(event) => {
                            const input = event.target.value;
                            const decimalInput = input.replace(/[^0-9.]/g, "");
                            const amount = decimalInput.replace(
                              /^(\d*\.\d*)\..*$/,
                              "$1"
                            );
                            setRate(amount);
                            setError("");
                          }}
                          id="Type"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <div>
                        {editingItem ? (
                          <button
                            className="btn btn-primary mb-1"
                            type="submit"
                          >
                            Update
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary mb-1"
                            type="submit"
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </Stack>
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <Box height={10} />

              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <Box height={14} />
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  columns={columns}
                  data={records}
                  customStyles={customStyles}
                  fixedHeader
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default InterestRates;
