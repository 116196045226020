import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DownloadIcon from "@mui/icons-material/Download";
import Visibility from "@mui/icons-material/Visibility";
import { useUser } from "../../componets/UserContext";
import dayjs from "dayjs";
import { error } from "../Alerts/alert";
import Select from "react-select";

const CapitalPayment = ({ open, onClose, detail, month, year, type }) => {
  // debugger;
  const { userData } = useUser();
  const [AttatchmentFileName, setAttatchmentFileName] = useState("Choose file");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, seterrors] = useState({});
  const navigate = useNavigate();
  const [Bank, setBankOption] = useState([]);


  // startDate: '2024-02-19T05:30:00+05:30',
  //   enddate: '2024-02-29T23:59:59+05:30',
  console.log(detail);
  function getPaymentDate() {
    let date = year + "-" + month + "-08";
    let initialDate = new Date(date);

    // Adding one month to the initial date
    let newDate = new Date(
      initialDate.getFullYear(),
      initialDate.getMonth() + 1,
      initialDate.getDate()
    );

    // Handling cases where the next month doesn't have the same number of days
    while (newDate.getMonth() !== (initialDate.getMonth() + 1) % 12) {
      newDate = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate() - 1
      );
    }

    // Format the new date as YYYY-MM-DD
    let formattedNewDate = newDate.toISOString().slice(0, 10);

    return formattedNewDate;
  }

  function convertPaymentDate(date) {
    const newdate = new Date(date);
    const formattedDate = newdate.toISOString().split("T")[0];

    return formattedDate;
  }

  const [paymentDetail, setPaymentDetail] = useState({
    Enrollment_No: detail.Enrollment_No,
    Customer_Id: detail.Customer_Id,
    Start_Date: detail.Start_Date,
    Capital_Amount: detail.Capital_Amount,
    Rate_Of_Int: detail.Rate_Of_Int,
    Customer_Name: type === "Submit" ? detail.Request_By : detail.Customer_Name,
    Amount:
      type === "Submit"
        ? parseFloat(detail.Capital_Amount).toFixed(2)
        : parseFloat(detail.Capital_Amount).toFixed(2),
    Payment_Date:
      type === "Submit"
        ? convertPaymentDate(detail.Withdrawal_Amount_Date)
        : convertPaymentDate(detail.Withdrawal_Amount_Date),
    Payment_Mode:
      type === "Submit" ? detail.Withdrawal_Mode : detail.Withdrawal_Mode,
    Bank_Name: "",
    Branch_Name: "",
    Cheque_No: "",
    Cheque_Date: null,
    Reference_No: type === "Submit" ? "" : detail.Reference_No,
    Attatchment: type === "Submit" ? "" : detail.Attatchment,
    Remarks: type === "Submit" ? "" : detail.Remarks,
    From_Date: null,
    To_Date: null,
    Month: dayjs(detail.Withdrawal_Amount_Date).month() + 1,
    Year: dayjs(detail.Withdrawal_Amount_Date).year(),
    Added_By_Id: "",
    Added_By: "",
    Org_Id: "",
  });
  const handleClose = () => {
    setShow(false);
    onClose();
  };
  const handleShow = () => setShow(true);

  const downloadFile = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    debugger;

    const updatedPaymentDetail = {
      ...paymentDetail,
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName: userData.UserName,
    };
    const formdata = new FormData();
    formdata.append("Enrollment_No", paymentDetail.Enrollment_No);
    formdata.append("Customer_Id", paymentDetail.Customer_Id);
    formdata.append("Start_Date", paymentDetail.Start_Date);
    formdata.append("Capital_Amount", paymentDetail.Capital_Amount);
    formdata.append("Rate_Of_Int", paymentDetail.Rate_Of_Int);
    formdata.append("Customer_Name", paymentDetail.Customer_Name);
    formdata.append("Amount", paymentDetail.Amount);
    formdata.append("Payment_Date", paymentDetail.Payment_Date);
    formdata.append("Payment_Mode", paymentDetail.Payment_Mode);
    formdata.append("Bank_Name", paymentDetail.Bank_Name);
    formdata.append("Branch_Name", paymentDetail.Branch_Name);
    formdata.append("Cheque_No", paymentDetail.Cheque_No);
    formdata.append("Cheque_Date", paymentDetail.Cheque_Date);
    formdata.append("Reference_No", paymentDetail.Reference_No);
    formdata.append("Attatchment", paymentDetail.Attatchment);
    formdata.append("Remarks", paymentDetail.Remarks);
    formdata.append("From_Date", paymentDetail.From_Date);
    formdata.append("To_Date", paymentDetail.To_Date);
    formdata.append("Month", paymentDetail.Month);
    formdata.append("Year", paymentDetail.Year);
    formdata.append("User_Role", updatedPaymentDetail.User_Role);
    formdata.append("Emp_Id", updatedPaymentDetail.Emp_Id);
    formdata.append("UserName", updatedPaymentDetail.UserName);
    formdata.append("Added_By_Id", updatedPaymentDetail.Added_By_Id);
    formdata.append("Added_By", updatedPaymentDetail.Added_By);
    formdata.append("Org_Id", updatedPaymentDetail.Org_Id);

    const number = detail.Request_By_Mobile;

    const whatsapp = {
      Customer: paymentDetail.Customer_Name,
      Amount: paymentDetail.Amount,
      Enrollment_No: paymentDetail.Enrollment_No,
      Payment_Date: dayjs(paymentDetail.Payment_Date).format("DD/MM/YYYY"),
      Reference_No: paymentDetail.Reference_No,
      number: number,
      Email: detail.Request_By_Email,
      OrgId: userData.Org_Id,
      template:"Capital Payment"
    };

    const validationErrors = {};

    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      if (type === "Submit") {
        axios
          .post(`${apiUrl}/payBackCapitalAmount`, formdata)
          .then((res) => {
            handleClose();
            Swal.fire(
              "Success!",
              "Data Inserted Successfully!!",
              "success"
            ).then((result) => {
              if (result.isConfirmed) {
                axios
                  .post(`${apiUrl}/sendMessage`, whatsapp)
                  .then((res) => {
                    hideLoader();
                  })
                  .catch((err) => {
                    error("Server is Busy!!");
                    hideLoader();
                  });
              }
            });
          })
          .catch((err) => {
            error("Server is Busy!!");
            hideLoader();
          });
      } else {
        if (detail && detail.Sr_No) {
          console.log(updatedPaymentDetail);
          axios
            .put(`${apiUrl}/intrestPayment/${detail.Sr_No}`, formdata)
            .then((res) => {
              handleClose();
              Swal.fire(
                "Success!",
                "Data Updated Successfully!!",
                "success"
              ).then((result) => {
                if (result.isConfirmed) {
                  // Redirect using React Router
                  // navigate("/viewTask");
                  //window.location.reload();
                  handleClose();
                }
              });
              hideLoader();
            })
            .catch((err) => {
              error("Server is Busy!!");
              hideLoader();
            });
        }
      }
    } else {
      seterrors(validationErrors);
    }
  };

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    if (userData?.Org_Id) {
      (async () => {
        showLoader();
        const res = await axios.get(
          `${apiUrl}/getBanks?OrgId=${userData.Org_Id}`
        );
        const bankOption = res.data.map((option) => ({
          label: option.Bank_Name,
          value: option.Bank_Name,
        }));
        setBankOption(bankOption);
        hideLoader();
      })();
    }
  }, [userData]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        style={{ boxShadow: "none !important" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row justify-content-center">
              <div className="col-md-4 ">
                <div className="mb-3">
                  <label>Enrollment No</label>
                  <input
                    type="text"
                    disabled
                    id="txtEnrollment"
                    name="txtEnrollment"
                    placeholder="Enrollment No"
                    className="form-control"
                    value={paymentDetail.Enrollment_No}
                  ></input>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    disabled
                    id="txtName"
                    name="txtName"
                    placeholder="Name"
                    className="form-control"
                    value={paymentDetail.Customer_Name}
                  ></input>
                </div>{" "}
              </div>
              <div className="col-md-4 ">
                <div className="mb-3">
                  <label>Amount</label>
                  <input
                    type="text"
                    id="txtAmount"
                    disabled
                    name="txtAmount"
                    placeholder="Interest Amount"
                    className="form-control"
                    value={paymentDetail.Amount}
                  ></input>
                </div>{" "}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="mb-3">
                  <label>Payment Date</label>
                  <span className="text-danger">*</span>
                  <input
                    type="date"
                    id="txtDate"
                    name="txtDate"
                    required
                    value={paymentDetail.Payment_Date}
                    onChange={(e) =>
                      setPaymentDetail({
                        ...paymentDetail,
                        Payment_Date: e.target.value,
                      })
                    }
                    placeholder="Payment Date"
                    className="form-control"
                  />
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label>Payment Mode</label>
                  <span className="text-danger">*</span>
                  <select
                    className="form-control"
                    required
                    value={paymentDetail.Payment_Mode}
                    onChange={(e) =>
                      setPaymentDetail({
                        ...paymentDetail,
                        Payment_Mode: e.target.value,
                      })
                    }
                  >
                    <option value="Online" label="Online"></option>
                    <option value="Cash" label="Cash"></option>
                    <option value="Cheque" label="Cheque"></option>
                    <option value="IMPS" label="IMPS"></option>
                    <option value="GPAY" label="GPAY"></option>
                    <option value="PhonePay" label="PhonePay"></option>
                    <option value="PAYTM" label="PAYTM"></option>
                    <option value="DD" label="DD"></option>
                    <option value="NEFT" label="NEFT"></option>
                    <option value="RTGS" label="RTGS"></option>
                  </select>
                </div>{" "}
              </div>
              <div
                className={
                  paymentDetail.Attatchment && type === "Update"
                    ? "col-md-3"
                    : "col-md-4"
                }
              >
                <div className="mb-3">
                  <label>Attatchment</label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="Attatchment"
                      name="Attatchment"
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={(e) => {
                        const fileName =
                          e.target.files[0]?.name || "Choose file";
                        setAttatchmentFileName(fileName);

                        setPaymentDetail({
                          ...paymentDetail,
                          Attatchment: e.target.files[0],
                        });
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="Attatchment"
                      style={{ overflow: "hidden" }}
                    >
                      {AttatchmentFileName}
                    </label>
                  </div>
                </div>

               
              </div>
              { type === "Update" && (
                <div className="col-md-1" style={{ marginTop: "33px" }}>
                      <div className="mb-3">
                        {paymentDetail.Attatchment ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/paymentDocView/` + paymentDetail.Attatchment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/paymentDoc/` + paymentDetail.Attatchment} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><FileDownloadOffIcon fontSize="medium" /> / <VisibilityOffIcon fontSize="medium" /></>
                        )}
                      </div>
                    </div>
               )}
            </div>

            <div className="row justify-content-center">
              {["Cheque", "DD"].includes(paymentDetail.Payment_Mode) && (
                <>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Bank_Name" className="form-label">
                        Bank<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        //type="text"
                        name="Bank_Name"
                        id="Bank_Name"
                        options={Bank}
                        value={paymentDetail.Bank_Name}
                        onChange={(e) =>
                          setPaymentDetail({
                            ...paymentDetail,
                            Bank_Name: e.value,
                          })
                        }
                    
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Branch_Name" className="form-label">
                        IFSC/Branch Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Branch_Name"
                        name="Branch_Name"
                        value={
                          paymentDetail.Branch_Name
                        }
                        placeholder="Enter IFSC/Branch Name"
                        required={true}
                        onChange={(e) =>
                          setPaymentDetail({
                            ...paymentDetail,
                            Branch_Name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Cheque_No" className="form-label">
                        Cheque No <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="Cheque_No"
                        name="Cheque_No"
                        placeholder="Enter Cheque No"
                        value={
                          paymentDetail.Cheque_No
                        }
                        required={true}
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setPaymentDetail({
                            ...paymentDetail,
                            Cheque_No: numericInput,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="Cheque_Date" className="form-label">
                        Cheque Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        id="Cheque_Date"
                        name="Cheque_Date"
                        required={true}
                        dateFormat="dd-MM-yyyy"
                        value={
                          paymentDetail.Cheque_Date
                        }
                        onChange={(e) =>
                          setPaymentDetail({
                            ...paymentDetail,
                            Cheque_Date: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row justify-content-center">
              {[
                "IMPS",
                "GPAY",
                "Online",
                "PhonePay",
                "PAYTM",
                "NEFT",
                "RTGS",
              ].includes(paymentDetail.Payment_Mode) && (
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="Reference_No" className="form-label">
                      Transaction ID <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="Reference_No"
                      name="Reference_No"
                      required={true}
                      placeholder="Enter Transaction ID"
                      value={paymentDetail.Reference_No}
                      onChange={(e) =>
                        setPaymentDetail({
                          ...paymentDetail,
                          Reference_No: e.target.value,
                        })
                      }
                    />
                    <p className="text-danger m-0">{errors.Reference_No}</p>
                  </div>
                </div>
              )}
            </div>

            <div className="row justify-content-center">
              <div className="col-md-12">
                <label>Remarks</label>
                <textarea
                  id="txtRemarks"
                  name="txtRemarks"
                  rows={2}
                  value={paymentDetail.Remarks}
                  onChange={(e) =>
                    setPaymentDetail({
                      ...paymentDetail,
                      Remarks: e.target.value,
                    })
                  }
                  placeholder="Remarks..."
                  className="form-control"
                ></textarea>
              </div>
            </div>
            <div className="row justify-content-center my-3">
              <div className="col-md-2 text-center">
                <button type="submit" className="btn btn-primary cursor-pointer">
                  {type === "Submit" ? "Submit" : "Update"}
                </button>
              </div>
            </div>
          </form>
          {loader}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CapitalPayment;
