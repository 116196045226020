import React, { useState } from "react";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { useUser } from "../../componets/UserContext";
import axios from "axios";
import Swal from "sweetalert2";
import { apiUrl } from "../../componets/apiConfig";
import { error } from "../Alerts/alert";
import { useNavigate } from "react-router-dom";
import UserPreview from "./UserPreview";

const AddUser = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errors, setErrors] = useState({});
  const [PhotoFileName, setPhotoFileName] = useState("Choose file");
  const [EmpDocument1FileName, setEmpDocument1FileName] =
    useState("Choose file");
  const [EmpDocument2FileName, setEmpDocument2FileName] =
    useState("Choose file");

  const showSummarry = (event) => {
    event.preventDefault();
    setIsModal(true);
  };
  const closed = (status) => {
    if (status) {
      handleSubmit();
    }
    setIsModal(false);
  };

  const [userDetail, setuUerDetail] = useState({
    Employee_Id: "",
    Joining_Date: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Gender: "Male",
    DOB: "",
    Email: "",
    Telephone: "",
    Mobile: "",
    Address: "",
    Status: "",
    Role: "Accountant",
    Username: "",
    Password: "accountant@123",
    Photo: "",
    EmpDocument1: "",
    EmpDocument2: "",
    OTP: "",
    GCM: "",
    App_Status: "",
    Date_Added: "",
    Added_By_Id: "",
    Added_By: "",
    Org_Id: "",
  });

  const handleSubmit = () => {
    let userPassword = "";
    //debugger;
    if (userDetail.Role === "Supervisor") {
      userPassword = "supervisor@123";
    } else if (userDetail.Role === "Accountant") {
      userPassword = "accountant@123";
    } else if (userDetail.Role === "Admin") {
      userPassword = "admin@123";
    } else if (userDetail.Role === "Director") {
      userPassword = "director@123";
    } else if (userDetail.Role === "Manager") {
      userPassword = "manager@123";
    } else if (userDetail.Role === "Executive") {
      userPassword = "executive@123";
    }
    const UpdatedUserDetail = {
      ...userDetail,
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName:userData.UserName,
      Username: userDetail.Mobile,
      Password: userPassword,
    };
    const formdata = new FormData();
    formdata.append("Employee_Id", userDetail.Employee_Id);
    formdata.append("Joining_Date", userDetail.Joining_Date);
    formdata.append("First_Name", userDetail.First_Name);
    formdata.append("Last_Name", userDetail.Last_Name);
    formdata.append("DOB", userDetail.DOB);
    formdata.append("Mobile", userDetail.Mobile);
    formdata.append("Email", userDetail.Email);
    formdata.append("Gender", userDetail.Gender);
    formdata.append("Role", userDetail.Role);
    formdata.append("Address", userDetail.Address);
    formdata.append("Photo", userDetail.Photo);
    formdata.append("EmpDocument1", userDetail.EmpDocument1);
    formdata.append("EmpDocument2", userDetail.EmpDocument2);
    formdata.append("Added_By_Id", UpdatedUserDetail.Added_By_Id);
    formdata.append("Added_By", UpdatedUserDetail.Added_By);
    formdata.append("Org_Id", UpdatedUserDetail.Org_Id);
    formdata.append("User_Role", UpdatedUserDetail.User_Role);
    formdata.append("Emp_Id", UpdatedUserDetail.Emp_Id);
    formdata.append("UserName", UpdatedUserDetail.UserName);
    formdata.append("Username", UpdatedUserDetail.Username);
    formdata.append("Password", UpdatedUserDetail.Password);

    const validationErrors = {};

    if (!/^[a-zA-Z\s]+$/.test(userDetail.First_Name)) {
      validationErrors.First_Name = "Invalid Input";
    }
    if (!/^[a-zA-Z\s]+$/.test(userDetail.Last_Name)) {
      validationErrors.Last_Name = "Invalid Input";
    }
    if (!/^([6-9][0-9]{9,11})$/.test(userDetail.Mobile)) {
      validationErrors.Mobile = "Invalid Mobile Number";
    }

    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(userDetail.Email) &&
      userDetail.Email !== ""
    ) {
      validationErrors.Email = "Invalid Email Address";
    }
    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      axios
        .post(`${apiUrl}/addUsers`, formdata)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Employee Added Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              // Redirect using React Router
              navigate("/viewUser");
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          error("Server is Busy!!");
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Add Employee</h5>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={showSummarry}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Employee_Id" className="form-label">
                        Employee Id<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Employee_Id"
                        className="form-control form-control-sm"
                        placeholder="Enter Employee Id"
                        required
                        value={userDetail.Employee_Id}
                        maxLength={10}
                        onChange={(e) => {
                          const input = e.target.value.toUpperCase();

                          setuUerDetail({
                            ...userDetail,
                            Employee_Id: input,
                          });
                        }}
                      />
                      <p className="text-danger">{errors.Employee_Id}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtFname" className="form-label">
                        First Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtFname"
                        className="form-control form-control-sm"
                        placeholder="Enter First Name"
                        required
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            First_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.First_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtLname" className="form-label">
                        Last Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtLname"
                        className="form-control form-control-sm"
                        placeholder="Enter Last Name"
                        required
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Last_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Last_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtMobile" className="form-label">
                        Mobile<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={userDetail.Mobile}
                        id="txtMobile"
                        className="form-control form-control-sm"
                        placeholder="Enter Mobile Number"
                        required
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setuUerDetail({
                            ...userDetail,
                            Mobile: numericInput,
                          });
                        }}
                      />
                      <p className="text-danger">{errors.Mobile}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtEmail" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        id="txtEmail"
                        className="form-control form-control-sm"
                        placeholder="Enter Email Id"
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Email: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Email}</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Joining_Date" className="form-label">
                        Joining Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        id="Joining_Date"
                        className="form-control form-control-sm"
                        required
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Joining_Date: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="dropRole" className="form-label">
                        Role
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="dropRole"
                        onChange={(e) =>
                          setuUerDetail({ ...userDetail, Role: e.target.value })
                        }
                      >
                        <option label="Accountant" value="Accountant"></option>
                        <option label="Executive" value="Executive"></option>
                        <option label="Admin" value="Admin"></option>
                        <option label="Director" value="Director"></option>
                        <option label="Manager" value="Manager"></option>
                        <option label="Supervisor" value="Supervisor"></option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="dropGender" className="form-label">
                        Gender
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="dropGender"
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Gender: e.target.value,
                          })
                        }
                      >
                        <option label="Male" value="Male"></option>
                        <option label="Female" value="Female"></option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtDOB" className="form-label">
                        DOB
                      </label>
                      <input
                        type="date"
                        id="txtDOB"
                        dateFormat="dd-mm-yyyy"
                        className="form-control form-control-sm"
                        //placeholder="Enter DOB"
                        onChange={(e) =>
                          setuUerDetail({ ...userDetail, DOB: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Photo">
                        Photo
                      </label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="Photo"
                          name="Photo"
                          accept=".jpg, .jpeg, .png"
                          onChange={(e) => {
                            const fileName =
                              e.target.files[0]?.name || "Choose file";
                            setPhotoFileName(fileName);

                            setuUerDetail({
                              ...userDetail,
                              Photo: e.target.files[0],
                            });
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="Photo"
                          style={{ overflow: "hidden" }}
                        >
                          {PhotoFileName}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="EmpDocument1">
                        Document1
                      </label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="EmpDocument1"
                          name="EmpDocument1"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) => {
                            const fileName =
                              e.target.files[0]?.name || "Choose file";
                            setEmpDocument1FileName(fileName);

                            setuUerDetail({
                              ...userDetail,
                              EmpDocument1: e.target.files[0],
                            });
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="EmpDocument1"
                          style={{ overflow: "hidden" }}
                        >
                          {EmpDocument1FileName}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="EmpDocument2">
                        Document2
                      </label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="EmpDocument2"
                          name="EmpDocument2"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) => {
                            const fileName =
                              e.target.files[0]?.name || "Choose file";
                            setEmpDocument2FileName(fileName);

                            setuUerDetail({
                              ...userDetail,
                              EmpDocument2: e.target.files[0],
                            });
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="EmpDocument2"
                          style={{ overflow: "hidden" }}
                        >
                          {EmpDocument2FileName}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Address
                      </label>
                      <textarea
                        type="text"
                        id="exampleFormControlTextarea1"
                        className="form-control"
                        placeholder="Enter Address"
                        rows="2"
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                    <button type="submit" className="btn btn-primary ">
                      Add User
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {isModal && (
            <UserPreview
              open={isModal}
              onClose={(status) => closed(status)}
              userDetail={userDetail}
              opration={'AddUser'}
            />
          )}
          {loader}
        </div>
      </div>
    </div>
  );
};
export default AddUser;
