import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import DataTable from "react-data-table-component";
import useFullPageLoader from "../../mui/useFullPageLoader";
import Select from "react-select";
import ShowPlan from "./PlanPreview"

const AddProduct = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [Plan_Id, setPlanId] = useState(null);
  const [Interest_Rate, setInterestRate] = useState("");
  const [Bank, setBankOption] = useState([]);
  const [Bank_Name, setBankName] = useState("");
  const [Acc_No, setAccNo] = useState("");
  const [Branch_Name, setBranchName] = useState("");
  const [Acc_Holder_Name, setAccHolderName] = useState("");
  const [IFSC_Code, setIFSCCode] = useState("");
  const [Capital_Amount, setCapitalAmount] = useState("");
  const [Description, setDescription] = useState("");
  const [data, setData] = useState([]);
  const [records, setRecords] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState("");
  const { userData } = useUser();
  const [Plan_Name, setPlanName] = useState("");
  const [Plan_Prefix, setPlanPrefix] = useState("");
  const [Payout_Int_Date, setPayoutIntDate] = useState("");
  const [Last_Payable_Int_days, setLastPayableIntdays] = useState("1");
  const [Capital_Return_Days, setCapitalReturnDays] = useState("30");
  const [IsPreview, setIsPreview] = useState(false);
  const [IsPreviewData, setIsPreviewData] = useState();

  const open = (row) => {
    console.log(row);
    setIsPreview(true);
    setIsPreviewData(row);
  }

    
  const closed = () => {
    setIsPreview(false);
  };

  const columns = [
    {
      name: " Plan Name",
      cell: (row) => <Link onClick={() => open(row)}>{row.Plan_Name}</Link>,
      width: "300px",
    },
    {
      name: "Capital Amount",
      selector: (row) => parseFloat(row.Capital_Amount).toFixed(2),
      sortable: true,
    },
    {
      name: "Interest Rate",
      selector: (row) => parseFloat(row.Interest_Rate).toFixed(2),
      sortable: true,
    },
    // {
    //   name: "Payout Int Date",
    //   selector: (row) => formatDate(row.Payout_Int_Date),
    //   sortable: true,
    // },

    {
      name: " Description",
      cell: (row) => (
        <LightTooltip title={row.Description}>
          <span
            style={{
              maxWidth: "140px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Description ? row.Description : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <div className="dropdown">
          <a
            maxWidth="100px"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i>
              <MoreVertIcon
                style={{
                  color: "grey",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </i>
          </a>
          <div
            className="dropdown-menu"
            x-placement="bottom-start"
            style={{
              position: "absolute",
              willChange: "transform",
              top: "0px",
              left: "0px",
              transform: "translate3d(0px, 38px, 0px)",
            }}
          >
            <Link className="dropdown-item" onClick={() => editExpenses(row)}>
              <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "}
              Edit
            </Link>
            <Link
              className="dropdown-item"
              onClick={() => deleteUser(row.Plan_Id, row.Plan_Name)}
            >
              <DeleteIcon
                className="text-danger"
                style={{ fontSize: "18px" }}
              />
              Delete
            </Link>
          </div>
        </div>
      ),
      width: "80px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  useEffect(() => {
    if (userData && userData.Org_Id) {
      Load();
    }
  }, [userData]);

  const Load = async () => {
    showLoader();

    const res = await axios.get(`${apiUrl}/getBanks?OrgId=${userData.Org_Id}`);
    const bankOption = res.data.map((option) => ({
      label: option.Bank_Name,
      value: option.Bank_Name,
    }));
    setBankOption(bankOption);

    const result = await axios.get(
      `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
    );
    const sortedData = result.data;
    setData(sortedData);
    setRecords(sortedData);
   // console.log(sortedData)
    hideLoader();

  };

  async function save(event) {
    event.preventDefault();

    const error = {};

    const day = Payout_Int_Date.toString().padStart(2, '0');
    const currentDate = new Date();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const currentYear = currentDate.getFullYear();
    const selectedDate = `${currentYear}-${currentMonth}-${day}`;
    //console.log(selectedDate);

    if (Object.keys(error).length === 0) {
      try {
        showLoader();
        await axios.post(`${apiUrl}/addPlan`, {
          Plan_Name: Plan_Name,
          Capital_Amount: Capital_Amount,
          Plan_Prefix: 'ETIFM',
          Payout_Int_Date: selectedDate,
          Last_Payable_Int_days: Last_Payable_Int_days,
          Capital_Return_Days: Capital_Return_Days,
          Interest_Rate: Interest_Rate,
          Bank_Name: Bank_Name,
          Acc_No: Acc_No,
          Branch_Name: Branch_Name,
          Acc_Holder_Name: Acc_Holder_Name,
          IFSC_Code: IFSC_Code,
          Description: Description,
          User_Role: userData.role,
          Emp_Id: userData.Employee_Id,
          Added_By_Id: userData.userId,
          Added_By: `${userData.firstName} ${userData.lastName}`,
          Org_Id: userData.Org_Id,
          UserName:userData.UserName,
        });
        Swal.fire("Success!", "Plan has been Added.", "success");
        setError("");
        setPlanName("");
        setCapitalReturnDays("");
        setPayoutIntDate("");
        setLastPayableIntdays("");
        setPlanPrefix("");
        setDescription("");
        setCapitalAmount("");
        setInterestRate("");
        setAccHolderName("");
        setIFSCCode("");
        setBranchName("");
        setAccNo("");
        setBankName("");
        setPlanId(null);
        Load();
        hideLoader();
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Failed to add Plan. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      }
    } else {
      setError(error);
    }
  }

  async function editExpenses(data) {
    setPlanName(data.Plan_Name);
    setCapitalReturnDays(data.Capital_Return_Days);
    //setPayoutIntDate(data.Payout_Int_Date);
    setPayoutIntDate(new Date(data.Payout_Int_Date).getDate())
    setLastPayableIntdays(data.Last_Payable_Int_days);
    setPlanPrefix(data.Plan_Prefix);
    setInterestRate(data.Interest_Rate);
    setDescription(data.Description);
    setCapitalAmount(data.Capital_Amount);
    setPlanId(data.Plan_Id);
    setAccHolderName(data.Acc_Holder_Name);
    setIFSCCode(data.IFSC_Code);
    setBranchName(data.Branch_Name);
    setAccNo(data.Acc_No);
    setBankName(data.Bank_Name);
    setEditingItem(data);
  }

  async function update(event) {
    event.preventDefault();

    try {

      const day = Payout_Int_Date.toString().padStart(2, '0');
      const currentDate = new Date();
      const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const currentYear = currentDate.getFullYear();
      const selectedDate = `${currentYear}-${currentMonth}-${day}`;
      //console.log(selectedDate);


      const updatedData = {
        Plan_Id: Plan_Id,
        Plan_Name: Plan_Name,
        Capital_Amount: Capital_Amount,
        Plan_Prefix: Plan_Prefix,
        Interest_Rate: Interest_Rate,
        Bank_Name: Bank_Name,
        Acc_No: Acc_No,
        Branch_Name: Branch_Name,
        Acc_Holder_Name: Acc_Holder_Name,
        IFSC_Code: IFSC_Code,
        Payout_Int_Date: selectedDate,
        Last_Payable_Int_days: Last_Payable_Int_days,
        Capital_Return_Days: Capital_Return_Days,
        Description: Description,
        User_Role: userData.role,
        Emp_Id: userData.Employee_Id,
        Added_By_Id: userData.userId,
        Added_By: `${userData.firstName} ${userData.lastName}`,
        Org_Id: userData.Org_Id,
        UserName:userData.UserName,
      };
      showLoader();
      await axios.put(`${apiUrl}/updatePlan/` + Plan_Id, updatedData);
      setData((prevData) =>
        prevData.map((data) =>
          data.Plan_Id === Plan_Id ? { ...data, ...updatedData } : data
        )
      );
      setPlanName("");
      setCapitalReturnDays("");
      setPayoutIntDate("");
      setLastPayableIntdays("");
      setPlanPrefix("");
      setDescription("");
      setInterestRate("");
      setCapitalAmount("");
      setAccHolderName("");
      setIFSCCode("");
      setBranchName("");
      setAccNo("");
      setBankName("");
      setPlanId(null);
      setEditingItem(null);
      Load();
      hideLoader();
      Swal.fire("Success!", "Plan updated successfully.", "success");
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Failed to Update. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  const deleteUser = (Plan_Id, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Plan_Id, name);
      }
    });
  };

  async function handleDelete(Plan_Id, name) {
    const obj={
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName:userData.UserName,
      Name: name
    }
    await axios.delete(`${apiUrl}/deletePlan/${Plan_Id}`, {data:obj});
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    Load();
  }

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Plan", 20, 10);

    const columns = [
      "Plan Name",
      "Capital Amount",
      "Interest Rate",
      "Interest Payout Date",
      "Last Payable Int days",
      "Capital Return Days",
      "Description"
    ];

    const rows = records.map((row) => [
      row.Plan_Name,
      row.Capital_Amount,
      row.Interest_Rate,
      formatDate(row.Payout_Int_Date),
      row.Last_Payable_Int_days,
      row.Capital_Return_Days,
      row.Description ? row.Description : "--"
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Plan.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Plan Name",
      "Capital Amount",
      "Interest Rate",
      "Interest Payout Date",
      "Last Payable Int days",
      "Capital Return Days",
      "Description"
    ];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        row.Plan_Name,
        row.Capital_Amount,
        row.Interest_Rate,
        formatDate(row.Payout_Int_Date),
        row.Last_Payable_Int_days,
        row.Capital_Return_Days,
        row.Description ? row.Description : "--",
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Plan");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Plan.xlsx");
  };



  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Plan_Name && row.Plan_Name.toLowerCase().includes(filterValue)) ||
        (row.Capital_Amount && (row.Capital_Amount).toString().toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Plan </h5>
          </div>
          <div className="card">
            <div className="card-body">
              <Box height={10} />
              <Stack>
                <form
                  className="form-horizontal"
                  onSubmit={editingItem ? update : save}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Plan_Name">
                          Plan Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="Plan_Name"
                          required
                          placeholder="Enter Plan Name"
                          value={Plan_Name}
                          onChange={(event) => {
                            setPlanName(event.target.value);
                            setError("");
                          }}
                          id="Plan_Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 d-none">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Plan_Prefix">
                          Enrollment ID Prefix
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="Plan_Prefix"
                          maxLength={3}
                          value={Plan_Prefix}
                          placeholder="Enter Prefix"
                          onChange={(event) => {
                            const input = event.target.value;
                            const uppercaseAlphabets = input
                              .replace(/[^A-Za-z]/g, "")
                              .toUpperCase();
                            setPlanPrefix(uppercaseAlphabets);
                            setError("");
                          }}
                          id="Plan_Prefix"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Capital_Amount">
                          Capital Amount<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="Capital_Amount"
                          required
                          placeholder="Enter Capital Amount"
                          value={Capital_Amount}
                          onChange={(event) => {
                            const input = event.target.value;
                            const decimalInput = input.replace(/[^0-9.]/g, "");
                            const amount = decimalInput.replace(
                              /^(\d*\.\d*)\..*$/,
                              "$1"
                            );
                            setCapitalAmount(amount);
                            setError("");
                          }}
                          id="Capital_Amount"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Interest_Rate">
                          Interest Rate<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="Interest_Rate"
                          required
                          placeholder="Enter Interest Rate"
                          value={Interest_Rate}
                          onChange={(event) => {
                            const input = event.target.value;
                            const decimalInput = input.replace(/[^0-9.]/g, "");
                            const amount = decimalInput.replace(
                              /^(\d*\.\d*)\..*$/,
                              "$1"
                            );
                            setInterestRate(amount);
                            setError("");
                          }}
                          id="Interest_Rate"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Payout_Int_Date">
                          Payout Int. Date (Day)
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="Payout_Int_Date"
                          required
                          placeholder="Enter Payout Day"
                          value={Payout_Int_Date || ""}
                          onChange={(event) => {
                            setPayoutIntDate(event.target.value);
                            setError("");
                          }}
                          id="Payout_Int_Date"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-none">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Last_Payable_Int_days"
                        >
                          Last Payable Int. Days
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="Last_Payable_Int_days"
                          //required
                          value={Last_Payable_Int_days}
                          onChange={(event) => {
                            const input = event.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            setLastPayableIntdays(numericInput);
                            setError("");
                          }}
                          id="Last_Payable_Int_days"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 d-none">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="Capital_Return_Days"
                        >
                          Capital Return days
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="Capital_Return_Days"
                          //required
                          value={Capital_Return_Days}
                          onChange={(event) => {
                            const input = event.target.value;
                            const numericInput = input.replace(/[^0-9]/g, "");
                            setCapitalReturnDays(numericInput);
                            setError("");
                          }}
                          id="Capital_Return_Days"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Bank_Name" className="form-label">
                          Bank Name
                        </label>
                        <Select
                          type="text"
                          name="Bank_Name"
                          id="Bank_Name"
                          //placeholder="Select Bank"
                          options={Bank}
                          value={Bank_Name ? { value: Bank_Name, label: Bank_Name } : null}
                          onChange={(event) => {
                            setBankName(event.value);
                            setError("");
                          }}

                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="IFSC" className="form-label">
                          IFSC Code
                        </label>
                        <input
                          type="text"
                          id="IFSC"
                          name="IFSC"
                          value={IFSC_Code}
                         className="form-control form-control-sm"
                          placeholder="Enter IFSC Code"
                          onChange={(e) =>
                            setIFSCCode(e.target.value.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Branch" className="form-label">
                        Branch Name
                        </label>
                        <input
                          type="text"
                          id="Branch"
                          name="Branch"
                          value={Branch_Name}
                         className="form-control form-control-sm"
                          placeholder="Enter Branch Name"
                          onChange={(e) =>
                            setBranchName(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="Acc_No" className="form-label">
                          Account No
                        </label>
                        <input
                          type="text"
                          id="Acc_No"
                          name="Acc_No"
                          value={Acc_No}
                         className="form-control form-control-sm"
                          placeholder="Enter Account No"
                          onChange={(e) => {
                            const input = e.target.value;
                            const decimalInput = input.replace(/[^0-9]/g, "");
                            setAccNo(decimalInput)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="Acc_Holder_Name"
                          className="form-label"
                        >
                          Account Name
                        </label>
                        <input
                          type="text"
                          id="Acc_Holder_Name"
                          name="Acc_Holder_Name"
                          value={Acc_Holder_Name}
                         className="form-control form-control-sm"
                          placeholder="Enter Name "
                          onChange={(e) =>
                            setAccHolderName(e.target.value)
                            }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label" htmlFor="Description">
                          Description
                        </label>
                        <textarea
                          type="text"
                         className="form-control form-control-sm"
                          name="Description"
                          value={Description}
                          onChange={(event) => {
                            setDescription(event.target.value);
                            setError("");
                          }}
                          id="Description"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <div>
                        {editingItem ? (
                          <button
                            className="btn btn-primary mb-1"
                            type="submit"
                          >
                            Update
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary mb-1"
                            type="submit"
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </Stack>
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <Box height={10} />

              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <Box height={14} />
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  columns={columns}
                  data={records}
                  customStyles={customStyles}
                  fixedHeader
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {IsPreview && (
        <ShowPlan
          open={IsPreview}
          onClose={closed}
          data={IsPreviewData}
        />
      )}
      {loader}
    </div>
  );
};

export default AddProduct;
