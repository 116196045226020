import React, { useState, useEffect } from "react";
import "./CustomerPreview.css";
import { Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import dayjs from "dayjs";
import { apiUrl } from "../../componets/apiConfig";
import { useUser } from "../../componets/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import InterestPayment from "../Reports/InterestPayment";
import Swal from "sweetalert2";
import InterestDetails from "./InterestDetails";
import InvestmentLog from "./InvestmentLog";
import FixedInterest from "./FixedInterest.js"
import SlabInterest from "./SlabInterest"
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DownloadIcon from "@mui/icons-material/Download";
import Visibility from "@mui/icons-material/Visibility";

const CustomerWiseDashBoard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { Sr_No } = useParams();
  const [data, setData] = useState({});
  const [SlabWise, setSalbWise] = useState([]);
  const [key, setKey] = useState(1);

  const handleTabSelect = (selectedKey) => {
    setKey(parseInt(selectedKey));
  };


  useEffect(() => {
    if (Sr_No) {
      (async () => {
        showLoader();
        const res = await axios.get(`${apiUrl}/getCustomerWiseDashBoard/${Sr_No}`);
        setData(res.data.customer[0]);
        console.log(res.data.customer[0]);
        setSalbWise(res.data.relatedCustomers);
        hideLoader();
      })();
    }
  }, [Sr_No]);



  return (
    <div className="container-fluid">
      <div className="bootstrap snippets bootdey" style={{ padding: "0 10px" }}>
        <div className="row">
          <div className="profile-nav col-md-3">
            <div className="card">
              <div className="user-heading round">
                <a>
                  {data.Photo ? (
                    <img src={`${apiUrl}/download/${data.Photo}`} alt="Photo" />
                  ) : (
                    <img src="/img/dummy.png" alt="user" />
                  )}
                </a>
                <h6>
                  {data.First_Name} {data.Middle_Name} {data.Last_Name}
                </h6>
                <p>{data.Enrollment_No}</p>
              </div>

              <ul className="nav nav-pills nav-stacked">
                <li>
                  <span>Mobile No : </span> {data.Mobile}
                </li>
                <li>
                  <span>Email : </span> <p style={{ width: "162px" }}>{data.Email}</p>
                </li>
                <li>
                  <span>DOB : </span>
                  {data.DOB != "" && data.DOB != null ? (
                    dayjs(data.DOB).format("DD/MM/YYYY")
                  ) : (
                    <></>
                  )}
                </li>
                <li>
                  <span>Gender : </span> {data.Gender}
                </li>
                <li>
                  <span>Marital Status : </span> {data.Marital_Status}
                </li>
                <li>
                  <span>Pan No :</span> {data.Pan_No}
                </li>
                <li>
                  <span>Aadhar No : </span> {data.Aadhar_No}
                </li>
                <li>
                  <span>Reference Type : </span> {data.Reference_Type}
                </li>
                {data.Reference_Type == "Reference" && (
                  <li>
                    <span>Reference : </span> {data.Reference}
                  </li>
                )}
                <li>
                  <span>Student : </span> {data.Student}
                </li>
                <li>
                  <span>Address : </span> <p style={{ width: "162px" }}>{data.Address}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="profile-info col-md-9">
            <Tabs
              defaultActiveKey={1}
              id="uncontrolled-tab-example"
              activeKey={key}
              onSelect={handleTabSelect}
              className="mb-2"
            >
              <Tab className="mt-2" eventKey={1} title="Profile">
                <div className="card">
                  <div className="card-body bio-graph-info">
                    <h5>Investment Detail</h5>
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          <span>Investment Type : </span> {data.Investment_Type}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          <span>Investment Date : </span>
                          {data.Investment_Date != "" &&
                            data.Investment_Date != null ? (
                            dayjs(data.Investment_Date).format("DD/MM/YYYY")
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <span>Capital Amount : </span> {data.Capital_Amount}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <span>Start Date : </span>
                          {data.Start_Date != "" && data.Start_Date != null ? (
                            dayjs(data.Start_Date).format("DD/MM/YYYY")
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <span>Mode Of Investment : </span>
                          {data.Mode_Of_Investment}
                        </p>
                      </div>
                      {data.Mode_Of_Investment !== "Cash" &&
                        data.Mode_Of_Investment !== "Cheque" && (
                          <div className="col-md-12">
                            <p>
                              <span>Transaction ID : </span> {data.Reference_No}
                            </p>
                          </div>
                        )}
                      {data.Mode_Of_Investment === "Cheque" && (
                        <>
                          <div className="col-md-4">
                            <p>
                              <span>Bank Name : </span> {data.Bank}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span>Branch Name : </span> {data.Branch_Name}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span>Cheque No : </span> {data.Cheque_No}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <span>Cheque Date : </span>
                              {data.Cheque_Date != "" &&
                                data.Cheque_Date != null ? (
                                dayjs(data.Cheque_Date).format("DD/MM/YYYY")
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </>
                      )}
                      <div className={data.Percentage_Type === "Fixed" || data.Mode_Of_Investment === "Cheque" ? "col-md-4" : "col-md-6"}>
                        <p><span>Investment Period : </span> {data.Investment_Period}</p>
                      </div>
                      <div className={data.Percentage_Type === "Fixed" || data.Mode_Of_Investment === "Cheque" ? "col-md-4" : "col-md-6"}>
                        <p><span>Interest Type : </span> {data.Percentage_Type}</p>
                      </div>
                      {data.Percentage_Type === "Fixed" && (<div className="col-md-4">
                        <p><span>Rate Of Interest : </span> {data.Rate_Of_Int}%</p>
                      </div>)}
                    </div>
                    {data.Percentage_Type === "Slabwise" && (
                      <div> {SlabWise.map((slab, index) => (
                        <div key={index} className="row">
                          {/*<div className="col-md-4">
                            <p><span>From Amount : </span> {slab.From_Amount}</p>
                      </div>*/}
                          <div className="col-md-6">
                            <p><span>Slab Amount : </span> {slab.To_Amount}</p>
                          </div>
                          <div className="col-md-6">
                            <p><span>Rate Of Interest : </span> {slab.Interest}%</p>
                          </div>  </div>))}</div>
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <p><span>Remarks : </span> {data.Remarks}</p>
                      </div>   </div>

                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h5>Bank Detail</h5>
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <span>Bank Name : </span> {data.Bank_Name}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <span>IFSC Code : </span> {data.IFCE_Code}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <span>Account No : </span> {data.Account_No}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <span>Account Holder : </span>
                              {data.Account_Holder_Name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h5>Nominee Detail</h5>
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <span>Full Name : </span> {data.Nominee_Full_Name}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <span>DOB : </span>
                              {data.Nominee_DOB != "" &&
                                data.Nominee_DOB != null ? (
                                dayjs(data.Nominee_DOB).format("DD/MM/YYYY")
                              ) : (
                                <></>
                              )}
                              |
                              {data.Nominee_Birth_Proof ? (
                                <>
                                  <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Nominee_Birth_Proof} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                    <Visibility fontSize="medium" />
                                  </a> /
                                  <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Nominee_Birth_Proof} className="cursor-pointer">
                                    <DownloadIcon fontSize="medium" />
                                  </a></>
                              ) : (
                                <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                              )}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <span>Photo : </span>
                              {data.Nominee_Photo ? (
                                <>
                                  <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Nominee_Photo} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                    <Visibility fontSize="medium" />
                                  </a> /
                                  <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Nominee_Photo} className="cursor-pointer">
                                    <DownloadIcon fontSize="medium" />
                                  </a></>
                              ) : (
                                <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                              )}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <span>Nominee Address Proof : </span>
                              {data.Nominee_Address_Proof ? (
                                <>
                                  <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Nominee_Address_Proof} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                    <Visibility fontSize="medium" />
                                  </a> /
                                  <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Nominee_Address_Proof} className="cursor-pointer">
                                    <DownloadIcon fontSize="medium" />
                                  </a></>
                              ) : (
                                <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                              )}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <span>Signature : </span>
                              {data.Nominee_Sign_Attachment ? (
                                <>
                                  <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Nominee_Sign_Attachment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                    <Visibility fontSize="medium" />
                                  </a> /
                                  <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Nominee_Sign_Attachment} className="cursor-pointer">
                                    <DownloadIcon fontSize="medium" />
                                  </a></>
                              ) : (
                                <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <h5>Documents</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <p>
                              <span>ID Proof Type : </span> {data.Id_Proof_Type}
                              |
                              {data.Id_Proof_Attachment ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Id_Proof_Attachment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Id_Proof_Attachment} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>Address Proof Type : </span>
                              {data.Address_Proof_Type} |
                              {data.Address_Proof_Attachment ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Address_Proof_Attachment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Address_Proof_Attachment} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {data.Co_applicant === "true" && (
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <h5>Co-Applicant</h5>
                          <div className="row">
                            <div className="col-md-6">
                              <p>
                                <span>Full Name : </span>
                                {data.Co_applicant_Full_Name}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                <span>DOB : </span>
                                {data.Co_applicant_DOB != "" &&
                                  data.Co_applicant_DOB != null ? (
                                  dayjs(data.Co_applicant_DOB).format(
                                    "DD/MM/YYYY"
                                  )
                                ) : (
                                  <></>
                                )}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                <span>Relationship : </span>
                                {data.Co_applicant_Relationship}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                <span>Occupation : </span>
                                {data.Co_applicant_Occupation}
                              </p>
                            </div>
                            <div className="col-md-12">
                              <p>
                                <span>Address : </span>
                                {data.Co_applicant_Address}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                <span>Photo : </span>
                                {data.Co_applicant_Photo ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Co_applicant_Photo} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Co_applicant_Photo} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                                
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                <span>Birth Proof : </span>
                                {data.Co_applicant_Birth_Proof ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Co_applicant_Birth_Proof} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Co_applicant_Birth_Proof} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <h5>Loan Agreement Details</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <p>
                              <span>Agreement Date : </span>
                              {data.Agreement_Date != "" &&
                                data.Agreement_Date != null ? (
                                dayjs(data.Agreement_Date).format(
                                  "DD/MM/YYYY"
                                )
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>Receiver Name : </span>
                              {data.Receiver_Name}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>Given By Employee : </span>
                              {data.Emp_Name}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>File No : </span>
                              {data.File_No}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <span>File Location : </span>
                              {data.File_Location}
                            </p>
                          </div>

                          <div className="col-md-6">
                            <p>
                              <span>Postal Compony : </span>
                              {data.Postal_Compony}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>AWB No : </span>
                              {data.AWB_No}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>Address : </span>
                              {data.Postal_Address}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>Agreement Remarks : </span>
                              {data.Agreement_Remarks}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>Attachment : </span>
                              {data.Attachment ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Attachment} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Attachment} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              <span>Postal Receipt : </span>
                              {data.Postal_Receipt ? (
                          <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/viewImage/` + data.Postal_Receipt} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                              <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/download/` + data.Postal_Receipt} className="cursor-pointer">
                              <DownloadIcon fontSize="medium" />
                            </a></>
                        ) : (
                          <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                        )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="Investment Detail" style={{ marginLeft: "10px" }}>
                {key === 2 && (<InvestmentLog CustomerId={Sr_No} />)}
              </Tab>
              <Tab eventKey={3} title="Interest Detail">
                {key === 3 && (data.Percentage_Type === "Fixed" ? (<FixedInterest />) : (<SlabInterest />))}
              </Tab>
              {/* <Tab eventKey={3} title="Tab 3">
            
              </Tab> */}
            </Tabs>
          </div>
        </div>

        {loader}
      </div>
    </div>
  );
};

export default CustomerWiseDashBoard;
