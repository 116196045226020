import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import axios from "axios";
import Select from "react-select";
import "jspdf-autotable";
import TextField from "@mui/material/TextField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import "../../CSS/dataTables.bootstrap4.min.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import $ from 'jquery';
import 'datatables.net';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import InterestPayment from "./InterestPayment";
import dayjs from "dayjs";

const PaidInterest = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [isModal, setIsModal] = useState(false);
    const [indexValue, setIndex] = useState();
    const [planData, setPlanData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [oldData, setlanData] = useState([]);
    const [data, setData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [filter, setFilter] = useState({
        PlanId: "-1",
        Customer_Id: "-1",
        InterestType: "-1",
        fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
    });
    const planLoad = async () => {
        showLoader();
        const staffResult = await axios.get(
            `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
        );
        setPlanData(staffResult.data);
        hideLoader();
    }
    const TypeLoad = async () => {
        showLoader();
        const TypeResult = await axios.get(
            `${apiUrl}/getPercentageTypes?OrgId=${userData.Org_Id}`
        );
        setTypeData(TypeResult.data);
        hideLoader();
    };
    const customerLoad = async () => {
        showLoader();
        const customerResult = await axios.get(`${apiUrl}/getCustomerByPlanId?OrgId=${userData.Org_Id}&PlanId=${filter.PlanId}`);
        setCustomerData(customerResult.data);
        hideLoader();
    }
    const dataLoad = async () => {
        showLoader();
        const Result = await axios.get(
            `${apiUrl}/paidInterest?OrgId=${userData.Org_Id}&PlanId=${filter.PlanId}&Type=${filter.InterestType}&Customer_Id=${filter.Customer_Id}&FromDate=${filter.fromDate}&ToDate=${filter.toDate}`
        );
        setData(Result.data);
        setlanData(Result.data);
        hideLoader();
    }
    useEffect(() => {
        if (userData && userData.Org_Id) {
            planLoad();
            customerLoad();
            dataLoad();
            TypeLoad();
        }
    }, [userData, filter.PlanId])

    const filterData = () => {

        dataLoad();
    }

    useEffect(() => {
        if (data) {
            showLoader();
            const totalCapitalAmount = data.reduce((total, item) => total + parseFloat(item.Amount), 0);
            setTotalAmount(totalCapitalAmount);
            hideLoader();
        }
    }, [data]);
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));
    const DownloadPDF = () => {
        const doc = new jsPDF();
        doc.text("Customers", 20, 10);

        const columns = [
            "Enrollment No",
            "Customer Name",
            "Plan",
            "Start Date",
            "Payment Date",
            "Reference No",
            "Amount"
        ];

        const rows = data.map((row) => [
            row.Enrollment_No,
            row.Customer_Name,
            row.Investment_Type,
            dayjs(row.Start_Date).format('DD/MM/YYYY'),
            dayjs(row.Payment_Date).format('DD/MM/YYYY'),
            row.Reference_No,
            row.Amount,
            row.Remarks
        ]);

        // Calculate the sum of the "Amount" column
        const totalAmount = rows.reduce((total, row) => total + row[6], 0); // Assuming "Amount" is at index 6

        // Add an additional row to display the total amount
        const totalRow = ["", "", "", "", "", "Total: ", `${totalAmount}`]; // Adjust indices based on columns
        rows.push(totalRow);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("PaidInterest.pdf");
    };

    const DownloadExcel = () => {
        const columns = [
            "Enrollment No",
            "Customer Name",
            "Plan",
            "Start Date",
            "Payment Date",
            "Reference No",
            "Amount"
        ];

        let totalAmount = 0; // Initialize total amount variable

        const rows = [columns];
        data.forEach((row) => {
            rows.push([
                row.Enrollment_No,
                row.Customer_Name,
                row.Investment_Type,
                dayjs(row.Start_Date).format('DD/MM/YYYY'),
                dayjs(row.Payment_Date).format('DD/MM/YYYY'),
                row.Reference_No,
                row.Amount
            ]);

            // Accumulate the amount for total calculation
            totalAmount += row.Amount;
        });

        // Push a row at the end to display the total amount
        const totalRow = ["", "", "", "", "", "Total:", totalAmount];
        rows.push(totalRow);

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "PaidInterest");

        // Buffer
        // let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

        // Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

        // Download
        XLSX.writeFile(workBook, "PaidInterest.xlsx");
    };

    function handleFilter(event) {
        const filterValue = event.target.value.toLowerCase();
        const filteredData = oldData.filter((row) => {
            return (
                (`${row.Enrollment_No}` && `${row.Enrollment_No}`.toLowerCase().includes(filterValue)) ||
                (row.Investment_Type && row.Investment_Type.toLowerCase().includes(filterValue)) ||
                (row.Customer_Name && row.Customer_Name.toLowerCase().includes(filterValue)) ||
                (row.Start_Date && row.Start_Date.toLowerCase().includes(filterValue)) ||
                (row.Amount.toString().includes(filterValue)) ||
                (dayjs(row.Payment_Date).format('DD/MM/YYYY').includes(filterValue)) ||
                (row.Reference_No && row.Reference_No.toLowerCase().includes(filterValue)) ||
                (row.Remarks && row.Remarks.toLowerCase().includes(filterValue))
            );
        });
        setData(filteredData);
    }
    const updateData = (index) => {
        setIndex(index);
        setIsModal(true);
    }

    function deleteData(id, name) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(id, name);
            }
        });
    }
    const handleDelete = async (id, name) => {
        const obj = {
            User_Role: userData.role,
            Emp_Id: userData.Employee_Id,
            Added_By_Id: userData.userId,
            Added_By: `${userData.firstName} ${userData.lastName}`,
            Org_Id: userData.Org_Id,
            UserName: userData.UserName,
            Name: name
        }
        await axios.delete(`${apiUrl}/deletePaymentLog/${id}`, { data: obj });
        Swal.fire("Deleted!", "Your record has been Deleted.", "success");
        await dataLoad();
    };
    
    const closed = () => {
        setIsModal(false);
        dataLoad();
    };

    const calculateDaysDifference = (startDate, endDate) => {
        const start = dayjs(startDate).startOf("day");
        const end = dayjs(endDate).startOf("day");
        const daysDifference = end.diff(start, "day");
        //console.log(daysDifference)
        return daysDifference;
    };

    return (
        <div className="container-fluid">
            <div>
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                            <h5 className="page-title">  Payment Statement</h5>
                        </div>
                        <div className="card">
                            <h5 className="page-title"></h5>
                            <div className="card-body" style={{ paddingTop: "5px" }}>
                                <div className="row justify-content-center mt-2">
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            Plan
                                        </label>
                                        <select
                                            className="form-control"
                                            id="example-select"
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    PlanId: e.target.value,
                                                });
                                            }} >
                                            <option value={-1}>All</option>
                                            {planData.map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item.Plan_Id}
                                                    label={item.Plan_Name}
                                                ></option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            Customer
                                        </label>
                                        <Select
                                            id="example-select"
                                            options={[
                                                { value: -1, label: 'All' },
                                                ...customerData.map((item, index) => ({
                                                    value: item.Sr_No,
                                                    label: `${item.First_Name} ${item.Last_Name}`
                                                }))
                                            ]}
                                            onChange={(selectedOption) => {
                                                setFilter({
                                                    ...filter,
                                                    Customer_Id: selectedOption.value
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            From Date
                                        </label>
                                        <input type="date" className="form-control"
                                            value={filter.fromDate}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    fromDate: e.target.value,
                                                });
                                            }}></input>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="example-select" className="form-label">
                                            To Date
                                        </label>
                                        <input type="date" className="form-control"
                                            value={filter.toDate}
                                            onChange={(e) => {
                                                setFilter({
                                                    ...filter,
                                                    toDate: e.target.value,
                                                });
                                            }}></input>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-2">
                                    <div className="col-2">
                                        <button className="btn btn-primary" onClick={filterData} style={{ width: "100%" }}>Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <TextField
                                        style={{ zIndex: 0 }}
                                        onChange={handleFilter}
                                        size="small"
                                        label="Search..."
                                    />
                                    <Tooltip title="Export to PDF" >
                                        <i className="mt-2" style={{ cursor: "pointer", padding: "5px", }} onClick={() => { DownloadPDF(); }}> <PictureAsPdfIcon /> </i>
                                    </Tooltip>
                                    <Tooltip title="Export to Excel">
                                        <i className="mt-2" style={{ padding: "5px", cursor: "pointer", }}
                                            onClick={() => { DownloadExcel(); }}>{" "}
                                            <Icon path={mdiFileExcel} size={1} />
                                        </i>
                                    </Tooltip>
                                </div>
                                <div className=" table-responsive">
                                    <table id="dataTableHover"
                                        className="table align-items-center table-flush table-hover paidInterest">
                                        <thead>
                                            <tr>
                                                <th className="text-left" style={{ padding: "10px" }}>Sr. No.</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Enrollment No</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Name</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Plan Type</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Payment Date</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Reference No</th>
                                                <th className="text-left" style={{ padding: "10px" }}>From Date</th>
                                                <th className="text-left" style={{ padding: "10px" }}>To Date</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Days</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Amount</th>
                                                <th className="text-left" style={{ padding: "10px" }}>Remarks</th>
                                                <th className="text-center" style={{ padding: "10px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.length !== 0 ? (
                                                data.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-left" style={{ padding: "10px" }} >{index + 1}</td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {item.Enrollment_No}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {item.Customer_Name}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {item.Investment_Type}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {dayjs(item.Payment_Date).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {item.Reference_No}
                                                            </td>
                                                           
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {item.From_Date ? dayjs(item.From_Date).format('DD/MM/YYYY') : '--'}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {item.To_Date ? dayjs(item.To_Date).format('DD/MM/YYYY') : '--'}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>
                                                                {calculateDaysDifference(item.From_Date, item.To_Date) + 1 || '0'}
                                                            </td>
                                                            <td className="text-left" style={{ padding: "10px" }}>{parseFloat(item.Amount).toFixed(2)}</td>
                                                            <td className="text-center" style={{ padding: "10px" }}>
                                                                {<LightTooltip title={item.Remarks}>
                                                                    <p style={{
                                                                        maxWidth: "90px", whiteSpace: "nowrap", overflow: "hidden",
                                                                        textOverflow: "ellipsis", padding: "5px", cursor: "pointer",
                                                                    }}>{item.Remarks ? item.Remarks : "--"} </p>
                                                                </LightTooltip>}
                                                            </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <a type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i><MoreVertIcon style={{ color: "grey", fontSize: "18px", cursor: "pointer", }} /></i>
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item" onClick={() => updateData(index)}>
                                                                            <EditIcon className="text-info" style={{ fontSize: "18px" }} />{" "}
                                                                            Edit
                                                                        </a>
                                                                        <a className="dropdown-item" onClick={() => deleteData(item.Sr_No, item.Customer_Name)}>
                                                                            <DeleteIcon className="text-danger" style={{ fontSize: "18px" }} />{" "}
                                                                            Delete
                                                                        </a>
                                                                    </div>
                                                                </div>                                                                </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td className="text-center text-danger" colSpan={12}>
                                                        No Data Found !!
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={9} className="text-right" style={{ padding: "10px" }}>
                                                    <strong aligne="right">Total Amount:</strong>
                                                </td>
                                                <td colSpan={3} className="text-left" style={{ padding: "10px" }}>
                                                    <strong>
                                                        {parseFloat(TotalAmount).toFixed(2)}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                isModal && (
                    <InterestPayment
                        open={isModal}
                        onClose={closed}
                        detail={data[indexValue]}
                        month={0}
                        year={0}
                        type="Update"
                    />
                )
            }
            {loader}
        </div>
    );
}

export default PaidInterest;