import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import axios from "axios";
import "jspdf-autotable";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { apiUrl } from "../../componets/apiConfig";
import "../../CSS/dataTables.bootstrap4.min.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import Select from "react-select";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import dayjs from "dayjs";

const ClosedCustomer = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [typeData, setTypeData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [TotalInterestAmount, setTotalInterestAmount] = useState(0);
  const [oldData, setlanData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    PlanId: "-1",
    Type_Id: "-1",
    Customer_Id: "-1",
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const planLoad = async () => {
    showLoader();
    const staffResult = await axios.get(
      `${apiUrl}/getPlans?OrgId=${userData.Org_Id}`
    );
    setPlanData(staffResult.data);
    hideLoader();
  }
  const customerLoad = async () => {
    showLoader();
    const customerResult = await axios.get(`${apiUrl}/getCustomerByPlanId?OrgId=${userData.Org_Id}&PlanId=${filter.PlanId}`);
    setCustomerData(customerResult.data);
    hideLoader();
  }
  const TypeLoad = async () => {
    showLoader();
    const TypeResult = await axios.get(
      `${apiUrl}/getPercentageTypes?OrgId=${userData.Org_Id}`
    );
    setTypeData(TypeResult.data);
    hideLoader();
  };
  const dataLoad = async () => {
    showLoader();
    const Result = await axios.get(
      `${apiUrl}/getClosedCustomer?OrgId=${userData.Org_Id}&PlanId=${filter.PlanId}&Type_Id=${filter.Type_Id}&Customer_Id=${filter.Customer_Id}&FromDate=${filter.fromDate}&ToDate=${filter.toDate}`
    );
    //console.log(Result.data);
    setData(Result.data);
    setlanData(Result.data);
    hideLoader();
  };
  useEffect(() => {
    if (userData && userData.Org_Id) {
      showLoader();
      planLoad();
      TypeLoad();
      dataLoad();
      customerLoad();
      hideLoader();
    }
  }, [userData, filter.PlanId]);

  const filterData = () => {
    dataLoad();
  };

  useEffect(() => {
    if (data) {
      showLoader();
      const totalCapitalAmount = data.reduce((total, item) => total + parseFloat(item.Close_Capital_Amount), 0);
      setTotalInterestAmount(totalCapitalAmount);
      hideLoader();
    }
  }, [data]);

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Closed Accounts", 20, 10);

    const columns = [
      "Enrollment No",
      "Name",
      "Start Date",
      "Colse Date",
      "Capital Amount",
      "Withdrawal Date",
    ];

    const rows = data.map((row) => [
      row.Close_Enrollment_No,
      row.Request_By,
      dayjs(row.Start_Date).format("DD/MM/YYYY"),
      dayjs(row.Request_Date).format("DD/MM/YYYY"),
      row.Close_Capital_Amount.toFixed(2),
      dayjs(row.Withdrawal_Amount_Date).format("DD/MM/YYYY"),
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Closed_Accounts.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Enrollment No",
      "Name",
      "Start Date",
      "Colse Date",
      "Capital Amount",
      "Withdrawal Date",
    ];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        row.Close_Enrollment_No,
        row.Request_By,
        dayjs(row.Start_Date).format("DD/MM/YYYY"),
        dayjs(row.Request_Date).format("DD/MM/YYYY"),
        row.Close_Capital_Amount.toFixed(2),
        dayjs(row.Withdrawal_Amount_Date).format("DD/MM/YYYY"),
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Closed Accounts");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Closed_Accounts.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = oldData.filter((row) => {
      return (
        (row.Close_Enrollment_No &&
          row.Close_Enrollment_No.toLowerCase().includes(filterValue)) ||
        (row.Request_By &&
          row.Request_By.toLowerCase().includes(filterValue)) ||
        row.Close_Capital_Amount.toString().includes(filterValue) ||
        dayjs(row.Start_Date).format("DD/MM/YYYY").includes(filterValue) ||
        dayjs(row.Request_Date).format("DD/MM/YYYY").includes(filterValue) ||
        dayjs(row.Withdrawal_Amount_Date).format("DD/MM/YYYY").includes(filterValue)
      );
    });
    setData(filteredData);
  }
  return (
    <div className="container-fluid">
      <div>
        <div className="row ">
          <div className="col-12">
            <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
              <h5 className="page-title">Closed Accounts</h5>
            </div>
            <div className="card">
              <h5 className="page-title"></h5>
              <div className="card-body" style={{ paddingTop: "5px" }}>
                <div className="row justify-content-center mt-2">
                  <div className="col-3" >
                    <label htmlFor="example-select" className="form-label">
                      Plan
                    </label>
                    <select
                      className="form-control"
                      id="example-select"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          PlanId: e.target.value,
                          Customer_Id: '-1'
                        });
                      }}
                    >
                      <option value={-1}>All</option>
                      {planData.map((item, index) => (
                        <option
                          key={index}
                          value={item.Plan_Id}
                          label={item.Plan_Name}
                        >{item.Plan_Name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      Customer
                    </label>
                    <Select
                      id="example-select"
                      options={[
                        { value: -1, label: 'All' },
                        ...customerData.map((item, index) => ({
                          value: item.Sr_No,
                          label: `${item.First_Name} ${item.Last_Name}`
                        }))
                      ]}
                      onChange={(selectedOption) => {
                        setFilter({
                          ...filter,
                          Customer_Id: selectedOption.value
                        });
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      From Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={filter.fromDate}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          fromDate: e.target.value,
                        });
                      }}
                    ></input>
                  </div>
                  <div className="col-3">
                    <label htmlFor="example-select" className="form-label">
                      To Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={filter.toDate}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          toDate: e.target.value,
                        });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-2">
                    <button
                      className="btn btn-primary"
                      onClick={filterData}
                      style={{ width: "100%" }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <TextField
                    onChange={handleFilter}
                    size="small"
                    label="Search..."
                  />
                  <Tooltip title="Export to PDF">
                    <i
                      className="mt-2"
                      style={{ cursor: "pointer", padding: "5px" }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      {" "}
                      <PictureAsPdfIcon />{" "}
                    </i>
                  </Tooltip>
                  <Tooltip title="Export to Excel">
                    <i
                      className="mt-2"
                      style={{ padding: "5px", cursor: "pointer" }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
                <div className=" table-responsive">
                  <table
                    id="dataTableHover"
                    className="table align-items-center table-flush table-hover paidInterest"
                  >
                    <thead>
                      <tr>
                        <th className="text-center">Sr. No.</th>
                        <th className="text-center">Enroll. No</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Start Date</th>
                        <th className="text-center">Close Date</th>
                        {/* <th className="text-center">Interest Type</th> */}
                        <th className="text-center">Withdrawal Date</th>
                        <th className="text-center">Capital Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length !== 0 ? (
                        data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center" style={{ width: "" }}>
                                {index + 1}
                              </td>
                              <td className="text-center" style={{ width: "" }}>
                                {item.Close_Enrollment_No}
                              </td>
                              <td className="text-center" style={{ width: "" }}>
                                {item.Request_By}
                              </td>
                              <td className="text-center" style={{ width: "" }}>
                                {dayjs(item.Start_Date).format("DD/MM/YYYY")}
                              </td>
                              <td className="text-center" style={{ width: "" }}>
                                {dayjs(item.Request_Date).format("DD/MM/YYYY")}
                              </td>
                              {/* <td className="text-center" style={{ width: "" }}>
                                {item.Percentage_Type}
                              </td> */}
                              <td className="text-center" style={{ width: "" }}>
                                {dayjs(item.Withdrawal_Amount_Date).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td className="text-center" style={{ width: "" }}>
                                {parseFloat(item.Close_Capital_Amount).toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center text-danger" colSpan={7}>
                            No Data Found !!
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={6} className="text-right">
                          <strong aligne="right">Total : </strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            {parseFloat(TotalInterestAmount).toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </div>
  );
};

export default ClosedCustomer;
