import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { apiUrl } from "../../componets/apiConfig";
import axios from "axios"; import { useUser } from "../../componets/UserContext";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

const Calendar = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { userData } = useUser();
  const [eventData, setEventData] = useState([]);

  function renderEventContent(eventInfo) {
    const eventBackgroundColor = eventInfo.event.extendedProps.Payment_Status === 'Paid' ? '#008000' : '#FF0000';
    const eventStyle = {
      cursor: 'pointer',
      backgroundColor: eventBackgroundColor
    };

    return (
      <Tooltip title={eventInfo.event.title}>
        <div style={eventStyle}>
        {eventInfo.event.extendedProps.Payment_Status === 'Unpaid' ?(
          <Link to={`/PayWithdrawalAmount/${eventInfo.event.start}`} style={{ textDecoration: "none", color: "#757575" }}>
          &nbsp;<i className='text-white'>{eventInfo.event.title}</i>
          </Link>
        ):(
        <>
        &nbsp;<i className='text-white'>{eventInfo.event.title}</i>
        </>
        )}
       
        </div>
      </Tooltip>
    );
  }

  const loadEvents = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getWithdrwalCapitalForCalender?OrgId=${userData.Org_Id}`
      );
      setEventData(result.data);
      //console.log(result.data);

    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (userData && userData.Org_Id) {
      loadEvents();
    }
  }, [userData]);

  const events = eventData.map(data => ({
    title: `Rs. ${data.Capital_Amount} to ${data.Request_By}`,
    start: data.Withdrawal_Amount_Date,
    Payment_Status: data.Payment_Status
  }));


  return (
    
     
      <div className="card mt-2">
        <div className="card-body">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            events={events}
            eventContent={renderEventContent}
            header={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,dayGridWeek,dayGridDay'
            }}
            height={800}
            width={100}
            allDayText='Events'
            selectable={true}
            overflow='hidden'
            editable={false}
            firstDay={1}
            slotEventOverlap={true}
            eventDisplay='block'
          />
        </div>
      </div>

  );
}

export default Calendar;



// const events = eventData.map(data => ({
//   title: `Rs. ${data.Capital_Amount} to ${data.Request_By}`,
//   start: data.Withdrawal_Amount_Date,
//   end: data.Withdrawal_End_Date, // Assuming there's an end date for the withdrawal
//   description: data.Description, // Additional description of the event
//   location: data.Location, // Location of the event
//   category: data.Category, // Category of the event
//   Payment_Status: data.Payment_Status
// }));
